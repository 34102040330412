import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';
import { ColorClassNames, FontClassNames, FontWeights, TextField, PrimaryButton, Spinner, SpinnerSize, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';
import { Link } from 'react-router-dom';

// Components
import TranslatedComponent from '../../localization/TranslatedComponent';
import styles from './SetNewPassword.module.css';

// Services

// Dto
import ISetNewPasswordProps from './dto/ISetNewPasswordProps';
import SetNewPasswordState from './dto/SetNewPasswordState';
import SetNewPasswordRequest from '../../dto/account/SetNewPasswordRequest';
import AccountFactory from '../../services/account/AccountFactory.service';

// Util
import getUrlParameter from '../../util/GetUrlParameter';

/**
 * Set new password Component
 */
class SetNewPassword extends TranslatedComponent<ISetNewPasswordProps, SetNewPasswordState> {
    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<ISetNewPasswordProps>) {
        super(props);

        let state = new SetNewPasswordState();
        state.setNewPasswordRequest.userId = getUrlParameter("userId");
        state.setNewPasswordRequest.code = getUrlParameter("code");

        this.state = state;
    }

    /**
     * Renders the set new password mail component
     * @param t Translate function
     * @returns Rendered component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <div className={`${styles.setNewPasswordContainer} ${ColorClassNames.whiteBackground}`}>
                <h1 className={`${FontClassNames.xLargePlus} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("setNewPassword.header")}</h1>
                {!this.state.couldSetNewPassword ? this.renderSetNewPasswordForm(t) : this.renderSuccessMessage(t)}
            </div>
        );
    }

    /**
     * Renders the set new password form
     * @param t Translate function
     * @returns Rendered message
     */
    private renderSetNewPasswordForm = (t: TFunction): ReactNode => {
        return (
            <div>
                {this.renderRequestErrorOccured(t)}
                <TextField errorMessage={this.getNewPasswordErrorMessage(t)} onChange={this.setNewPassword} value={this.state.setNewPasswordRequest.password} type="password" required={true} label={t("setNewPassword.newPassword")}></TextField>
                <TextField errorMessage={this.getRepeatNewPasswordErrorMessage(t)} onChange={this.setRepeatNewPassword} value={this.state.repeatNewPassword} type="password" required={true} label={t("setNewPassword.repeatNewPassword")}></TextField>
                <p>
                    <PrimaryButton onClick={this.onSetNewPassword} disabled={this.state.isLoading}>
                        {this.state.isLoading && <Spinner className={styles.setNewPasswordButtonSpinner} size={SpinnerSize.xSmall}></Spinner>}
                        {t("setNewPassword.updatePassword")}
                    </PrimaryButton>
                </p>
            </div>
        );
    }

    /**
     * Renders a message that a request error occured
     * @param t Translate function
     */
    private renderRequestErrorOccured = (t: TFunction): ReactNode => {
        if (!this.state.setNewPasswordError) {
            return null;
        }

        return (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.removeRequestErrorBanner} dismissButtonAriaLabel={t("general.close")}>
                {t(`general.error.${this.state.setNewPasswordError}`)}
            </MessageBar>
        )
    }

    /**
     * Renders a success message
     * @param t Translate function
     * @returns Rendered message
     */
    private renderSuccessMessage = (t: TFunction): ReactNode => {
        return (
            <div>
                <p>{t("setNewPassword.successMessage")}</p>
                <Link className={`${ColorClassNames.themePrimary} ${styles.redirectLink}`} to="/login">{t("setNewPassword.loginMessage")}</Link>
            </div>
        );
    }

    /**
     * Removes the request error banner
     */
    private removeRequestErrorBanner = () => {
        this.setState({
            setNewPasswordError: ""
        });
    }

    /**
     * Returns an error message if an invalid new password is provided
     * @param t Translate function
     * @returns Error message
     */
    private getNewPasswordErrorMessage = (t: TFunction): string => {
        if (this.state.showErrorMessage && !this.state.setNewPasswordRequest.password)
        {
            return t("general.mandatoryField");
        }

        return "";
    }

    /**
     * Returns an error message if an invalid new password repeat is provided
     * @param t Translate function
     * @returns Error message
     */
    private getRepeatNewPasswordErrorMessage = (t: TFunction): string => {
        if (!this.state.showErrorMessage)
        {
            return "";
        }

        if(!this.state.repeatNewPassword)
        {
            return t("general.mandatoryField");
        }

        return this.state.setNewPasswordRequest.password !== this.state.repeatNewPassword ? t("general.passwordsDoNotMatch") : "";
    }

    /**
     * Callback if the new password was changed
     * @param _ev Event object
     * @param newValue New value of the textbox
     */
    private setNewPassword = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
        if(!newValue)
        {
            newValue = "";
        }

        let newPasswordRequest: SetNewPasswordRequest = { ...this.state.setNewPasswordRequest };
        newPasswordRequest.password = newValue;

        this.setState({
            setNewPasswordRequest: newPasswordRequest
        });
    }

    /**
     * Callback if the repeat password was changed
     * @param _ev Event object
     * @param newValue New value of the textbox
     */
    private setRepeatNewPassword = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
        if(!newValue)
        {
            newValue = "";
        }

        this.setState({
            repeatNewPassword: newValue
        });
    }
    
    /**
     * Gets called if the new password must be set
     */
    private onSetNewPassword = async (): Promise<void> => {
        if(!this.state.setNewPasswordRequest.password || this.state.setNewPasswordRequest.password !== this.state.repeatNewPassword)
        {
            this.setState({
                showErrorMessage: true,
                isLoading: false
            });
            return;
        }
        
        this.setState({
            showErrorMessage: false,
            isLoading: true
        });

        try {
            await AccountFactory.getAccountService().setNewPassword(this.state.setNewPasswordRequest);
            
            this.setState({
                isLoading: false,
                couldSetNewPassword: true
            });
        }
        catch (e) {
            let errorMessage = "generic";
            if (e.response && e.response.status < 500 && e.response.data && e.response.data.message) {
                errorMessage = e.response.data.message;
            }

            if(errorMessage === "invalidToken") {
                errorMessage = "invalidTokenResetPassword";
            }

            this.setState({
                isLoading: false,
                setNewPasswordError: errorMessage
            });
        }
    }
}

export default SetNewPassword; 