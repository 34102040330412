import ISubscriptionsService from './ISubscriptions.service';
import TrackedHttpClient from './../trackedHttpClient/TrackedHttpClient.service';
import SubscriptionConnectRequest from './../../dto/subscriptions/SubscriptionConnectRequest';
import UserSubscription from '../../dto/subscriptions/UserSubscription';

/**
 * Web service for subscriptions services
 */
class WebSubscriptionsService implements ISubscriptionsService {
    /**
     * Connects a subscription
     * @param subscriptionConnectRequest Request to connect the subscription
     * @returns Newly connected subscription
     */
    public async connectSubscription(subscriptionConnectRequest: SubscriptionConnectRequest): Promise<UserSubscription> {
        return await TrackedHttpClient.post<UserSubscription>("/api/subscriptions/connect", subscriptionConnectRequest);
    }

    /**
     * Returns the subscriptions of the current user
     * @returns Subscriptions of the current user
     */
    public async getMySubscriptions(): Promise<UserSubscription[]> {
        return await TrackedHttpClient.get<UserSubscription[]>("/api/subscriptions/mySubscriptions");
    }
}

export default WebSubscriptionsService;