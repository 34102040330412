import React, { ReactNode } from 'react';

// Library
import { ColorClassNames, FontSizes, CommandBar, ICommandBarItemProps, getTheme, ICommandBarStyles, ContextualMenuItemType, MessageBarType, MessageBar } from 'office-ui-fabric-react';
import { TFunction } from 'i18next';
import { useHistory } from 'react-router-dom';

// Components
import TranslatedComponent from '../../../localization/TranslatedComponent';
import styles from './NavBar.module.css';
import NavBarButton from './components/NavBarButton';

// Services
import AuthFactoryService from '../../../services/auth/AuthFactory.service';

// Dto
import INavBarProps from './dto/INavBarProps';
import NavBarState from './dto/NavBarState';

/**
 * NavBar Component
 */
class NavBar extends TranslatedComponent<INavBarProps, NavBarState> {
    /**
     * Theme
     */
    private readonly _theme = getTheme();

    /**
     * Menu Styles
     */
    private readonly _menuStyles: Partial<ICommandBarStyles> = {
        root: {
            backgroundColor: this._theme.palette.neutralLight,
            paddingLeft: "68px",
            paddingRight: "68px"
        }
    };

    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<INavBarProps>) {
        super(props);

        this.state = new NavBarState();
    }

    /**
     * Renders the header
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        let routerHistory = useHistory();

        return (
            <div>
                <div className={`${styles.mainNavBar} ${ColorClassNames.neutralLightBackground}`}>
                    <CommandBar
                        styles={this._menuStyles}
                        buttonAs={NavBarButton}
                        overflowButtonAs={NavBarButton}
                        items={this.getItems(t, routerHistory)}
                        farItems={this.getFarItems(t, routerHistory)}
                        ariaLabel={t("navbar.useLeftRightButtonToNavigate")}
                    />
                </div>

                {this.renderSignOutErrorBar(t)}
            </div>
        );
    }

    /**
     * Renders the sign out error bar if requierd
     * @param t Translate function
     */
    private renderSignOutErrorBar(t: TFunction): ReactNode {
        if (!this.state.showSignOutErrorMessage) {
            return null;
        }

        return (
            <div className={styles.signOutErrorBar}>
                <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.hideSignOutError} dismissButtonAriaLabel={t("general.close")}>
                    {t("navbar.couldNotLogout")}
                </MessageBar>
            </div>
        );
    }

    /**
     * Hides the sign out error
     */
    private hideSignOutError = () => {
        this.setState({
            showSignOutErrorMessage: false
        });
    }

    /**
     * Builds the menu items
     * @param t Translate function
     * @param routerHistory Router history
     * @returns Commandbar items
     */
    private getItems = (t: TFunction, routerHistory: any): ICommandBarItemProps[] => {
        return [
            {
                key: "Home",
                text: t("navbar.home"),
                data: {
                    target: "/"
                },
                buttonStyles: { rootHovered: { borderBottomColor: this._theme.palette.themePrimary, borderBottomWidth: "3px", borderBottomStyle: "solid", backgroundColor: "transparent" }, root: { fontSize: FontSizes.mediumPlus, backgroundColor: "transparent" } },
                iconProps: { styles: { root: { fontSize: FontSizes.medium } }, iconName: "Home" },
                onClick: () => routerHistory.push("/")
            },
            {
                key: "MyDownloads",
                text: t("navbar.myDownloads"),
                data: {
                    target: "/myDownloads"
                },
                buttonStyles: { rootHovered: { borderBottomColor: this._theme.palette.themePrimary, borderBottomWidth: "3px", borderBottomStyle: "solid", backgroundColor: "transparent" }, root: { fontSize: FontSizes.mediumPlus, backgroundColor: "transparent" } },
                iconProps: { styles: { root: { fontSize: FontSizes.medium } }, iconName: "Installation" },
                onClick: () => routerHistory.push("/myDownloads")
            },
            {
                key: "Favoriten",
                text: t("navbar.Favoriten"),
                data: {
                    target: "/favorites"
                },
                buttonStyles: { rootHovered: { borderBottomColor: this._theme.palette.themePrimary, borderBottomWidth: "3px", borderBottomStyle: "solid", backgroundColor: "transparent" }, root: { fontSize: FontSizes.mediumPlus, backgroundColor: "transparent" } },
                iconProps: { styles: { root: { fontSize: FontSizes.medium } }, iconName: "FavoriteStar" },
                onClick: () => routerHistory.push("/favorites")
            }
        ];
    }

    /**
     * Builds the far items
     * @param t Translate function
     * @param routerHistory Router history
     * @returns Commandbar items
     */
    private getFarItems = (t: TFunction, routerHistory: any): ICommandBarItemProps[] => {
        if (!this.props.currentUser || !this.props.currentUser.isAuthenticated) {
            return [
                {
                    key: "Login",
                    text: t("navbar.login"),
                    iconProps: { iconName: "Fingerprint" },
                    buttonStyles: { rootHovered: { borderBottomColor: this._theme.palette.themePrimary, borderBottomWidth: "3px", borderBottomStyle: "solid", backgroundColor: "transparent" }, root: { fontSize: FontSizes.mediumPlus, backgroundColor: "transparent" } },
                    data: {
                        target: "/login"
                    },
                    onClick: () => routerHistory.push("/login")
                }
            ];
        } else {
            let userItems: any[]  = [];
            if (!this.props.currentUser.isIpAuthenticated) {
                userItems = [{
                    key: "ChangePassword",
                    text: t("navbar.changePassword"),
                    iconProps: { iconName: "Signin" },
                    onClick: () => routerHistory.push("/changePassword")
                },
                {
                    key: "MyProfile",
                    text: t("navbar.myProfile"),
                    iconProps: { iconName: "Contact" },
                    onClick: () => routerHistory.push("/myProfile")
                },
                {
                    key: "MySubscriptions",
                    text: t("navbar.mySubscriptions"),
                    iconProps: { iconName: "PaymentCard" },
                    onClick: () => routerHistory.push("/mySubscriptions")
                },
                {
                    key: "Divider",
                    itemType: ContextualMenuItemType.Divider
                }];
            }

            if(this.props.currentUser.isAdmin)
            {
                userItems.push({
                    key: "AdminArea",
                    text: t("navbar.administration"),
                    iconProps: { iconName: "Admin" },
                    onClick: () => routerHistory.push("/administration")
                });
                userItems.push({
                    key: "AdminDivider",
                    itemType: ContextualMenuItemType.Divider
                });
            }

            userItems.push({
                key: "Logout",
                text: t("navbar.logout"),
                iconProps: { iconName: "SignOut" },
                onClick: this.signOut
            });

            return [
                {
                    key: "LoggedIn",
                    text: this.props.currentUser.displayName,
                    buttonStyles: { rootHovered: { borderBottomColor: this._theme.palette.themePrimary, borderBottomWidth: "3px", borderBottomStyle: "solid", backgroundColor: "transparent" }, root: { backgroundColor: "transparent" } },

                    iconProps: { iconName: "Fingerprint" },
                    subMenuProps: {
                        items: userItems
                    }
                }
            ];
        }
    }

    /**
     * Signs the current user out
     */
    private signOut = async (): Promise<void> => {
        this.setState({
            showSignOutErrorMessage: false
        });

        try {
            await AuthFactoryService.getAuthService().signOut();
            this.props.onUserSignedOut();
        }
        catch (e) {
            this.setState({
                showSignOutErrorMessage: true
            });
        }
    }
}

export default NavBar; 