/**
 * Type for a filter entry
 */
enum MyDownloadsFilterEntryType {
    /**
     * Magazin
     */
    Magazin = 0,

    /**
     * Book
     */
    Book = 1,

    /**
     * Video
     */
    Video = 2,

    /**
     * Generic
     */
    Generic = 3
}

export default MyDownloadsFilterEntryType;