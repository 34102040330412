import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';
import { Panel, PrimaryButton, DefaultButton, Spinner, SpinnerSize, MessageBar, MessageBarType, PanelType } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';

// Components
import TranslatedComponent from '../../../../localization/TranslatedComponent';
import UserDataForm from '../../../shared/userDataForm/UserDataForm';
import styles from './UserEditCreatePanel.module.css';

// Services
import AdminFactoryService from '../../../../services/admin/AdminFactory.service';

// Dto
import IUserEditCreatePanelProps from './dto/IUserEditCreatePanelProps';
import UserEditCreatePanelState from './dto/UserEditCreatePanelState';
import UserData from '../../../../dto/account/UserData';

/**
 * User Edit / Create panel Component
 */
class UserEditCreatePanel extends TranslatedComponent<IUserEditCreatePanelProps, UserEditCreatePanelState> {
    /**
     * Reference to the user data form
     */
    private userDataForm: UserDataForm | null = null;
    
    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<IUserEditCreatePanelProps>) {
        super(props);

        this.state = new UserEditCreatePanelState();
    }

    /**
     * Gets called if the component is updated
     */
    public componentDidUpdate(prevProps: IUserEditCreatePanelProps) {
        if(!(this.props.showPanel && !prevProps.showPanel)) {
            return;
        }

        if (this.props.userToEdit !== null) {
            this.setState({
                errorOccured: false,
                existingUserData: null,
                isLoading: true
            });

            AdminFactoryService.getAdminService().getUserById(this.props.userToEdit.id).then((u) => {
                let createEditRequest = this.state.createEditRequest.clone();
                createEditRequest.assignFromUserData(u);

                this.setState({
                    existingUserData: u,
                    createEditRequest: createEditRequest,
                    isLoading: false
                });
            }, () => {
                this.setState({
                    isLoading: false,
                    errorOccured: true
                })
            });
        } else {
            this.setState({
                errorOccured: false,
                existingUserData: null
            });
        }
    }

    /**
     * Renders the administration component
     * @param t Translate function
     * @returns Rendered component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <Panel
                isOpen={this.props.showPanel}
                onDismiss={this.props.onClosePanel}
                closeButtonAriaLabel={t("general.close")}
                headerText={t("userEditCreatePanel.header")}
                onRenderFooter={() => this.renderPanelFooter(t)}
                isFooterAtBottom={true}
                type={PanelType.large}>
                {this.renderErrorOccured(t)}
                
                <UserDataForm existingUserData={this.state.existingUserData} hideUsername={false} hideMiscFields={false} hideIpAuthFields={false} hideLoginFields={false} onUserDataChanged={this.onUserDataChanged} ref={r => this.userDataForm = r}></UserDataForm>

            </Panel>
        );
    }

    /**
     * Renders the panel footer
     * @param t Translate function
     */
    private renderPanelFooter(t: TFunction): JSX.Element | null {
        return (
            <div className={styles.footerContainer}>
                <PrimaryButton disabled={this.state.isLoading} onClick={this.onSaveUser} className={styles.buttonMargin}>
                    {this.state.isLoading && <Spinner className={styles.saveButtonSpinner} size={SpinnerSize.xSmall}></Spinner>}
                    {t("general.save")}
                </PrimaryButton>
                <DefaultButton disabled={this.state.isLoading} onClick={this.props.onClosePanel}>{t("general.cancel")}</DefaultButton>
            </div>
        );
    }

    /**
     * Renders a message that an error occured
     * @param t Translate function
     */
    private renderErrorOccured(t: TFunction): ReactNode {
        if (!this.state.errorOccured) {
            return null;
        }

        return (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={true} onDismiss={this.removeErrorBanner} dismissButtonAriaLabel={t("general.close")}>
                {t(`general.error.${this.state.errorMessage}`)}
            </MessageBar>
        )
    }
    
    /**
     * Removes the error banner
     */
    private removeErrorBanner = (): void => {
        this.setState({
            errorOccured: false,
            errorMessage: "generic"
        })
    }

    /**
     * Gets called if the user data is changed
     * @param userData New User data
     */
    private onUserDataChanged = (userData: UserData): void => {
        let createEditRequest = this.state.createEditRequest.clone();
        createEditRequest.assignFromUserData(userData);

        this.setState({
            createEditRequest: createEditRequest
        });
    }

    /**
     * Gets called if the user must be saved
     */
    private onSaveUser = async (): Promise<void> => {
        if(!this.userDataForm) {
            return;
        }

        if(!this.userDataForm.validate()) {
            return;
        }

        this.setState({
            errorOccured: false,
            errorMessage: "generic",
            isLoading: true
        });

        try
        {
            if(this.props.userToEdit)
            {
                await AdminFactoryService.getAdminService().updateUser(this.props.userToEdit.id, this.state.createEditRequest);
            }
            else
            {
                await AdminFactoryService.getAdminService().createUser(this.state.createEditRequest);
            }

            this.setState({
                isLoading: false
            });

            this.props.onSaveSuccess();
        }
        catch(e)
        {
            let errorMessage = "generic";
            if (e.response && e.response.status < 500 && e.response.data && e.response.data.message) {
                errorMessage = e.response.data.message;
            }

            if(errorMessage === "usernameAlreadyTaken") {
                errorMessage = "usernameAlreadyTakenAdmin";
            }

            this.setState({
                isLoading: false,
                errorOccured: true,
                errorMessage: errorMessage
            });
        }
    }
}

export default UserEditCreatePanel; 