import UserData from './../../dto/UserData';
import LoggedInUserCheckResult from './../../dto/auth/loggedInUserCheckResult';
import mockLoadingTime from './../../util/MockLoadingTime';

import IAuthService from './IAuth.service';

/**
 * Mock auth services
 */
class MockAuthService implements IAuthService {
    /**
     * Runs a login for a user
     * @param username Username
     * @param password Password
     * @returns Logged in userdata
     */
    public async login(username: string, password: string): Promise<UserData> {
        await mockLoadingTime();

        let userData = new UserData();
        userData.isAuthenticated = true;
        userData.isIpAuthenticated = false;
        userData.displayName = "Tim Tester";
        return userData;
    }

    /**
     * Signs the current user out
     */
    public async signOut(): Promise<void> {
        await mockLoadingTime();
    }

    /**
     * Checks if a user is already logged in
     */
    public checkLoggedInUser(): LoggedInUserCheckResult {
        let userData = new UserData();
        if(window.location.href.indexOf("autoLogin=1") >= 0) {
            userData.isAuthenticated = true;
            userData.isIpAuthenticated = false;
            userData.displayName = "Arnold AutoLogin";
        }
        if(window.location.href.indexOf("adminAutoLogin=1") >= 0) {
            userData.isAuthenticated = true;
            userData.isIpAuthenticated = false;
            userData.isAdmin = true;
            userData.displayName = "Auto Admin";
        }
        if(window.location.href.indexOf("ipAutoLogin=1") >= 0) {
            userData.isAuthenticated = true;
            userData.isIpAuthenticated = true;
            userData.displayName = "Ingo IP";
        }

        let result: LoggedInUserCheckResult = new LoggedInUserCheckResult();
        result.user = userData;
        if(window.location.href.indexOf("peterPopular=1") >= 0) {
            result.tooManyLogins = true;
        }

        return result;
    }
}

export default MockAuthService;