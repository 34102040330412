/**
 * Available Product to associate with a user
 */
class AvailableUserProduct {
    /**
     * Id of the product
     */
    public id: string;

    /**
     * Title of the product
     */
    public name: string;

    /**
     * Filename of the product
     */
    public filename: string;

    /**
     * Constructor
     */
    public constructor() {
        this.id = "";
        this.name = "";
        this.filename = "";
    }
}

export default AvailableUserProduct;