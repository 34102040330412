import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';
import { ColorClassNames, FontClassNames, FontWeights, TextField, PrimaryButton, Spinner, SpinnerSize, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';

// Components
import TranslatedComponent from '../../localization/TranslatedComponent';
import styles from './ChangePassword.module.css';

// Services

// Dto
import IChangePasswordProps from './dto/IChangePasswordProps';
import ChangePasswordState from './dto/ChangePasswordState';
import ChangePasswordRequest from '../../dto/account/ChangePasswordRequest';
import AccountFactory from '../../services/account/AccountFactory.service';
import { Redirect } from 'react-router-dom';

/**
 * Change password Component
 */
class ChangePassword extends TranslatedComponent<IChangePasswordProps, ChangePasswordState> {
    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<IChangePasswordProps>) {
        super(props);

        this.state = new ChangePasswordState();
    }

    /**
     * Renders the change password component
     * @param t Translate function
     * @returns Rendered component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <div className={`${styles.changePasswordContainer} ${ColorClassNames.whiteBackground}`}>
                {this.renderUnauthorizedRedirect()}
                <h1 className={`${FontClassNames.xLargePlus} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("changePassword.header")}</h1>
                {this.renderRequestErrorOccured(t)}
                {this.renderSuccessBanner(t)}
                <TextField errorMessage={this.getMandatoryFieldError(t, this.state.changePasswordRequest.oldPassword)} onChange={this.setOldPassword} value={this.state.changePasswordRequest.oldPassword} type="password" required={true} label={t("changePassword.oldPassword")}></TextField>
                <TextField errorMessage={this.getMandatoryFieldError(t, this.state.changePasswordRequest.oldPassword)} onChange={this.setNewPassword} value={this.state.changePasswordRequest.newPassword} type="password" required={true} label={t("changePassword.newPassword")}></TextField>
                <TextField errorMessage={this.getRepeatNewPasswordErrorMessage(t)} onChange={this.setRepeatNewPassword} value={this.state.repeatNewPassword} type="password" required={true} label={t("changePassword.repeatNewPassword")}></TextField>
                <p>
                    <PrimaryButton onClick={this.onChangePassword} disabled={this.state.isLoading}>
                        {this.state.isLoading && <Spinner className={styles.changePasswordButtonSpinner} size={SpinnerSize.xSmall}></Spinner>}
                        {t("changePassword.updatePassword")}
                    </PrimaryButton>
                </p>
            </div>
        );
    }

    /**
     * Renders a redirect if the current user is not logged in
     */
    private renderUnauthorizedRedirect = (): ReactNode => {
        if(this.props.currentUser && this.props.currentUser.isAuthenticated && !this.props.currentUser.isIpAuthenticated)
        {
            return null;
        }

        return (
            <Redirect to={{
                pathname: "/login",
                state: { from: "/changePassword" }
            }}></Redirect>
        )
    }

    /**
     * Renders a message that the password was changed
     * @param t Translate function
     */
    private renderSuccessBanner = (t: TFunction): ReactNode => {
        if (!this.state.passwordWasChanged) {
            return null;
        }

        return (
            <MessageBar messageBarType={MessageBarType.success} onDismiss={this.removeSuccessBanner} dismissButtonAriaLabel={t("general.close")}>
                {t("changePassword.passwordWasChanged")}
            </MessageBar>
        )
    }

    /**
     * Renders a message that a request error occured
     * @param t Translate function
     */
    private renderRequestErrorOccured = (t: TFunction): ReactNode => {
        if (!this.state.changePasswordError) {
            return null;
        }

        return (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.removeRequestErrorBanner} dismissButtonAriaLabel={t("general.close")}>
                {t(`general.error.${this.state.changePasswordError}`)}
            </MessageBar>
        )
    }
    
    /**
     * Removes the success banner
     */
    private removeSuccessBanner = () => {
        this.setState({
            passwordWasChanged: false
        });
    }

    /**
     * Removes the request error banner
     */
    private removeRequestErrorBanner = () => {
        this.setState({
            changePasswordError: ""
        });
    }

    /**
     * Returns an error message if a mandatory field was not filled
     * @param t Translate function
     * @param value Value to check
     * @returns Error message
     */
    private getMandatoryFieldError = (t: TFunction, value: string): string => {
        if (this.state.showErrorMessage && !value)
        {
            return t("general.mandatoryField");
        }

        return "";
    }

    /**
     * Returns an error message if an invalid new password repeat is provided
     * @param t Translate function
     * @returns Error message
     */
    private getRepeatNewPasswordErrorMessage = (t: TFunction): string => {
        if (!this.state.showErrorMessage)
        {
            return "";
        }

        if(!this.state.repeatNewPassword)
        {
            return t("general.mandatoryField");
        }

        return this.state.changePasswordRequest.newPassword !== this.state.repeatNewPassword ? t("general.passwordsDoNotMatch") : "";
    }

    /**
     * Callback if the old password was changed
     * @param _ev Event object
     * @param newValue New value of the textbox
     */
    private setOldPassword = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
        if(!newValue)
        {
            newValue = "";
        }

        let changePasswordRequest: ChangePasswordRequest = { ...this.state.changePasswordRequest };
        changePasswordRequest.oldPassword = newValue;

        this.setState({
            changePasswordRequest: changePasswordRequest
        });
    }

    /**
     * Callback if the new password was changed
     * @param _ev Event object
     * @param newValue New value of the textbox
     */
    private setNewPassword = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
        if(!newValue)
        {
            newValue = "";
        }

        let changePasswordRequest: ChangePasswordRequest = { ...this.state.changePasswordRequest };
        changePasswordRequest.newPassword = newValue;

        this.setState({
            changePasswordRequest: changePasswordRequest
        });
    }

    /**
     * Callback if the repeat password was changed
     * @param _ev Event object
     * @param newValue New value of the textbox
     */
    private setRepeatNewPassword = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
        if(!newValue)
        {
            newValue = "";
        }

        this.setState({
            repeatNewPassword: newValue
        });
    }
    
    /**
     * Gets called if the new password must be set
     */
    private onChangePassword = async (): Promise<void> => {
        if(!this.state.changePasswordRequest.oldPassword || !this.state.changePasswordRequest.newPassword || 
            this.state.changePasswordRequest.newPassword !== this.state.repeatNewPassword)
        {
            this.setState({
                showErrorMessage: true,
                isLoading: false,
                passwordWasChanged: false,
                changePasswordError: ""
            });
            return;
        }
        
        this.setState({
            showErrorMessage: false,
            isLoading: true,
            passwordWasChanged: false,
            changePasswordError: ""
        });

        try {
            await AccountFactory.getAccountService().changePassword(this.state.changePasswordRequest);
            
            this.setState({
                isLoading: false,
                passwordWasChanged: true
            });
        }
        catch (e) {
            let errorMessage = "generic";
            if (e.response && e.response.status < 500 && e.response.data && e.response.data.message) {
                errorMessage = e.response.data.message;
            }

            this.setState({
                isLoading: false,
                changePasswordError: errorMessage
            });
        }
    }
}

export default ChangePassword; 