/**
 * Home page state class
 */
class HomeState {
    /**
     * Home thumbnail image
     */
    public thumbnailName: string;

    /**
     * Constructor
     */
    public constructor()
    {
        this.thumbnailName = ""; 
    }
}

export default HomeState;