import TrackedHttpClient from './../trackedHttpClient/TrackedHttpClient.service';
import UserData from './../../dto/UserData';
import LoggedInUserCheckResult from './../../dto/auth/loggedInUserCheckResult';
import IAuthService from './IAuth.service';

/**
 * Web auth services
 */
class WebAuthService implements IAuthService {
    /**
     * Runs a login for a user
     * @param username Username
     * @param password Password
     * @returns Logged in userdata
     */
    public async login(username: string, password: string): Promise<UserData> {
        // Make sure to use post to not log credentials
        return await TrackedHttpClient.post<UserData>("/api/login", { 
            username: username,
            password: password
        });
    }

    /**
     * Signs the current user out
     */
    public async signOut(): Promise<void> {
        await TrackedHttpClient.post<void>("/api/signOut", {});
    }

    /**
     * Checks if a user is already logged in
     */
    public checkLoggedInUser(): LoggedInUserCheckResult {
        let result: LoggedInUserCheckResult = new LoggedInUserCheckResult();

        result.user = new UserData();
        let windowCheckObj: any = window as any;
        if(windowCheckObj["VulkanVerlag"] && windowCheckObj["VulkanVerlag"].CurrentUser) {
            result.user.isAuthenticated = true;
            result.user.isIpAuthenticated = windowCheckObj["VulkanVerlag"].CurrentUser.isIpAuthenticated;
            result.user.displayName = windowCheckObj["VulkanVerlag"].CurrentUser.name;
            result.user.isAdmin = windowCheckObj["VulkanVerlag"].CurrentUser.isAdmin;
        } else {
            result.user.isAuthenticated = false;
        }

        if(windowCheckObj["VulkanVerlag"] && windowCheckObj["VulkanVerlag"].hasTooManyLogins) {
            result.tooManyLogins = true;
        }

        return result;
    }
}

export default WebAuthService;