import IMyDownloadsService from './IMyDownloads.service';
import TrackedHttpClient from './../trackedHttpClient/TrackedHttpClient.service';
import MyDownloadsQueryResult from './../../dto/myDownloads/MyDownloadsQueryResult';
import MyDownloadsFilterConfig from '../../dto/myDownloads/MyDownloadsFilterConfig';
import MyDownloadsFilterEntryType from '../../dto/myDownloads/MyDownloadsFilterEntryType';
import MyDownloadsFilter from "../../dto/myDownloadsFilter/MyDownloadsFilter";
import fixEnum from '../../util/FixEnum';
import MyDownloadsEntry from '../../dto/myDownloads/MyDownloadsEntry';

/**
 * Web service for my downloads services
 */
class WebMyDownloadsService implements IMyDownloadsService {
    /**
     * Loads the downloads for the currrent user
     * @param page Current page
     * @param pageSize Page size
     * @param onlyFavorites true if only favorites must be returned, else false
     * @param filter Filter to use
     * @returns Downloads for the user
     */
    public async getMyDownloads(page: number, pageSize: number, onlyFavorites: boolean, filter: MyDownloadsFilter): Promise<MyDownloadsQueryResult> {
        return await TrackedHttpClient.post<MyDownloadsQueryResult>(`/api/myDownloads?page=${page}&pageSize=${pageSize}&onlyFavorites=${onlyFavorites}`, filter);
    }

    /**
     * Loads the download config for the current user
     * @param onlyFavorites true if only favorites must be returned, else false
     * @returns Downloads config
     */
    public async getMyDownloadsConfig(onlyFavorites: boolean): Promise<MyDownloadsFilterConfig> {
        let downloadConfig = await TrackedHttpClient.get<MyDownloadsFilterConfig>(`/api/myDownloads/filterConfig?onlyFavorites=${onlyFavorites}`);
        if(downloadConfig.filterEntries) {
            for(let curEntry of downloadConfig.filterEntries) {
                curEntry.entryType = fixEnum(curEntry.entryType, MyDownloadsFilterEntryType);
            }
        }

        return downloadConfig;
    }

    /**
     * Returns the a download entry by product number
     * @param productNumber Number of the product
     */
    public getDownloadByProductNumber(productNumber: string): Promise<MyDownloadsEntry> {
        return TrackedHttpClient.get<MyDownloadsEntry>(`/api/myDownloads/byProductNumber?productNumber=${productNumber}`);
    }

    /**
     * Adds a favorite for an entry
     * @param entryId Id of the entry
     */
    public async addFavorite(entryId: string): Promise<void> {
        await TrackedHttpClient.post<MyDownloadsQueryResult>(`/api/favorite?entryId=${encodeURIComponent(entryId)}`, {});
    }
    
    /**
     * Removes a favorite for an entry
     * @param entryId Id of the entry
     */
    public async removeFavorite(entryId: string): Promise<void> {
        await TrackedHttpClient.delete<MyDownloadsQueryResult>(`/api/favorite?entryId=${encodeURIComponent(entryId)}`, {});
    }
}

export default WebMyDownloadsService;