import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';
import { Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, DefaultButton, Label, ColorClassNames, FontClassNames, FontWeights, ProgressIndicator, Stack, ActivityItem, Icon } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';
import papaparse from 'papaparse';

// Components
import TranslatedComponent from '../../../../localization/TranslatedComponent';
import styles from './UserProductImportPanel.module.css';

// Services
import AdminFactoryService from '../../../../services/admin/AdminFactory.service';

// Dto
import IUserProductImportPanelProps from './dto/IUserProductImportPanelProps';
import UserProductImportPanelState from './dto/UserProductImportPanelState';
import CsvUserProductData from './dto/CsvUserProductData';
import GroupedUserProductData from './dto/GroupedUserProductData';
import UserProductImportLogEntry from './dto/UserProductImportLogEntry';
import UserEntry from '../../../../dto/admin/UserEntry';

// Util
import formatDate from '../../../../util/FormatDate';
import AvailableUserProduct from '../../../../dto/admin/AvailableUserProduct';
import UserProduct from '../../../../dto/admin/UserProduct';

/**
 * User import panel Component
 */
class UserImportPanel extends TranslatedComponent<IUserProductImportPanelProps, UserProductImportPanelState> {
    /**
     * Progress that is used before starting the actual user import
     */
    private readonly preUserImportProgress: number = 5;

    /**
     * Import File input
     */
    private importFileInput: HTMLInputElement | null = null;

    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<IUserProductImportPanelProps>) {
        super(props);

        this.state = new UserProductImportPanelState();
    }

    /**
     * Renders the administration component
     * @param t Translate function
     * @returns Rendered component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <Panel
                isOpen={this.props.showPanel}
                onDismiss={this.onClosePanel}
                closeButtonAriaLabel={t("general.close")}
                headerText={t("userProductImportPanel.header")}
                onRenderFooter={() => this.renderPanelFooter(t)}
                isFooterAtBottom={true}
                type={PanelType.medium}>

                <Label>{t("userProductImportPanel.userProductFile")}</Label>
                <input type="file" accept=".csv" ref={(r) => this.importFileInput = r}></input>
                {this.state.showErrorMessage && <Label className={ColorClassNames.red}>{t("general.mandatoryField")}</Label>}

                {this.renderImportProgress(t)}
            </Panel>
        );
    }

    /**
     * Renders the panel footer
     * @param t Translate function
     */
    private renderPanelFooter(t: TFunction): JSX.Element | null {
        return (
            <div className={styles.footerContainer}>
                <PrimaryButton disabled={this.state.isLoading || this.state.isImportRunning} onClick={this.onImportUser} className={styles.buttonMargin}>
                    {(this.state.isLoading || this.state.isImportRunning) && <Spinner className={styles.saveButtonSpinner} size={SpinnerSize.xSmall}></Spinner>}
                    {t("userProductImportPanel.import")}
                </PrimaryButton>
                <DefaultButton disabled={this.state.isLoading || this.state.isImportRunning} onClick={this.onClosePanel}>{t("general.close")}</DefaultButton>
            </div>
        );
    }

    /**
     * Renders the import progress
     * @param t Translate function
     * @returns Rendered import progress
     */
    private renderImportProgress(t: TFunction): React.ReactNode | null {
        if (!this.state.showImportLog) {
            return null;
        }

        let importLog = this.state.importLog.map((il) => {
            let colorClassName = "";
            let textKey = "userProductImportPanel.productWasImported";
            let icon = "ProductRelease";

            if(il.errorOccured) {
                textKey = `userProductImportPanel.${il.errorMessage}`;
                icon = "StatusErrorFull";
                colorClassName = ColorClassNames.red;
            }

            let activityItem = {
                activityDescription: [
                    <div key={0} className={colorClassName}>{il.userMail} - {t(textKey)}</div>,
                    il.productNumber ? <div key={1}>{t("userProductImportPanel.productNumber")} {il.productNumber}</div> : null
                ],
                activityIcon: <Icon iconName={icon} className={colorClassName}/>,
                timeStamp: formatDate(t, il.createTime, true),
            }

            return <Stack.Item key={il.id}>
                <ActivityItem className={styles.userImportLogEntry} {...activityItem} />
            </Stack.Item>;
        });

        return (
            <div>
                <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("userImportPanel.importProgress")}</h2>
                <ProgressIndicator percentComplete={this.state.importProgress}></ProgressIndicator>
                <Stack reversed className={styles.userImportLog}>
                    {importLog}
                </Stack>
            </div>
        )
    }

    /**
     * Imports a user
     */
    private onImportUser = async (): Promise<void> => {
        if (!this.importFileInput) {
            return;
        }

        if (!this.importFileInput.files || this.importFileInput.files.length === 0) {
            this.setState({
                showErrorMessage: true
            });
            return;
        }

        this.setState({
            showErrorMessage: false,
            showImportLog: true,
            isImportRunning: true,
            importProgress: 0,
            importLog: []
        });

        try {
            let fileContent = await this.readFileContent(this.importFileInput.files[0]);
            let parsedFile = papaparse.parse<CsvUserProductData>(fileContent, {
                header: true
            });
            this.setImportProgress(1, null);

            let allExistingUsers = await AdminFactoryService.getAdminService().getUsers(0, 100000, "");
            this.setImportProgress(this.preUserImportProgress / 2, null);

            let allAvailableUserProducts = await AdminFactoryService.getAdminService().getAvailableUserProducts();
            this.setImportProgress(this.preUserImportProgress, null);

            await this.importUserProducts(parsedFile.data, allExistingUsers.entries, allAvailableUserProducts);

            this.setState({
                isImportRunning: false,
                importProgress: 100
            });
        }
        catch (e) {
            this.setState({
                isImportRunning: false,
                errorOccured: true
            })
        }
    }

    /**
     * Imports a list of user products
     * @param userData User data to import
     * @param allExistingUsers All existing users
     * @param availableProducts Available products
     */
    private async importUserProducts(userProductData: CsvUserProductData[], allExistingUsers: UserEntry[], availableProducts: AvailableUserProduct[]) {
        let groupedUserData = this.groupUserData(userProductData);

        let userLookup: { [key: string]: string } = {};
        for (let curUser of allExistingUsers) {
            userLookup[curUser.email.toLowerCase()] = curUser.id;
        }
        let curProgress = this.preUserImportProgress;
        let progressPerUser = (100 - this.preUserImportProgress) / groupedUserData.length;
        let curUserLogIndex = 0;
        for (let curUserProduct of groupedUserData) {
            let userId = userLookup[curUserProduct.UserMail.toLowerCase()];
            if(!userId) {
                let userLogEntry = new UserProductImportLogEntry(curUserLogIndex, curUserProduct.UserMail, "", true, "userNotFound");
                ++curUserLogIndex;
    
                curProgress += progressPerUser;
                this.setImportProgress(curProgress, userLogEntry);

                continue;
            }

            let anyValidProduct = false;
            let errorOccured = false;
            let errorMessage = "";

            try {
                let anyChange = false;
                let currentUserProducts = await AdminFactoryService.getAdminService().getUserAdminProducts(userId);
                for(let curProduct of curUserProduct.Products)
                {
                    let start = new Date(curProduct.Start);
                    if(isNaN(start.getTime()))
                    {
                        let userLogEntry = new UserProductImportLogEntry(curUserLogIndex, curUserProduct.UserMail, curProduct.ProduktNummer, true, "missingOrInvalidStartDate");
                        ++curUserLogIndex;
                        this.setImportProgress(curProgress, userLogEntry);
                        continue;
                    }

                    let end = new Date(9999, 11, 31);
                    if(curProduct.Ende)
                    {
                        end = new Date(curProduct.Ende);
                        if(isNaN(end.getTime()))
                        {
                            let userLogEntry = new UserProductImportLogEntry(curUserLogIndex, curUserProduct.UserMail, curProduct.ProduktNummer, true, "invalidEndDate");
                            ++curUserLogIndex;
                            this.setImportProgress(curProgress, userLogEntry);
                            continue;
                        }
                    }

                    let productToAdd = this.findProductToAdd(availableProducts, curProduct.ProduktNummer);
                    if(!productToAdd) {
                        let userLogEntry = new UserProductImportLogEntry(curUserLogIndex, curUserProduct.UserMail, curProduct.ProduktNummer, true, "productNotFound");
                        ++curUserLogIndex;
                        this.setImportProgress(curProgress, userLogEntry);
                        continue;
                    }

                    let alreadyAddedProduct = currentUserProducts.find(p => p.productId === productToAdd?.id && this.areDatesEqual(p.start, start) && this.areDatesEqual(p.end, end));
                    if(alreadyAddedProduct) {
                        continue;
                    }

                    anyChange = true;
                    let userProduct = new UserProduct(productToAdd.id, productToAdd.name, productToAdd.filename, new Date(start), new Date(end));
                    currentUserProducts.push(userProduct);
                    anyValidProduct = true;
                }

                if(anyChange) {
                    await AdminFactoryService.getAdminService().saveUserAdminProducts(userId, currentUserProducts);
                } else {
                    await this.uiResponsiveSleep();
                }
            }
            catch (e) {
                console.error(e);
                errorOccured = true;
                errorMessage = "productCouldNotBeImported";
            }
            
            let userLogEntry = new UserProductImportLogEntry(curUserLogIndex, curUserProduct.UserMail, "", errorOccured, errorMessage);
            ++curUserLogIndex;

            curProgress += progressPerUser;
            this.setImportProgress(curProgress, anyValidProduct ? userLogEntry : null);
        }
    }

    /**
     * Sleeps for a moment to keep the ui responsive
     */
    private uiResponsiveSleep(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, 30);
        });
    }
    
    /**
     * Finds a product to add
     * @param availableProducts Available products to add 
     * @param productNumber Product number to search
     * @returns Available products
     */
    private findProductToAdd(availableProducts: AvailableUserProduct[], productNumber: string): AvailableUserProduct | undefined {
        let exactMatch = availableProducts.find(a => a.id === productNumber);
        if(exactMatch) {
            return exactMatch;
        }

        // In case of book the last 5 digits of the EAN is provided
        return availableProducts.find(a => a.id.startsWith("b_") && a.id.endsWith(productNumber));
    }

    /**
     * Returns true if two dates are equal
     * @param d1 Date 1
     * @param d2 Date 2
     * @returns true if the dates are equal
     */
    private areDatesEqual(d1: Date, d2: Date): boolean {
        if(!d1 && !d2) {
            return true;
        }

        if(!d1 || !d2) {
            return false;
        }

        if(isNaN(d1.getTime()) && isNaN(d2.getTime())) {
            return true;
        }

        if(isNaN(d1.getTime()) || isNaN(d2.getTime())) {
            return false;
        }

        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    }
    
    /**
     * Groups the userdata to a list of products per user from the CSV
     * @param userData User data to group
     * @returns Grouped user data
     */
    private groupUserData(userData: CsvUserProductData[]): GroupedUserProductData[] {
        let groupedData: GroupedUserProductData[] = [];

        for(let curUserData of userData)
        {
            if(!curUserData.BenutzerEMail)
            {
                continue;
            }

            let targetGroup = groupedData.find(g => g.UserMail === curUserData.BenutzerEMail.toLowerCase());
            if(!targetGroup)
            {
                targetGroup = new GroupedUserProductData();
                targetGroup.UserMail = curUserData.BenutzerEMail.toLowerCase();
                targetGroup.Products = [];
                groupedData.push(targetGroup);
            }

            targetGroup.Products.push(curUserData);
        }

        return groupedData;
    }

    /**
     * Sets the import progress
     * @param progress Progress in percent (0 - 100)
     * @param userLogEntry User import log entry
     */
    private setImportProgress(progress: number, userLogEntry: UserProductImportLogEntry | null): void {
        let importLog = this.state.importLog;
        if (userLogEntry) {
            importLog.unshift(userLogEntry);    // Push to beginning to keep it at bottom since stack is reversed for scroll
        }

        this.setState({
            importProgress: progress / 100,
            importLog: importLog
        });
    }

    /**
     * Reads the file content
     * @param file File to read
     * @returns File content
     */
    private readFileContent(file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = function (evt) {
                resolve(evt.target?.result?.toString());
            }
            reader.onerror = function () {
                reject();
            }
        });
    }

    /**
     * Gets called if the pannel must be closed
     */
    private onClosePanel = (): void => {
        this.setState({
            isImportRunning: false,
            showImportLog: false,
            isLoading: false,
            importProgress: 0,
            importLog: []
        })
        this.props.onClosePanel();
    }
}

export default UserImportPanel; 