/**
 * EventBus Events
 */
enum EventBusEvent {
    /**
     * Gets called if a loading process starts
     */
    OnLoadStarted,

    /**
     * Gets called if a loading process is done
     */
    OnLoadFinished
}

export default EventBusEvent;