/**
 * Debounces a function to run it later
 * @param func Function to debounce
 * @param context Context of the call
 * @param wait Wait time
 * @param immediate true if the function must be called immedieat and then debounce or not
 * @returns Debounced function
 */
export default function debounce(func: any, context: any, wait: number, immediate: boolean) {
    let timeout: any;
    return function() {
      let args = arguments;
      let later = function() {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
          func.apply(context, args);
      };
    };
  }
  