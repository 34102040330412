import UpdateUserDataRequest from './../../../dto/account/UpdateUserDataRequest';
import UserData from './../../../dto/account/UserData';

/**
 * My profile page state class
 */
class MyProfileState {
    /**
     * Request to set the new user data
     */
    public updateUserDataRequest: UpdateUserDataRequest;

    /**
     * Existing user data
     */
    public existingUserData: UserData | null;

    /**
     * true if the page is loading the user data
     */
    public isLoading: boolean;

    /**
     * true if an error during loading occured
     */
    public loadErrorOccured: boolean;

    /**
     * true if an error occured while sending the update request
     */
    public requestErrorOccured: boolean;

    /**
     * true if the new data could be set
     */
    public dataWasChanged: boolean;

    /**
     * Constructor
     */
    public constructor()
    {
        this.updateUserDataRequest = new UpdateUserDataRequest();
        this.existingUserData = null;
        this.isLoading = false;
        this.loadErrorOccured = false;
        this.requestErrorOccured = false;
        this.dataWasChanged = false;
    }
}

export default MyProfileState;