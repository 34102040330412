/**
 * Subscription connect request
 */
class SubscriptionConnectRequest {
    /**
     * Subscription number
     */
    public subscriptionNumber: string;

    /**
     * Constructor
     */
    public constructor() {
        this.subscriptionNumber = "";
    }
}

export default SubscriptionConnectRequest;