import CsvUserProductData from './CsvUserProductData';

/**
 * Grouped user product data
 */
class GroupedUserProductData {
    /**
     * User mail that was affected
     */
    public UserMail: string;

    /**
     * Number of the product that is added
     */
    public Products: CsvUserProductData[];

    /**
     * Constructor
     */
    public constructor()
    {
        this.UserMail = "";
        this.Products = [];
    }
}

export default GroupedUserProductData;