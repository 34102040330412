import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';
import { ColorClassNames, PrimaryButton, FontClassNames, FontWeights, Spinner, SpinnerSize, Label, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

// Components
import TranslatedComponent from '../../localization/TranslatedComponent';
import UserDataForm from './../shared/userDataForm/UserDataForm';
import styles from './Register.module.css';

// Services

// Dto
import IRegisterProps from './dto/IRegisterProps';
import RegisterState from './dto/RegisterState';
import AccountFactory from '../../services/account/AccountFactory.service';

// Util
import scrollToTop from './../../util/ScrollToTop';
import UserData from '../../dto/account/UserData';

/**
 * Register Component
 */
class Register extends TranslatedComponent<IRegisterProps, RegisterState> {
    /**
     * Recaptcha ref
     */
    private recaptchaRef: ReCAPTCHA | null = null;

    /**
     * User Data form ref
     */
    private userDataFormRef: UserDataForm | null = null;

    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<IRegisterProps>) {
        super(props);

        let state = new RegisterState();

        let configObject: any = (window as any)["VulkanVerlag"];
        if (configObject && configObject.Config && configObject.Config.CaptchaKey) {
            state.captchaKey = configObject.Config.CaptchaKey;
        }

        this.state = state;
    }

    /**
     * Renders the register component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <div className={`${styles.registerContainer} ${ColorClassNames.whiteBackground}`}>
                <h1 className={`${FontClassNames.xLargePlus} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("register.header")}</h1>
                {!this.state.accountWasCreated ?
                    this.renderRegisterForm(t) :
                    this.renderRegisterSuccess(t)}
            </div>
        );
    }

    /**
     * Renders the register form
     * @param t Translate Function
     * @returns Register form
     */
    private renderRegisterForm = (t: TFunction): ReactNode => {
        return (
            <div>
                {this.renderRequestErrorOccured(t)}
                
                <UserDataForm existingUserData={null} onUserDataChanged={this.onUserDataChanged} ref={(r) => this.userDataFormRef = r } hideUsername={true} hideMiscFields={true} hideIpAuthFields={true} hideLoginFields={false}></UserDataForm>
                <div className={styles.captchaContainer}>
                    {this.state.captchaKey ? <ReCAPTCHA ref={(r) => this.recaptchaRef = r} sitekey={this.state.captchaKey} onChange={this.onCaptchaValidated} onExpired={this.resetCaptcha} onErrored={this.resetCaptcha} hl={t("general.repatchaLocale")} /> : "CAPTCHA NOT SHOWN IN SERVE MODE"}
                    {(this.state.showErrorMessage && this.state.captchaKey && !this.state.registerRequest.captchaToken) &&
                        <Label className={`${ColorClassNames.redDark} ${FontClassNames.smallPlus}`}>{t("register.confirmCaptcha")}</Label>}
                </div>
                <PrimaryButton disabled={this.state.isLoading} onClick={this.register}>
                    {this.state.isLoading && <Spinner className={styles.registerButtonSpinner} size={SpinnerSize.xSmall}></Spinner>}
                    {t("register.register")}
                </PrimaryButton>
            </div>
        )
    }

    /**
     * Renders a message that a request error occured
     * @param t Translate function
     */
    private renderRequestErrorOccured = (t: TFunction): ReactNode => {
        if (!this.state.requestErrorMessage) {
            return null;
        }

        return (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.removeRequestErrorBanner} dismissButtonAriaLabel={t("general.close")}>
                {t(`general.error.${this.state.requestErrorMessage}`)}
            </MessageBar>
        )
    }

    /**
     * Renders the register success
     * @param t Translate Function
     * @returns Register success
     */
    private renderRegisterSuccess = (t: TFunction): ReactNode => {
        return (
            <div>
                <p>{t("register.successMessage")}</p>
                <Link className={`${ColorClassNames.themePrimary} ${styles.redirectToLoginLink}`} to="/login">{t("register.loginMessage")}</Link>
            </div>
        )
    }

    /**
     * Removes the request error banner
     */
    private removeRequestErrorBanner = () => {
        this.setState({
            requestErrorMessage: ""
        });
    }

    /**
     * Gets called if the user validates the captcha
     * @param token Captcha token that was validated
     */
    private onCaptchaValidated = (token: string | null): void => {
        let registerRequest = this.state.registerRequest.clone();
        registerRequest.captchaToken = token ? token : "";

        this.setState({
            registerRequest: registerRequest
        })
    }

    /**
     * Resets the captcha value
     */
    private resetCaptcha = (): void => {
        let registerRequest = this.state.registerRequest.clone();
        registerRequest.captchaToken = "";

        this.setState({
            registerRequest: registerRequest
        })
    }

    /**
     * Gets called if the user data is changed
     */
    private onUserDataChanged = (u: UserData): void => {
        let registerRequest = this.state.registerRequest.clone();
        registerRequest.assignFromUserData(u);

        this.setState({
            registerRequest: registerRequest
        })
    }

    /**
     * Sends a register request
     */
    private register = async () => {
        if(!this.userDataFormRef) {
            return;
        }

        if (!this.userDataFormRef.validate() || (this.state.captchaKey && !this.state.registerRequest.captchaToken)) {
            this.setState({
                showErrorMessage: true
            });
            return;
        }

        this.setState({
            showErrorMessage: false,
            isLoading: true,
            requestErrorMessage: ""
        });
        try {
            await AccountFactory.getAccountService().register(this.state.registerRequest);
            this.setState({
                isLoading: false,
                accountWasCreated: true
            });
        }
        catch (e) {
            let errorMessage = "generic";
            if (e.response && e.response.status < 500 && e.response.data && e.response.data.message) {
                errorMessage = e.response.data.message;
            }
            this.setState({
                isLoading: false,
                requestErrorMessage: errorMessage
            });

            scrollToTop();

            if (this.recaptchaRef) {
                this.recaptchaRef.reset();
            }
        }
    }
}

export default Register; 