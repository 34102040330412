import RegisterRequest from "../../../dto/account/RegisterRequest";

/**
 * Register page state class
 */
class RegisterState {
    /**
     * Register Request
     */
    public registerRequest: RegisterRequest;

    /**
     * Password repeat data
     */
    public repeatPassword: string;

    /**
     * Captcha key
     */
    public captchaKey: string;

    /**
     * true if error messages should be shown on empty
     */
    public showErrorMessage: boolean;

    /**
     * true if the page is loading
     */
    public isLoading: boolean;

    /**
     * Request error message
     */
    public requestErrorMessage: string;

    /**
     * The account was created successfully
     */
    public accountWasCreated: boolean;

    /**
     * Constructor
     */
    public constructor() {
        this.registerRequest = new RegisterRequest();
        this.repeatPassword = "";
        this.captchaKey = "";
        this.showErrorMessage = false;
        this.isLoading = false;
        this.requestErrorMessage = "";
        this.accountWasCreated = false;
    }
}

export default RegisterState;