import { TFunction } from 'i18next';

/**
 * Ensures the length of a number value as a string
 * @param val Value to format
 * @returns Number as string
 */
function ensureLengthOfTwo(val: number): string {
    let formattedVal = val.toString();
    if(formattedVal.length < 2)
    {
        formattedVal = "0" + formattedVal;
    }

    return formattedVal;
}

/**
 * Formats a date
 * @param t Translate function
 * @param date Date to Format
 * @param includeTime true if the time must be included
 */
export default function formatDate(t:TFunction, date?: Date, includeTime: boolean = false): string {
    if(!date) {
        return "";
    }

    let format = t(!includeTime ? "general.dateFormat" : "general.dateTimeFormat");
    format = format.replace(/dd/, ensureLengthOfTwo(date.getDate()));
    format = format.replace(/MM/, ensureLengthOfTwo(date.getMonth() + 1));
    format = format.replace(/yyyy/, date.getFullYear().toString());
    format = format.replace(/hh/, ensureLengthOfTwo(date.getHours()));
    format = format.replace(/mm/, ensureLengthOfTwo(date.getMinutes()));
    format = format.replace(/ss/, ensureLengthOfTwo(date.getSeconds()));

    return format;
}