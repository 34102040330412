/**
 * Login page state class
 */
class LoginState {
    /**
     * Username
     */
    public username: string;

    /**
     * Password
     */
    public password: string;

    /**
     * true if error messages should be shown on empty
     */
    public showErrorMessage: boolean;

    /**
     * true if an auth error occured
     */
    public authErrorOccured: boolean;

    /**
     * Text of the auth error message
     */
    public authErrorMessage: string;

    /**
     * true if the login process is running
     */
    public isLoading: boolean;

    /**
     * Constructor
     */
    public constructor() {
        this.username = "";
        this.password = "";
        this.showErrorMessage = false;
        this.authErrorOccured = false;
        this.authErrorMessage = "";
        this.isLoading = false;
    }
}

export default LoginState;