import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';
import { ColorClassNames, FontClassNames, FontWeights, PrimaryButton, Spinner, SpinnerSize, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';
import { Redirect } from 'react-router-dom';

// Components
import TranslatedComponent from '../../localization/TranslatedComponent';
import UserDataForm from '../shared/userDataForm/UserDataForm';
import styles from './MyProfile.module.css';

// Services

// Dto
import IMyProfileProps from './dto/IMyProfileProps';
import MyProfileState from './dto/MyProfileState';
import UpdateUserDataRequest from '../../dto/account/UpdateUserDataRequest';
import AccountFactory from '../../services/account/AccountFactory.service';
import UserData from '../../dto/account/UserData';

/**
 * My Profile Component
 */
class MyProfile extends TranslatedComponent<IMyProfileProps, MyProfileState> {
    /**
     * User Data form ref
     */
    private userDataFormRef: UserDataForm | null = null;

    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<IMyProfileProps>) {
        super(props);

        this.state = new MyProfileState();
    }

    /**
     * Loads the current user data
     */
    public componentDidMount() {
        this.setState({
            isLoading: true
        });

        AccountFactory.getAccountService().getCurrentUserData().then((currentUserData) => {
            let userData: UserData = new UserData();
            userData.salutation = currentUserData.salutation;
            userData.firstname = currentUserData.firstname;
            userData.lastname = currentUserData.lastname;
            
            userData.companyName = currentUserData.companyName;
            userData.companyStreet = currentUserData.companyStreet;
            userData.companyCity = currentUserData.companyCity;
            userData.companyPostalCode = currentUserData.companyPostalCode;
            userData.companyCountry = currentUserData.companyCountry;

            let updateUserDataRequest = new UpdateUserDataRequest();
            updateUserDataRequest.assignFromUserData(userData);

            this.setState({
                updateUserDataRequest: updateUserDataRequest,
                existingUserData: userData,
                isLoading: false
            });
        }, () => {
            this.setState({
                requestErrorOccured: true,
                loadErrorOccured: true,
                isLoading: false
            });
        });
    }

    /**
     * Renders the my profile component
     * @param t Translate function
     * @returns Rendered component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <div className={`${styles.myProfileContainer} ${ColorClassNames.whiteBackground}`}>
                {this.renderUnauthorizedRedirect()}
                <h1 className={`${FontClassNames.xLargePlus} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("myProfile.header")}</h1>
                {this.renderRequestErrorOccured(t)}
                {this.renderSuccessBanner(t)}
                
                <UserDataForm existingUserData={this.state.existingUserData} onUserDataChanged={this.onUserDataChanged} ref={(r) => this.userDataFormRef = r } hideUsername={true} hideMiscFields={true} hideIpAuthFields={true} hideLoginFields={true}></UserDataForm>
                <p>
                    <PrimaryButton onClick={this.onChangeData} disabled={this.state.isLoading || this.state.loadErrorOccured}>
                        {this.state.isLoading && <Spinner className={styles.myProfileButtonSpinner} size={SpinnerSize.xSmall}></Spinner>}
                        {t("myProfile.updateData")}
                    </PrimaryButton>
                </p>
            </div>
        );
    }

    /**
     * Renders a redirect if the current user is not logged in
     */
    private renderUnauthorizedRedirect = (): ReactNode => {
        if(this.props.currentUser && this.props.currentUser.isAuthenticated && !this.props.currentUser.isIpAuthenticated)
        {
            return null;
        }

        return (
            <Redirect to={{
                pathname: "/login",
                state: { from: "/myProfile" }
            }}></Redirect>
        )
    }

    /**
     * Renders a message that the data was changed
     * @param t Translate function
     */
    private renderSuccessBanner = (t: TFunction): ReactNode => {
        if (!this.state.dataWasChanged) {
            return null;
        }

        return (
            <MessageBar messageBarType={MessageBarType.success} onDismiss={this.removeSuccessBanner} dismissButtonAriaLabel={t("general.close")}>
                {t("myProfile.dataWasChanged")}
            </MessageBar>
        )
    }

    /**
     * Renders a message that a request error occured
     * @param t Translate function
     */
    private renderRequestErrorOccured = (t: TFunction): ReactNode => {
        if (!this.state.requestErrorOccured) {
            return null;
        }

        return (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={!this.state.loadErrorOccured ? this.removeRequestErrorBanner : undefined} dismissButtonAriaLabel={t("general.close")}>
                {t("general.error.generic")}
            </MessageBar>
        )
    }
    
    /**
     * Removes the success banner
     */
    private removeSuccessBanner = () => {
        this.setState({
            dataWasChanged: false
        });
    }

    /**
     * Removes the request error banner
     */
    private removeRequestErrorBanner = () => {
        this.setState({
            requestErrorOccured: false
        });
    }

    /**
     * Gets called if the user data is changed
     */
    private onUserDataChanged = (u: UserData): void => {
        let updateUserDataRequest = this.state.updateUserDataRequest.clone();
        updateUserDataRequest.assignFromUserData(u);

        this.setState({
            updateUserDataRequest: updateUserDataRequest
        })
    }

    /**
     * Gets called if the new data must be set
     */
    private onChangeData = async (): Promise<void> => {
        if(!this.userDataFormRef) {
            return;
        }

        if (!this.userDataFormRef.validate()) {
            this.setState({
                isLoading: false,
                dataWasChanged: false,
                requestErrorOccured: false
            });
            return;
        }
        
        this.setState({
            isLoading: true,
            dataWasChanged: false,
            requestErrorOccured: false
        });

        try {
            await AccountFactory.getAccountService().updateUserData(this.state.updateUserDataRequest);
            
            this.setState({
                isLoading: false,
                dataWasChanged: true
            });
        }
        catch (e) {
            this.setState({
                isLoading: false,
                requestErrorOccured: true
            });
        }
    }
}

export default MyProfile; 