/**
 * Returns true if the application is running in serve mode, else false
 * @returns true if the application is running in serve mode, else false
 */
function isDevMode() {
    if (window.location.href.includes("localhost:3000")) {
        return true;
    }
    return false;
}

export default isDevMode;