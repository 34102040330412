import mockLoadingTime from "./../../util/MockLoadingTime";
import DocumentEntry from "../../dto/documentAccess/DocumentEntry";
import DocumentDetailEntry from "../../dto/documentAccess/DocumentDetailEntry";
import IDocumentAccessService from "./IDocumentAccess.service";

/**
 * Mock document access services
 */
class MockDocumentAccessService implements IDocumentAccessService {
    /**
     * Returns the latest document entry
     * @returns Latest document entry
     */
    public async getLatestDocumentEntry(): Promise<DocumentEntry> {
        await mockLoadingTime();

        let entry: DocumentEntry = new DocumentEntry();
        entry.id = "1";
        entry.title = "gwf Gas + Energie";
        entry.edition = "02";
        entry.pageCount = 120;
        entry.publicationDate = new Date(2020, 1, 1);

        return entry;
    }
    
    /**
     * Returns the document entry details for a document entry
     * @param id Id of the document entry
     * @returns Details of the document entry
     */
    public async getDocumentEntryDetails(id: string): Promise<DocumentDetailEntry> {
        await mockLoadingTime();

        let entry: DocumentDetailEntry = new DocumentDetailEntry();
        entry.id = id;
        entry.title = "Das Buch";
        entry.subTitle = "Es ist sehr gut.";
        entry.description = "Ein Buch...<br/>es ist <b>großartig.</b> <script>alert('ok')</script>"
        entry.ean = "9783125171541";
        entry.isbn = "ISBN-10 3-12-517154-7";
        entry.edition = "Erste Auflage";
        entry.author = "Andrea Autorin";
        entry.editor = "Erik Editor";
        entry.publisher = "Die Veröffentlicher";
        entry.pageCount = 120;
        entry.tags = "Buch;Ein Buch";
        
        return entry;
    }
}

export default MockDocumentAccessService;