/**
 * Reset password request data
 */
class ResetPasswordRequest {
    /**
     * Email
     */
    public email: string;

    /**
     * Constructor
     */
    public constructor() {
        this.email = "";
    }
}

export default ResetPasswordRequest;