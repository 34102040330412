import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';

// Services
import DocumentAccessFactory from './../../services/documentAccess/DocumentAccessFactory.service';

// Components
import TranslatedComponent from '../../localization/TranslatedComponent';
import styles from './Home.module.css';
import { FontClassNames, ColorClassNames, FontWeights, Text, Stack, Icon } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';

// Dto
import IHomeProps from './dto/IHomeProps';
import HomeState from './dto/HomeState';

/**
 * Home Component
 */
class Home extends TranslatedComponent<IHomeProps, HomeState> {
    /**
     * Constructor
     * @param props Input props 
     */
    public constructor(props: IHomeProps) {
        super(props);

        this.state = new HomeState();
    }

    /**
     * Gets called as the component did mount
     */
    public componentDidMount() {
        DocumentAccessFactory.getDocumentAccessService().getLatestDocumentEntry().then((documentEntry) => {
            if(documentEntry) {
                let name = `${documentEntry.title} Ausgabe ${documentEntry.edition} ${documentEntry.publicationDate.getFullYear()}`;
                this.setState({
                    thumbnailName: name
                });
            }
        }, (err) => {
            // Simply dont display thumbnail if error occures
        });
    }

    /**
     * Renders the home component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <div className={styles.mainHomeContainer}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl8">
                            <h1 className={`${FontClassNames.xLargePlus} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("home.welcome")}</h1>
                            <Text className={ColorClassNames.neutralPrimary}><p className={FontClassNames.mediumPlus}>{t("home.sub")}</p></Text>
                            <h2 className={`${FontClassNames.xLarge} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("home.q1")}</h2>
                            <Text className={ColorClassNames.neutralPrimary}><p className={FontClassNames.mediumPlus}>{t("home.q1text")}</p></Text>
                            <Text className={ColorClassNames.neutralPrimary}><p className={FontClassNames.mediumPlus}>{t("home.q1text2")}</p></Text>
                            <h2 className={`${FontClassNames.xLarge} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("home.q2")}</h2>
                            <Text className={ColorClassNames.neutralPrimary}><p className={FontClassNames.mediumPlus}>{t("home.q2text")}</p></Text>
                        </div>
                        <div className={`ms-Grid-col ms-hiddenLgDown ms-xl4 ${styles.center}`}>
                            { this.state.thumbnailName && (
                                <div>
                                    <h2 className={`${FontClassNames.xLargePlus} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("home.promo")}</h2>
                                    <Stack horizontal verticalAlign="center">
                                        <Stack.Item grow styles={{ root: { textAlign: "right", paddingRight: "10px" } }}>
                                            <Icon styles={{ root: { display: "none" }}} iconName="CaretLeft8" className={ColorClassNames.neutralPrimary} />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <img src="/api/Thumbnail/LatestThumbnail" alt="Zeitschrift" className={styles.thumbnail} />
                                        </Stack.Item>
                                        <Stack.Item grow styles={{ root: { textAlign: "left", paddingLeft: "10px" } }}>
                                            <Icon styles={{ root: { display: "none" }}} iconName="CaretRight8" className={ColorClassNames.neutralPrimary} />
                                        </Stack.Item>
                                    </Stack>
                                    <Text variant="mediumPlus" className={`${ColorClassNames.neutralPrimary}`} style={{ fontWeight: FontWeights.light as number }}>{this.state.thumbnailName}</Text>
                                </div>
                            )}
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}
// TODO: <Video></Video> Embedden!! TODO: Name mit aufnehmnen, Firma und Adresse (kein Pflicht), Anrede, Captcha vor der Registrierung
// Benutzer Logo - pro Account. 
// ICON - Sternchen
export default Home; 