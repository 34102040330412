import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';
import { ColorClassNames, FontClassNames, Text, FontWeights, Stack, TextField, PrimaryButton, IStackTokens, Spinner, SpinnerSize, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';
import { Redirect } from 'react-router-dom';

// Components
import TranslatedComponent from '../../localization/TranslatedComponent';
import styles from './MySubscriptions.module.css';

// Services
import SubscriptionsFactoryService from './../../services/subscriptions/SubscriptionsFactory.service';

// Dto
import IMySubscriptionsProps from './dto/IMySubscriptionsProps';
import MySubscriptionsState from './dto/MySubscriptionsState';
import SubscriptionConnectRequest from '../../dto/subscriptions/SubscriptionConnectRequest';

/**
 * My Subscriptions Component
 */
class MySubscriptions extends TranslatedComponent<IMySubscriptionsProps, MySubscriptionsState> {
    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<IMySubscriptionsProps>) {
        super(props);

        this.state = new MySubscriptionsState();
    }

    /**
     * Loads the current user data
     */
    public componentDidMount() {
        this.loadUserSubscriptions();
    }

    /**
     * Loads the user subscriptions
     */
    private loadUserSubscriptions() {
        this.setState({
            isLoading: true
        });
        SubscriptionsFactoryService.getSubscriptionsService().getMySubscriptions().then((subscriptionData) => {
            this.setState({
                currentUserSubscriptions: subscriptionData,
                isLoading: false
            });
        }, () => {
            this.setState({
                loadErrorOccured: true,
                isLoading: false
            });
        });
    }

    /**
     * Renders the my subscriptions component
     * @param t Translate function
     * @returns Rendered component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <div className={`${styles.mySubscriptionsContainer} ${ColorClassNames.whiteBackground}`}>
                {this.renderUnauthorizedRedirect()}
                <h1 className={`${FontClassNames.xLargePlus} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("mySubscriptions.header")}</h1>
                {this.renderSuccessBanner(t)}
                {this.renderCouldNotLoadBanner(t)}
                {this.renderCouldNotConnectBanner(t)}
                {this.renderAddSubscriptionSection(t)}
                {this.renderUserSubscriptions(t)}
            </div>
        );
    }

    /**
     * Renders a redirect if the current user is not logged in
     */
    private renderUnauthorizedRedirect = (): ReactNode => {
        if (this.props.currentUser && this.props.currentUser.isAuthenticated && !this.props.currentUser.isIpAuthenticated) {
            return null;
        }

        return (
            <Redirect to={{
                pathname: "/login",
                state: { from: "/mySubscriptions" }
            }}></Redirect>
        )
    }

    /**
     * Renders a banner if a subscription can not be connected
     * @param t Translate function
     */
    private renderCouldNotConnectBanner = (t: TFunction): ReactNode => {
        if (!this.state.subscriptionConnectErrorOccured) {
            return null;
        }

        return (
            <MessageBar messageBarType={MessageBarType.error} onDismiss={this.removeCouldNotConnectBanner} dismissButtonAriaLabel={t("general.close")}>
                {t(this.state.subscriptionNumberInUse ? "mySubscriptions.subscriptionNumberInUse" : "mySubscriptions.subscriptionCouldNotBeAdded")}
            </MessageBar>
        )
    }

    /**
     * Renders a banner if the subscriptions of the user could not be loaded
     * @param t Translate function
     */
    private renderCouldNotLoadBanner = (t: TFunction): ReactNode => {
        if (!this.state.loadErrorOccured) {
            return null;
        }

        return (
            <MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={t("general.close")}>
                {t("mySubscriptions.subscriptionsCouldNotBeLoaded")}
            </MessageBar>
        )
    }

    /**
     * Renders a success banner
     * @param t Translate function
     */
    private renderSuccessBanner = (t: TFunction): ReactNode => {
        if (!this.state.subscriptionWasConnected) {
            return null;
        }

        return (
            <MessageBar messageBarType={MessageBarType.success} onDismiss={this.removeSuccessBanner} dismissButtonAriaLabel={t("general.close")}>
                {t("mySubscriptions.subscriptionWasAdded")}
            </MessageBar>
        )
    }

    /**
     * Renders a section to add a new subscription
     * @param t Translate function
     */
    private renderAddSubscriptionSection = (t: TFunction): ReactNode => {
        const horizontalGapStackTokens: IStackTokens = {
            childrenGap: 10,
            padding: 10,
        };

        return (
            <div style={{ paddingLeft: "10px" }}>
                <Text className={ColorClassNames.neutralPrimary}>
                    <p className={FontClassNames.mediumPlus} style={{ paddingLeft: "10px", marginBottom:"0" }}>
                    {t("mySubscriptions.enterSubscriptionNumberToConnect")}</p>
                </Text>
                <Stack horizontal wrap tokens={horizontalGapStackTokens} padding="0" style={{ padding: 0 }}>
                    <Stack.Item>
                        <TextField readOnly={this.state.loadErrorOccured} errorMessage={this.getSubscriptionNumberError(t)} onChange={this.onSubscriptionNumberToConnectChanged} value={this.state.subscriptionConnectRequest.subscriptionNumber} placeholder={t("mySubscriptions.subscriptionNumber")}></TextField>
                    </Stack.Item>
                    <Stack.Item>
                        <PrimaryButton onClick={this.onAddSubscription} disabled={this.state.isSaving || this.state.isLoading || this.state.loadErrorOccured}>
                            {this.state.isSaving && <Spinner className={styles.mySubscriptionsButtonSpinner} size={SpinnerSize.xSmall}></Spinner>}
                            {t("mySubscriptions.addSubscription")}
                        </PrimaryButton>
                    </Stack.Item>
                </Stack>
            </div>
        )
    }

    /**
     * Renders a section to display the current user subscriptions
     * @param t Translate function
     */
    private renderUserSubscriptions = (t: TFunction): ReactNode => {
        return (
            <div>
                <h2 className={`${FontClassNames.xLarge} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("mySubscriptions.mySubscriptions")}</h2>
                <ul className={ColorClassNames.themePrimary}>
                    {this.state.currentUserSubscriptions.map(s => <li className={`${FontClassNames.mediumPlus}`} key={s.id}><Text className={`${FontClassNames.mediumPlus} ${ColorClassNames.neutralPrimary}`} >{`${s.productName} (${s.subscriptionNumber})`}{!s.isActive && <span>{t("mySubscriptions.inactive")}</span>}</Text></li>)}
                </ul>
            </div>
        )
    }

    /**
     * Removes the success banner
     */
    private removeSuccessBanner = () => {
        this.setState({
            subscriptionWasConnected: false
        });
    }

    /**
     * Removes the could not connect banner
     */
    private removeCouldNotConnectBanner = () => {
        this.setState({
            subscriptionConnectErrorOccured: false
        });
    }

    /**
     * Handles the change of the subscription number to change field
     * @param _ev Event
     * @param val New value of the field
     */
    private onSubscriptionNumberToConnectChanged = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) => {
        if (!val) {
            val = "";
        }

        let subscriptionConnectRequest = { ...this.state.subscriptionConnectRequest };
        subscriptionConnectRequest.subscriptionNumber = val;

        this.setState({
            subscriptionConnectRequest: subscriptionConnectRequest
        });
    }

    /**
     * Returns the error message for the subscription number field
     * @param t Translate function
     * @returns Error message for subscription number field
     */
    private getSubscriptionNumberError = (t: TFunction): string => {
        if (!this.state.showErrorMessage || this.state.subscriptionConnectRequest.subscriptionNumber) {
            return "";
        }

        return t("general.mandatoryField");
    }

    /**
     * Adds a subscription
     */
    private onAddSubscription = async (): Promise<void> => {
        if (!this.state.subscriptionConnectRequest.subscriptionNumber) {
            this.setState({
                showErrorMessage: true,
                isSaving: false,
                subscriptionWasConnected: false,
                subscriptionNumberInUse: false
            });
            return;
        }

        this.setState({
            showErrorMessage: false,
            isSaving: true,
            subscriptionWasConnected: false,
            subscriptionConnectErrorOccured: false,
            subscriptionNumberInUse: false
        });

        try {
            await SubscriptionsFactoryService.getSubscriptionsService().connectSubscription(this.state.subscriptionConnectRequest);
            this.setState({
                subscriptionConnectRequest: new SubscriptionConnectRequest(),
                isSaving: false,
                subscriptionWasConnected: true
            }, () => {
                this.loadUserSubscriptions();
            });
        }
        catch (e)
        {
            let isSubscriptionInUse = false;
            if (e.response && e.response.status < 500 && e.response.data && e.response.data.message === "numberInUse") {
                isSubscriptionInUse = true;
            }
            this.setState({
                isSaving: false,
                subscriptionConnectErrorOccured: true,
                subscriptionNumberInUse: isSubscriptionInUse
            });
        }
    }
}

export default MySubscriptions; 