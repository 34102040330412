import UserData from "./UserData";

/**
 * Update user data request
 */
class UpdateUserDataRequest {
    /**
     * Firstname
     */
    public salutation: string;

    /**
     * Firstname
     */
    public firstname: string;

    /**
     * Lastname
     */
    public lastname: string;
    
    /**
     * Company name
     */
    public companyName: string;
    
    /**
     * Company street
     */
    public companyStreet: string;
    
    /**
     * Company postal code
     */
    public companyPostalCode: string;
    
    /**
     * Company city
     */
    public companyCity: string;

    /**
     * Company country
     */
    public companyCountry: string;

    /**
     * Constructor
     */
    public constructor() {
        this.salutation = "";
        this.firstname = "";
        this.lastname = "";
        this.companyName = "";
        this.companyStreet = "";
        this.companyPostalCode = "";
        this.companyCity = "";
        this.companyCountry = "";
    }
    
    /**
     * Assigns the user data from another user data object
     * @param userData User data to copy
     */
    public assignFromUserData(userData: UserData) {
        this.salutation = userData.salutation;
        this.firstname = userData.firstname;
        this.lastname = userData.lastname;
        this.companyName = userData.companyName;
        this.companyStreet = userData.companyStreet;
        this.companyPostalCode = userData.companyPostalCode;
        this.companyCity = userData.companyCity;
        this.companyCountry = userData.companyCountry;
    }

    /**
     * Clones the request
     * @returns Cloned request
     */
    public clone(): UpdateUserDataRequest {
        let userDataRequest = new UpdateUserDataRequest();
        userDataRequest.salutation = this.salutation;
        userDataRequest.firstname = this.firstname;
        userDataRequest.lastname = this.lastname;
        userDataRequest.companyName = this.companyName;
        userDataRequest.companyStreet = this.companyStreet;
        userDataRequest.companyPostalCode = this.companyPostalCode;
        userDataRequest.companyCity = this.companyCity;
        userDataRequest.companyCountry = this.companyCountry;

        return userDataRequest;
    }
}

export default UpdateUserDataRequest;