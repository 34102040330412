import MyDownloadsEntry from "./MyDownloadsEntry";

/**
 * Result of a my downloads query
 */
class MyDownloadsQueryResult {
    /**
     * Total count of downloads
     */
    public totalCount: number;

    /**
     * Download Entries
     */
    public entries: MyDownloadsEntry[];

    /**
     * Constructor
     */
    public constructor() {
        this.totalCount = 0;
        this.entries = [];
    }
}

export default MyDownloadsQueryResult;