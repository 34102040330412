import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';
import { Panel, DefaultButton, Spinner, SpinnerSize, MessageBar, MessageBarType, PanelType, Label } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';
import sanitizeHtml from 'sanitize-html';

// Components
import TranslatedComponent from '../../../../localization/TranslatedComponent';
import styles from './DocumentEntryInfoSidePanel.module.css';

// Services
import DocumentAccessFactory from '../../../../services/documentAccess/DocumentAccessFactory.service';

// Dto
import IDocumentEntryInfoSidePanelProps from './dto/IDocumentEntryInfoSidePanelProps';
import DocumentEntryInfoSidePanelState from './dto/DocumentEntryInfoSidePanelState';

// Util
import formatDate from '../../../../util/FormatDate';

/**
 * Document entry info panel Component
 */
class DocumentEntryInfoSidePanel extends TranslatedComponent<IDocumentEntryInfoSidePanelProps, DocumentEntryInfoSidePanelState> {
    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<IDocumentEntryInfoSidePanelProps>) {
        super(props);

        this.state = new DocumentEntryInfoSidePanelState();
    }

    /**
     * Gets called if the component is updated
     * @param prevProps Previous props
     */
    public componentDidUpdate(prevProps: IDocumentEntryInfoSidePanelProps) {
        if (this.props.downloadEntryToShow !== null && prevProps.downloadEntryToShow === null) {
            this.setState({
                documentEntry: null,
                isLoading: true
            });

            DocumentAccessFactory.getDocumentAccessService().getDocumentEntryDetails(this.props.downloadEntryToShow.id).then((documentEntry) => {
                this.setState({
                    documentEntry: documentEntry,
                    isLoading: false
                });
            }, () => {
                this.setState({
                    isLoading: false,
                    errorOccured: true
                })
            });
        }
    }

    /**
     * Renders the administration component
     * @param t Translate function
     * @returns Rendered component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <Panel
                isOpen={this.props.downloadEntryToShow !== null}
                onDismiss={this.props.onClosePanel}
                closeButtonAriaLabel={t("general.close")}
                headerText={this.state.documentEntry ? this.state.documentEntry.title : ""}
                onRenderFooter={() => this.renderPanelFooter(t)}
                isFooterAtBottom={true}
                type={PanelType.medium}
                isLightDismiss={true}>
                {this.renderErrorOccured(t)}

                {this.state.isLoading && <div className={styles.loadingContainer}><Spinner size={SpinnerSize.large}></Spinner></div>}
                {!this.state.isLoading && this.renderDocumentEntryInfo(t)}
            </Panel>
        );
    }

    /**
     * Renders the document entry info
     * @param t Translate function
     * @returns Rendered info
     */
    private renderDocumentEntryInfo = (t: TFunction): JSX.Element | null => {
        if(!this.state.documentEntry) {
            return null;
        }

        return (
            <div>
                <div className={styles.infoBlock}>
                    <Label>{t("documentEntryInfoSidePanel.subTitle")}</Label>
                    {this.state.documentEntry.subTitle}
                </div>
                <div className={styles.infoBlock}>
                    <Label>{t("documentEntryInfoSidePanel.ean")}</Label>
                    {this.state.documentEntry.ean}
                </div>
                <div className={styles.infoBlock}>
                    <Label>{t("documentEntryInfoSidePanel.isbn")}</Label>
                    {this.state.documentEntry.isbn}
                </div>
                <div className={styles.infoBlock}>
                    <Label>{t("documentEntryInfoSidePanel.edition")}</Label>
                    {this.state.documentEntry.edition}
                </div>
                <div className={styles.infoBlock}>
                    <Label>{t("documentEntryInfoSidePanel.author")}</Label>
                    {this.state.documentEntry.author}
                </div>
                <div className={styles.infoBlock}>
                    <Label>{t("documentEntryInfoSidePanel.editor")}</Label>
                    {this.state.documentEntry.editor}
                </div>
                <div className={styles.infoBlock}>
                    <Label>{t("documentEntryInfoSidePanel.publisher")}</Label>
                    {this.state.documentEntry.publisher}
                </div>
                <div className={styles.infoBlock}>
                    <Label>{t("documentEntryInfoSidePanel.pageCount")}</Label>
                    {this.state.documentEntry.pageCount}
                </div>
                <div className={styles.infoBlock}>
                    <Label>{t("documentEntryInfoSidePanel.publicationDate")}</Label>
                    {formatDate(t, this.state.documentEntry.publicationDate)}
                </div>
                {this.state.documentEntry.tags && 
                    <div className={styles.infoBlock}>
                        <Label>{t("documentEntryInfoSidePanel.tags")}</Label>
                        {this.state.documentEntry.tags.split(";").join(", ")}
                    </div>}
                <div className={styles.infoBlock}>
                    <Label>{t("documentEntryInfoSidePanel.description")}</Label>
                    <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(this.state.documentEntry.description)}} />
                </div>
            </div>
        );
    }

    /**
     * Renders the panel footer
     * @param t Translate function
     */
    private renderPanelFooter(t: TFunction): JSX.Element | null {
        return (
            <div className={styles.footerContainer}>
                <DefaultButton disabled={this.state.isLoading} onClick={this.props.onClosePanel}>{t("general.close")}</DefaultButton>
            </div>
        );
    }

    /**
     * Renders a message that an error occured
     * @param t Translate function
     */
    private renderErrorOccured(t: TFunction): ReactNode {
        if (!this.state.errorOccured) {
            return null;
        }

        return (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.removeErrorBanner} dismissButtonAriaLabel={t("general.close")}>
                {t("general.errorOccured")}
            </MessageBar>
        )
    }
    
    /**
     * Removes the error banner
     */
    private removeErrorBanner = (): void => {
        this.setState({
            errorOccured: false
        })
    }
}

export default DocumentEntryInfoSidePanel; 