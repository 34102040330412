// Util
import isDevMode from './../../util/IsDevMode';

// Services
import ISubscriptionsService from './ISubscriptions.service';
import MockSubscriptionsService from './MockSubscriptions.service';
import WebSubscriptionsService from './WebSubscriptions.service';

/**
 * Factory class to provide subscriptions service
 */
export default class SubscriptionsFactoryService {
    /**
     * Returns the subscriptions service
     * @returns Subscriptions service
     */
    public static getSubscriptionsService(): ISubscriptionsService {
        if (isDevMode()) {
            return new MockSubscriptionsService();
        }

        return new WebSubscriptionsService();
    }
}