/**
 * Navbar state class
 */
class NavBarState {
    /**
     * true if the sign out error messages should be shown
     */
    public showSignOutErrorMessage: boolean;

    /**
     * Constructor
     */
    public constructor()
    {
        this.showSignOutErrorMessage = false;
    }
}

export default NavBarState;