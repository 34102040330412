import UserSubscription from '../../../../../dto/subscriptions/UserSubscription';

/**
 * User subscriptions panel state class
 */
class UserSubscriptionsPanelState {
    /**
     * User subscriptions
     */
    public userSubscriptions: UserSubscription[];

    /**
     * Subscription number to add
     */
    public subscriptionNumberToAdd: string;

    /**
     * Susbcriptions that must be deleted
     */
    public subscriptionToDelete: UserSubscription | null;

    /**
     * true if the error messages must be shown, else false
     */
    public showErrorMessage: boolean;

    /**
     * true if a load operation is in progress
     */
    public isLoading: boolean;

    /**
     * true if a save operation is in progress
     */
    public isSaving: boolean;

    /**
     * true if an error occured
     */
    public errorOccured: boolean;

    /**
     * Constructor
     */
    public constructor()
    {
        this.userSubscriptions = [];
        this.subscriptionNumberToAdd = "";
        this.subscriptionToDelete = null;
        this.showErrorMessage = false;
        this.isLoading = false;
        this.isSaving = false;
        this.errorOccured = false;
    }
}

export default UserSubscriptionsPanelState;