import UserQueryResult from './../../../dto/admin/UserQueryResult';
import UserEntry from '../../../dto/admin/UserEntry';

/**
 * Administration page state class
 */
class AdministrationState {
    /**
     * User query result
     */
    public userQueryResult: UserQueryResult;

    /**
     * true if the user edit create panel must be shown
     */
    public showUserEditCreatePanel: boolean;

    /**
     * The User which to edit
     */
    public userToEdit: UserEntry | null;
    
    /**
     * The User for which the products are managed
     */
    public userToManageProducts: UserEntry | null;

    /**
     * The User for which the subscriptions are managed
     */
    public userToManageSubscriptions: UserEntry | null;

    /**
     * True if the import panel must be shown
     */
    public showImportPanel: boolean;

    /**
     * True if the product import panel must be shown
     */
    public showProductImportPanel: boolean;
    
    /**
     * User that must be deleted
     */
    public userToDelete: UserEntry | null;

    /**
     * Current user page
     */
    public userPage: number;

    /**
     * User search query
     */
    public userSearchQuery: string;

    /**
     * true if user data is loaded
     */
    public loadingUserData: boolean;

    /**
     * true if an error occured while loading user data
     */
    public errorOccured: boolean;

    /**
     * Constructor
     */
    public constructor()
    {
        this.userQueryResult = new UserQueryResult();
        this.showUserEditCreatePanel = false;
        this.userToEdit = null;
        this.userToManageProducts = null;
        this.userToManageSubscriptions = null;
        this.showImportPanel = false;
        this.showProductImportPanel = false;
        this.userToDelete = null;
        this.userPage = 0;
        this.userSearchQuery = "";
        this.loadingUserData = false;
        this.errorOccured = false;
    }
}

export default AdministrationState;