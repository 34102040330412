/**
 * ConfirmMail page state class
 */
class ConfirmMailState {
    /**
     * true if the mail could be confirmed, else false
     */
    public couldConfirmMail: boolean;

    /**
     * Constructor
     */
    public constructor()
    {
        this.couldConfirmMail = false;
    }
}

export default ConfirmMailState;