/**
 * Helper function to fix enum values
 * @param value Value that is being mapped
 * @param enumType Enum Type
 * @returns Fixed enum value
 */
export default function fixEnum(value:any, enumType:any) {
    if(!value) {
        return value;
    }

    if(typeof(value) !== "string") {
        return value;
    }

    return enumType[(value.toString() as any)] as any;
}