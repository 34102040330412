import TrackedHttpClient from './../trackedHttpClient/TrackedHttpClient.service';
import UserQueryResult from '../../dto/admin/UserQueryResult';
import IAdminService from './IAdmin.service';
import UserCreateEditRequest from '../../dto/admin/UserCreateEditRequest';
import UserData from '../../dto/account/UserData';
import UserProduct from '../../dto/admin/UserProduct';
import AvailableUserProduct from '../../dto/admin/AvailableUserProduct';
import UserSubscription from '../../dto/subscriptions/UserSubscription';
import AddUserSubscriptionRequest from '../../dto/admin/AddUserSubscriptionRequest';

/**
 * Web admin services
 */
class WebAdminService implements IAdminService {
    /**
     * Returns the users
     * @param page Page to load
     * @param pageSize Pagesize to load
     * @param searchQuery Search query
     * @returns Userlist
     */
    public getUsers(page: number, pageSize: number, searchQuery: string): Promise<UserQueryResult> {
        return TrackedHttpClient.get<UserQueryResult>(`/api/admin/users?page=${page}&pageSize=${pageSize}&searchQuery=${encodeURIComponent(searchQuery)}`);
    }

    /**
     * Loads a user by id
     * @param userId Id of the user
     */
    public getUserById(userId: string): Promise<UserData> {
        return TrackedHttpClient.get<UserData>(`/api/admin/getUser?id=${userId}`);
    }
    
    /**
     * Creates a new user
     * @param createRequest Create request 
     */
    public createUser(createRequest: UserCreateEditRequest): Promise<void> {
        return TrackedHttpClient.post<void>(`/api/admin/createUser`, createRequest);
    }
    
    /**
     * Updates a user
     * @param userId Id of the user
     * @param editRequest Edit request 
     */
    public updateUser(userId: string, editRequest: UserCreateEditRequest): Promise<void> {
        return TrackedHttpClient.post<void>(`/api/admin/updateUser?id=${encodeURIComponent(userId)}`, editRequest);
    }

    /**
     * Deletes a user
     * @param userId User Id
     */
    public async deleteUser(userId: string): Promise<void> {
        return TrackedHttpClient.delete<void>(`/api/admin/deleteUser?id=${encodeURIComponent(userId)}`);
    }

    /**
     * Returns the available user products
     * @returns List of user products
     */
    public getAvailableUserProducts(): Promise<AvailableUserProduct[]> {
        return TrackedHttpClient.get<AvailableUserProduct[]>('/api/admin/getAvailableUserProducts');
    }

    /**
     * Returns the products of a user which were associated by an admin
     * @param userId Id of the user
     * @returns List of user products
     */
    public async getUserAdminProducts(userId: string): Promise<UserProduct[]> {
        let products: UserProduct[] = await TrackedHttpClient.get<UserProduct[]>(`/api/admin/getUserProducts?id=${userId}`);

        for(let curProduct of products)
        {
            curProduct.start = new Date(curProduct.start);
            curProduct.end = new Date(curProduct.end);
        }

        return products;
    }
    
    /**
     * Saves the user admin products
     * @param userId Id of the user
     * @param products Products to save
     */
    public saveUserAdminProducts(userId: string, products: UserProduct[]): Promise<void> {
        return TrackedHttpClient.post<void>(`/api/admin/saveUserProducts?id=${encodeURIComponent(userId)}`, products);
    }

    /**
     * Returns a list of user subscriptions
     * @param userId Id of the user
     * @returns List of user subscription
     */
    public getUserSubscriptions(userId: string): Promise<UserSubscription[]> {
        return TrackedHttpClient.get<UserSubscription[]>(`/api/admin/getUserSubscriptions?id=${encodeURIComponent(userId)}`);
    }
        
    /**
     * Adds a user subscription
     * @param userId User Id
     * @param subscriptionNumber Subscription number to add
     */
    public async addUserSubscription(subscriptionRequest: AddUserSubscriptionRequest): Promise<void> {
        return TrackedHttpClient.post<void>(`/api/admin/addUserSubscriptions`, subscriptionRequest);
    }

    /**
     * Deletes a user subscriptions
     * @param subscriptionId Id of the subscription
     */
    public deleteUserSubscription(subscriptionId: string): Promise<void> {
        return TrackedHttpClient.delete<void>(`/api/admin/deleteUserSubscriptions?id=${encodeURIComponent(subscriptionId)}`);
    }
}

export default WebAdminService;