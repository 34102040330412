/**
 * Document entry
 */
class DocumentDetailEntry {
    /**
     * Id of the entry
     */
    public id: string;

    /**
     * Title of the entry
     */
    public title: string;
    
    /**
     * Sub Title of the entry
     */
    public subTitle: string;

    /**
     * EAN
     */
    public ean: string;

    /**
     * ISBN
     */
    public isbn: string;

    /**
     * Edition
     */
    public edition: string;

    /**
     * Author
     */
    public author: string;

    /**
     * Editor
     */
    public editor: string;

    /**
     * Publisher
     */
    public publisher: string;

    /**
     * Description
     */
    public description: string;

    /**
     * Publication date
     */
    public publicationDate: Date;

    /**
     * Page count
     */
    public pageCount: number;

    /**
     * Tags
     */
    public tags: string;

    /**
     * Constructor
     */
    public constructor() {
        this.id = "";
        this.title = "";
        this.subTitle = "";
        this.description = "";
        this.ean = "";
        this.isbn = "";
        this.edition = "";
        this.author = "";
        this.editor = "";
        this.publisher = "";
        this.publicationDate = new Date();
        this.pageCount = 0;
        this.tags = "";
    }
}

export default DocumentDetailEntry;