import UserData from './../UserData';

/**
 * Logged in user check result
 */
class LoggedInUserCheckResult {
    /**
     * User
     */
    public user: UserData | null;

    /**
     * true if too many logins were detected
     */
    public tooManyLogins: boolean;

    /**
     * Constructor
     */
    public constructor() {
        this.user = null;
        this.tooManyLogins = false;
    }
}

export default LoggedInUserCheckResult;