import React from 'react';

// Library
import { ColorClassNames, ProgressIndicator } from 'office-ui-fabric-react';

// Components
import styles from './GlobalLoadingIndicator.module.css';

// Services
import EventBus from './../../../services/eventBus/EventBus.service';
import EventBusEvent from '../../../services/eventBus/EventBusEvent';

// Dto
import GlobalLoadingIndicatorState from './dto/GlobalLoadingIndicatorState';

/**
 * Global Loading Indicator Component
 */
class GlobalLoadingIndicator extends React.Component<{}, GlobalLoadingIndicatorState> {
    /**
     * Constructor
     * @param props Input props
     */
    constructor(props: Readonly<{}>) {
        super(props);

        this.state = new GlobalLoadingIndicatorState();
    }

    /**
     * Connects to loading events
     */
    public componentDidMount() {
        EventBus.getInstance().on(EventBusEvent.OnLoadStarted, this, this.incLoadingRefCount);
        EventBus.getInstance().on(EventBusEvent.OnLoadFinished, this, this.decLoadingRefCount);
    }

    /**
     * Disconnects from loading events
     */
    public componentWillUnmount() {
        EventBus.getInstance().off(EventBusEvent.OnLoadStarted, this.incLoadingRefCount);
        EventBus.getInstance().off(EventBusEvent.OnLoadFinished, this.decLoadingRefCount);
    }

    /**
     * Increments the loading ref count
     */
    private incLoadingRefCount = () => { this.changeLoadingRefCount(1); }

    /**
     * Decrements the loading ref count
     */
    private decLoadingRefCount = () => { this.changeLoadingRefCount(-1); }

    /**
     * Changes the loading ref count
     * @param refChange Ref count change
     */
    private changeLoadingRefCount = (refChange: number) => {
        let newRefCount = this.state.loadingRefCount + refChange;
        if(newRefCount < 0)
        {
            newRefCount = 0;
        }

        this.setState({
            loadingRefCount: newRefCount
        });
    }

    /**
     * Renders the loading bar
     */
    public render() {
        return (
            <div className={`${ColorClassNames.neutralLightBackground} ${styles.loadingBarContainer}`}>
                {this.state.loadingRefCount > 0 && 
                    <ProgressIndicator styles={{ itemProgress: { padding: '0' } }} />
                }
            </div>
        );
    }
}

export default GlobalLoadingIndicator; 