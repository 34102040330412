import UserCreateEditRequest from './../../../../../dto/admin/UserCreateEditRequest';
import UserData from '../../../../../dto/account/UserData';

/**
 * User Edit / Create panel state class
 */
class UserEditCreatePanelState {
    /**
     * Create / Edit Request
     */
    public createEditRequest: UserCreateEditRequest;

    /**
     * Existing user data
     */
    public existingUserData: UserData | null;

    /**
     * true if a load operation is in progress
     */
    public isLoading: boolean;

    /**
     * true if an error occured
     */
    public errorOccured: boolean;

    /**
     * Error message to show
     */
    public errorMessage: string;

    /**
     * Constructor
     */
    public constructor()
    {
        this.createEditRequest = new UserCreateEditRequest();
        this.existingUserData = null;
        this.isLoading = false;
        this.errorOccured = false;
        this.errorMessage = "";
    }
}

export default UserEditCreatePanelState;