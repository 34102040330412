import EventBus from './../services/eventBus/EventBus.service';
import EventBusEvent from '../services/eventBus/EventBusEvent';
import LogManagerService from '../services/logging/LogManager.service';

/**
 * Mocks a loading time
 * @returns Promise for the loading time
 */
function mockLoadingTime(): Promise<void> {
    return new Promise((resolve, reject) => {
        let start = (new Date()).getTime();
        EventBus.getInstance().dispatch(EventBusEvent.OnLoadStarted);
        setTimeout(() => {
            EventBus.getInstance().dispatch(EventBusEvent.OnLoadFinished);
            LogManagerService.getInstance().trackRequest("MOCK", "MOCK_URL", true, 200, "MOCK", (new Date()).getTime() - start);
            resolve();
        }, (Math.random() * 500) + 250);
    });
}

export default mockLoadingTime;