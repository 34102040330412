import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';

// Components
import TranslatedComponent from '../../localization/TranslatedComponent';
import styles from './TermsOfUse.module.css';
import { FontClassNames, ColorClassNames, FontWeights, Link } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';

/**
 * Terms of use Component
 */
class TermsOfUse extends TranslatedComponent<{}, {}> {

    /**
     * Renders the home component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <div className={styles.termsOfUseContainer}>
                <h1 className={`${FontClassNames.xLargePlus} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("termsOfUse.header")}</h1>
                <p>
                    { t("termsOfUse.headerLine1") }<br/>
                    { t("termsOfUse.headerLine2") }<br/>
                    { t("termsOfUse.headerLine3") }
                </p>
                <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("termsOfUse.headerParagraph1") }</h2>
                <p>
                    <b>1.1</b><br/>
                    { t("termsOfUse.1.1") }
                </p>
                <p>
                    <b>1.2</b><br/>
                    { t("termsOfUse.1.2") }
                </p>
                <p>
                    <b>1.3</b><br/>
                    { t("termsOfUse.1.3") }
                </p>
                <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("termsOfUse.headerParagraph2") }</h2>
                <p>
                    <b>2.1</b><br/>
                    { t("termsOfUse.2.1") }
                </p>
                <p>
                    <b>2.2</b><br/>
                    { t("termsOfUse.2.2") }
                    <ul className={styles.termsOfUseList}>
                        <li>{ t("termsOfUse.2.2_list1") }</li>
                        <li>{ t("termsOfUse.2.2_list2") }</li>
                        <li>{ t("termsOfUse.2.2_list3") }</li>
                        <li>{ t("termsOfUse.2.2_list4") }</li>
                        <li>{ t("termsOfUse.2.2_list5") }</li>
                        <li>{ t("termsOfUse.2.2_list6") }</li>
                    </ul>
                </p>
                <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("termsOfUse.headerParagraph3") }</h2>
                <p>
                    <b>3.1</b><br/>
                    { t("termsOfUse.3.1") }
                </p>
                <p>
                    <b>3.2</b><br/>
                    { t("termsOfUse.3.2") }
                </p>
                <p>
                    <b>3.3</b><br/>
                    { t("termsOfUse.3.3") }
                </p>
                <p>
                    <b>3.4</b><br/>
                    { t("termsOfUse.3.4") }
                </p>
                <p>
                    <b>3.5</b><br/>
                    { t("termsOfUse.3.5") }
                </p>
                <p>
                    <b>3.6</b><br/>
                    { t("termsOfUse.3.6") }
                </p>
                <p>
                    <b>3.7</b><br/>
                    { t("termsOfUse.3.7") }
                </p>
                <p>
                    <b>3.8</b><br/>
                    { t("termsOfUse.3.8") }
                </p>
                <p>
                    <b>3.9</b><br/>
                    { t("termsOfUse.3.9") }
                </p>
                <p>
                    <b>3.10</b><br/>
                    { t("termsOfUse.3.10") }
                </p>
                <p>
                    <b>3.11</b><br/>
                    { t("termsOfUse.3.11") }
                </p>
                <p>
                    <b>3.12</b><br/>
                    { t("termsOfUse.3.12") }
                </p>
                <p>
                    <b>3.13</b><br/>
                    { t("termsOfUse.3.13") }
                </p>
                <p>
                    <b>3.14</b><br/>
                    { t("termsOfUse.3.14") }
                </p>
                <p>
                    <b>3.15</b><br/>
                    { t("termsOfUse.3.15") }
                </p>
                <p>
                    <b>3.16</b><br/>
                    { t("termsOfUse.3.16") }
                </p>
                <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("termsOfUse.headerParagraph4") }</h2>
                <p>
                    <b>4.1</b><br/>
                    { t("termsOfUse.4.1") }
                </p>
                <p>
                    <b>4.2</b><br/>
                    { t("termsOfUse.4.2") }
                </p>
                <p>
                    <b>4.3</b><br/>
                    { t("termsOfUse.4.3") }
                </p>
                <p>
                    <b>4.4</b><br/>
                    { t("termsOfUse.4.4") }
                </p>
                <p>
                    <b>4.5</b><br/>
                    { t("termsOfUse.4.5") }
                </p>
                <p>
                    <b>4.6</b><br/>
                    { t("termsOfUse.4.6") }
                </p>
                <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("termsOfUse.headerParagraph5") }</h2>
                <p>
                    <b>5.1</b><br/>
                    { t("termsOfUse.5.1") }<br/>
                    { t("termsOfUse.5.1_line1") }<br/>
                    { t("termsOfUse.5.1_line2") }<br/>
                    { t("termsOfUse.5.1_line3") }
                </p>
                <p>
                    <b>5.2</b><br/>
                    { t("termsOfUse.5.2") }
                </p>
                <p>
                    <b>5.3</b><br/>
                    { t("termsOfUse.5.3") }
                </p>
                <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("termsOfUse.headerParagraph6") }</h2>
                <p>
                    <b>6.1</b><br/>
                    { t("termsOfUse.6.1") }
                </p>
                <p>
                    <b>6.2</b><br/>
                    { t("termsOfUse.6.2") }
                </p>
                <p>
                    <b>6.3</b><br/>
                    { t("termsOfUse.6.3") }
                </p>
                <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("termsOfUse.headerParagraph7") }</h2>
                <p>
                    <b>7.1</b><br/>
                    { t("termsOfUse.7.1") }
                </p>
                <p>
                    <b>7.2</b><br/>
                    { t("termsOfUse.7.2") }
                </p>
                <p>
                    <b>7.3</b><br/>
                    { t("termsOfUse.7.3") }
                </p>
                <p>
                    <b>7.4</b><br/>
                    { t("termsOfUse.7.4") }
                </p>
                <p>
                    <b>7.5</b><br/>
                    { t("termsOfUse.7.5") }
                </p>
                <p>
                    <b>7.6</b><br/>
                    { t("termsOfUse.7.6") }
                </p>
                <p>
                    <b>7.7</b><br/>
                    { t("termsOfUse.7.7") }
                </p>
                <p>
                    <b>7.8</b><br/>
                    { t("termsOfUse.7.8") }
                </p>
                <p>
                    <b>7.9</b><br/>
                    { t("termsOfUse.7.9") }
                </p>
                <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("termsOfUse.headerParagraph8") }</h2>
                <p>
                    <b>8.1</b><br/>
                    { t("termsOfUse.8.1") }
                </p>
                <p>
                    <b>8.2</b><br/>
                    { t("termsOfUse.8.2") }
                </p>
                <p>
                    <b>8.3</b><br/>
                    { t("termsOfUse.8.3") }<br/>
                    { t("termsOfUse.8.3_line1") }<br/>
                    { t("termsOfUse.8.3_line2") }<br/>
                    { t("termsOfUse.8.3_line3") }
                </p>
                <p>
                    <b>8.4</b><br/>
                    { t("termsOfUse.8.4") }
                </p>
                <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("termsOfUse.headerParagraph9") }</h2>
                <p>
                    <b>9.1</b><br/>
                    { t("termsOfUse.9.1") }
                </p>
                <p>
                    <b>9.2</b><br/>
                    { t("termsOfUse.9.2") }
                </p>
                <p>
                    <b>9.3</b><br/>
                    { t("termsOfUse.9.3") }
                </p>
                <p>
                    <b>9.4</b><br/>
                    { t("termsOfUse.9.4") }
                    <ul>
                        <li>{ t("termsOfUse.9.4_line1") }</li>
                        <li>{ t("termsOfUse.9.4_line2") }</li>
                        <li>{ t("termsOfUse.9.4_line3") }</li>
                        <li>{ t("termsOfUse.9.4_line4") }</li>
                    </ul>
                </p>
                <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("termsOfUse.headerParagraph10") }</h2>
                <p>
                    <b>10.1</b><br/>
                    { t("termsOfUse.10.1") }
                </p>
                <p>
                    <b>10.2</b><br/>
                    { t("termsOfUse.10.2") }
                </p>
                <p>
                    <b>10.3</b><br/>
                    { t("termsOfUse.10.3") }
                </p>
                <p>
                    { t("termsOfUse.Subline_1") }<br/>
                    { t("termsOfUse.Subline_2") }
                </p>
                <p>
                    { t("termsOfUse.Subline_3") }<br/><br/>
                    <Link href="/static/Allgemeine_Nutzungsbedingungen_ePaper_eBooks_06_2019.pdf">{ t("termsOfUse.DownloadLink") }</Link>
                </p>
            </div>
        );
    }
}

export default TermsOfUse; 