/**
 * Uncrypts a mail to message
 * @param s Mail To string
 */
export default function UncryptMailTo(s: string): void {
    var n = 0;
    var r = "";
    for (var i = 0; i < s.length; i++) {
        n = s.charCodeAt(i);
        if (n >= 8364) 
        { 
            n = 128; 
        }
        r += String.fromCharCode(n - (1));
    }

    window.location.href = r;
}