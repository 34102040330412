import { ApplicationInsights } from '@microsoft/applicationinsights-web'

import ILogger from "./ILogger";

/**
 * Class for application insights
 */
class AppInsightsLogger implements ILogger {

    /**
     * Application insights
     */
    private appInsights: ApplicationInsights | null = null;

    /**
     * Current page
     */
    private currentPage: string = "";

    /**
     * Initialized the logger
     */
    public init(): void {
        if(this.appInsights) {
            return;
        }

        let configObject: any = (window as any)["VulkanVerlag"];
        if(configObject && configObject.Config && configObject.Config.InstrumentationKey)
        {
            this.appInsights = new ApplicationInsights({ config : {
                instrumentationKey: configObject.Config.InstrumentationKey,
                disableAjaxTracking: true
            }});
        }
    }

    /**
     * Starts tracking a page
     * @param pageName Page name
     */
    public startTrackPage(pageName: string): void {
        if(this.currentPage === pageName) {
            return;
        }

        if(this.appInsights) {
            if(this.currentPage) {
                this.appInsights.stopTrackPage(this.currentPage);
            }

            this.appInsights.startTrackPage(pageName);
            this.currentPage = pageName;
        }
    }

    /**
     * Tracks a request
     * @param trackId Track id
     * @param url Url that was called
     * @param success true if the request was a success, else false
     * @param responseCode Response code
     * @param method Method used
     * @param duration Duration the call took
     */
    public trackRequest(trackId: string, url: string, success: boolean, responseCode: number, method: string, duration: number): void {
        if(!this.appInsights) {
            return;
        }

        if(!url.startsWith("https://") || !url.startsWith("http://")) {
            if(!url.startsWith("/")) {
                url = "/" + url;
            }
            url = window.location.origin + url;
        }
        
        this.appInsights.trackDependencyData({
            id: trackId,
            target: url,
            success: success,
            responseCode: responseCode,
            type: method,
            duration: duration
        });
    }
}

export default AppInsightsLogger;