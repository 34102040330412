/**
 * User data
 */
class UserData {
    /**
     * Username
     */
    public username: string;

    /**
     * Email
     */
    public email: string;

    /**
     * Password
     */
    public password: string;

    /**
     * Firstname
     */
    public salutation: string;

    /**
     * Firstname
     */
    public firstname: string;

    /**
     * Lastname
     */
    public lastname: string;
    
    /**
     * Company name
     */
    public companyName: string;
    
    /**
     * Company street
     */
    public companyStreet: string;
    
    /**
     * Company postal code
     */
    public companyPostalCode: string;
    
    /**
     * Company city
     */
    public companyCity: string;

    /**
     * Company country
     */
    public companyCountry: string;

    /**
     * Max session count
     */
    public maxSessionCount: number | null;

    /**
     * IP Address
     */
    public ipAddress: string;

    /**
     * Constructor
     */
    public constructor() {
        this.username = "";
        this.email = "";
        this.password = "";
        this.salutation = "";
        this.firstname = "";
        this.lastname = "";
        this.companyName = "";
        this.companyStreet = "";
        this.companyPostalCode = "";
        this.companyCity = "";
        this.companyCountry = "";
        this.maxSessionCount = null;
        this.ipAddress = "";
    }

    /**
     * Assigns the user data from another user data object
     * @param userData User data to copy
     */
    public assignFromUserData(userData: UserData) {
        this.username = userData.username;
        this.email = userData.email;
        this.password = userData.password;
        this.salutation = userData.salutation;
        this.firstname = userData.firstname;
        this.lastname = userData.lastname;
        this.companyName = userData.companyName;
        this.companyStreet = userData.companyStreet;
        this.companyPostalCode = userData.companyPostalCode;
        this.companyCity = userData.companyCity;
        this.companyCountry = userData.companyCountry;
        this.maxSessionCount = userData.maxSessionCount;
        this.ipAddress = userData.ipAddress;
    }
}

export default UserData;