import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';
import { ColorClassNames, FontWeights, FontClassNames } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';
import { Link } from 'react-router-dom';

// Components
import TranslatedComponent from '../../localization/TranslatedComponent';
import styles from './ConfirmMail.module.css';

// Services

// Dto
import IConfirmMailProps from './dto/IConfirmMailProps';
import ConfirmMailState from './dto/ConfirmMailState';

/**
 * Confirm Mail Component
 */
class ConfirmMail extends TranslatedComponent<IConfirmMailProps, ConfirmMailState> {
    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<IConfirmMailProps>) {
        super(props);

        let state = new ConfirmMailState();

        let configObject: any = (window as any)["VulkanVerlag"];
        if (configObject && configObject.Config && configObject.Config.AdditionalConfig && configObject.Config.AdditionalConfig.CouldConfirmMail) {
            state.couldConfirmMail = true;
        }

        this.state = state;
    }

    /**
     * Renders the confirm mail component
     * @param t Translate function
     * @returns Rendered component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <div className={`${styles.confirmMailContainer} ${ColorClassNames.whiteBackground}`}>
                <h1 className={`${FontClassNames.xLargePlus} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("confirmMail.header")}</h1>
                {this.state.couldConfirmMail ? this.renderSuccessMessage(t) : this.renderFailMessage(t)}
            </div>
        );
    }

    /**
     * Renders a success message
     * @param t Translate function
     * @returns Rendered message
     */
    private renderSuccessMessage = (t: TFunction): ReactNode => {
        return (
            <div>
                <p>{t("confirmMail.successMessage")}</p>
                <Link className={`${ColorClassNames.themePrimary} ${styles.redirectLink}`} to="/login">{t("confirmMail.loginMessage")}</Link>
            </div>
        );
    }

    /**
     * Renders a fail message
     * @param t Translate function
     * @returns Rendered message
     */
    private renderFailMessage = (t: TFunction): ReactNode => {
        return (
            <div>
                <p>{t("confirmMail.failMessage")}</p>
                <Link className={`${ColorClassNames.themePrimary} ${styles.redirectLink}`} to="/register">{t("confirmMail.registerMessage")}</Link>
            </div>
        );
    }
}

export default ConfirmMail; 