import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';
import { ColorClassNames, TextField, PrimaryButton, Spinner, SpinnerSize, FontClassNames, FontWeights } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';

// Components
import TranslatedComponent from '../../localization/TranslatedComponent';
import styles from './PasswordReset.module.css';

// Services
import AccountFactory from '../../services/account/AccountFactory.service';

// Dto
import IPasswordResetProps from './dto/IPasswordResetProps';
import PasswordResetState from './dto/PasswordResetState';
import ResetPasswordRequest from '../../dto/account/ResetPasswordRequest';

// Util
import isValidEmail from './../../util/IsValidEmail';

/**
 * Password reset Component
 */
class PasswordReset extends TranslatedComponent<IPasswordResetProps, PasswordResetState> {
    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<IPasswordResetProps>) {
        super(props);

        this.state = new PasswordResetState();
    }

    /**
     * Renders the password reset component
     * @param t Translate function
     * @returns Rendered component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <div className={`${styles.passwordResetContainer} ${ColorClassNames.whiteBackground}`}>
                <h1 className={`${FontClassNames.xLargePlus} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("passwordReset.header")}</h1>
                {!this.state.requestWasSent ? this.renderResetPasswordForm(t) : this.renderSuccessMessage(t)}
            </div>
        );
    }
    
    /**
     * Renders the reset password form
     * @param t Translate function
     */
    private renderResetPasswordForm = (t: TFunction): ReactNode => {
        return (
            <div>
                <p>{t("passwordReset.enterEmailToReceiveResetLink")}</p>
                <TextField onChange={this.onEmailAddressChanged} errorMessage={this.getEmailErrorMessage(t)} required={true} value={this.state.resetRequest.email} label={t("passwordReset.emailAddress")}></TextField>
                <p>
                    <PrimaryButton disabled={this.state.isLoading} onClick={this.onResetPassword}>
                        {this.state.isLoading && <Spinner className={styles.resetButtonSpinner} size={SpinnerSize.xSmall}></Spinner>}
                        {t("passwordReset.resetPassword")}
                    </PrimaryButton>
                </p>
            </div>
        );
    }

    /**
     * Renders the success message form
     * @param t Translate function
     */
    private renderSuccessMessage = (t: TFunction): ReactNode => {
        return (
            <div>
                <p>{t("passwordReset.successMessage")}</p>
            </div>
        );
    }

    /**
     * Callback if the email address was changed
     * @param _ev Event object
     * @param newValue New value of the textbox
     */
    private onEmailAddressChanged = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
        if(!newValue)
        {
            newValue = "";
        }

        let resetRequest: ResetPasswordRequest = { ...this.state.resetRequest };
        resetRequest.email = newValue;

        this.setState({
            resetRequest: resetRequest
        });
    }

    
    /**
     * Returns an error message if an invalid email address is provided
     * @param t Translate function
     * @returns Error message
     */
    private getEmailErrorMessage = (t: TFunction): string => {
        if (!this.state.showErrorMessage)
        {
            return "";
        }

        if(!this.state.resetRequest.email)
        {
            return t("general.mandatoryField");
        }

        return !isValidEmail(this.state.resetRequest.email) ? t("general.invalidEmail") : "";
    }


    /**
     * Gets called if the password must be reset
     */
    private onResetPassword = async (): Promise<void> => {
        if(!this.state.resetRequest.email || !isValidEmail(this.state.resetRequest.email))
        {
            this.setState({
                showErrorMessage: true,
                isLoading: false
            });
            return;
        }
        
        this.setState({
            showErrorMessage: false,
            isLoading: true
        });

        try {
            await AccountFactory.getAccountService().resetPassword(this.state.resetRequest);
        }
        catch (e) {
            // Dont show error to not help potential hackers
        }

        this.setState({
            isLoading: false,
            requestWasSent: true
        });
    }
}

export default PasswordReset; 