import MyDownloadsFilter from '../../../../../dto/myDownloadsFilter/MyDownloadsFilter';
import MyDownloadsFilterEntry from '../../../../../dto/myDownloads/MyDownloadsFilterEntry';
import AvailableFilterFormat from './AvailableFilterFormat';

/**
 * Filter panel state
 */
class FilterPanelState {
    /**
     * Constructed filter
     */
    public filter: MyDownloadsFilter;

    /**
     * true if the year filter should be shown, else false
     */
    public showYearFilter: boolean;

    /**
     * Minimum year
     */
    public minYear: number;

    /**
     * Maximum year
     */
    public maxYear: number;

    /**
     * Available formats to filter
     */
    public availableFormats: AvailableFilterFormat[];

    /**
     * Available filter entries
     */
    public filterEntries: MyDownloadsFilterEntry[];

    /**
     * true if the filter config is being loaded
     */
    public isLoading: boolean;

    /**
     * true if an error occured during loading, else false
     */
    public errorOccured: boolean;

    /**
     * Constructor
     */
    public constructor() {
        this.filter = new MyDownloadsFilter();
        this.showYearFilter = false;
        this.minYear = 0;
        this.maxYear = 0;
        this.availableFormats = [];
        this.filterEntries = [];
        this.isLoading = false;
        this.errorOccured = false;
    }
}

export default FilterPanelState;