// Util
import isDevMode from './../../util/IsDevMode';

// Services
import IAdminService from './IAdmin.service';
import MockAdminService from './MockAdmin.service';
import WebAdminService from './WebAdmin.service';

/**
 * Factory class to provide admin services
 */
export default class AdminFactoryService {
    /**
     * Returns the admin service
     * @returns Admin service
     */
    public static getAdminService(): IAdminService {
        if (isDevMode()) {
            return new MockAdminService();
        }

        return new WebAdminService();
    }
}