import TrackedHttpClient from './../trackedHttpClient/TrackedHttpClient.service';
import DocumentEntry from "../../dto/documentAccess/DocumentEntry";
import DocumentDetailEntry from "../../dto/documentAccess/DocumentDetailEntry";
import IDocumentAccessService from './IDocumentAccess.service';

/**
 * Web document access services
 */
class WebDocumentService implements IDocumentAccessService {
    /**
     * Returns the latest document entry
     * @returns Latest document entry
     */
    public async getLatestDocumentEntry(): Promise<DocumentEntry>  {
        let documentEntry: DocumentEntry = await TrackedHttpClient.get<DocumentEntry>("/api/document/latestDocument");
        documentEntry.publicationDate = new Date(documentEntry.publicationDate);
        return documentEntry;
    }
    
    /**
     * Returns the document entry details for a document entry
     * @param id Id of the document entry
     * @returns Details of the document entry
     */
    public async getDocumentEntryDetails(id: string): Promise<DocumentDetailEntry> {
        let documentEntry: DocumentDetailEntry = await TrackedHttpClient.get<DocumentDetailEntry>(`/api/document/getDetailEntry?id=${id}`);
        documentEntry.publicationDate = new Date(documentEntry.publicationDate);
        return documentEntry;
    }
}

export default WebDocumentService;