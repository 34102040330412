/**
 * Data of the logged in user
 */
class UserData {
    /**
     * true if the user is authenticated, else false
     */
    public isAuthenticated: boolean;

    /**
     * true if the user is authenticated using IP, else false
     */
    public isIpAuthenticated: boolean;

    /**
     * true if the user MySite
     */
    public isAdmin: boolean;

    /**
     * Displayname of the user
     */
    public displayName: string;

    /**
     * Constructor
     */
    public constructor() {
        this.isAuthenticated = false;
        this.isIpAuthenticated = false;
        this.displayName = "";
        this.isAdmin = false;
    }
}

export default UserData;