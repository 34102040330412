import MyDownloadsFilterEntryType from './MyDownloadsFilterEntryType';

/**
 * Entry for my downloads
 */
class MyDownloadsEntry {
    /**
     * Id of the entry
     */
    public id: string;

    /**
     * Title of the entry
     */
    public title: string;

    /**
     * Edition
     */
    public edition: string;

    /**
     * Publication year
     */
    public publicationYear: number;

    /**
     * Page count
     */
    public pageCount: number;

    /**
     * Tags
     */
    public tags: string[];

    /**
     * Download image url
     */
    public imageUrl: string;

    /**
     * Filename
     */
    public filename: string;

    /**
     * True if the entry is favorited, else false
     */
    public isFavorited: boolean;

    /**
     * Type of the entry
     */
    public entryType: MyDownloadsFilterEntryType;

    /**
     * Search hit
     */
    public searchHit: string;

    /**
     * Constructor
     */
    public constructor() {
        this.id = "";
        this.title = "";
        this.edition = "";
        this.publicationYear = 0;
        this.pageCount = 0;
        this.tags = [];
        this.imageUrl = "";
        this.filename = "";
        this.isFavorited = false;
        this.entryType = MyDownloadsFilterEntryType.Magazin;
        this.searchHit = "";
    }
}

export default MyDownloadsEntry;