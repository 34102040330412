import SubscriptionConnectRequest from './../../../dto/subscriptions/SubscriptionConnectRequest';
import UserSubscription from '../../../dto/subscriptions/UserSubscription';

/**
 * My subscriptions page state class
 */
class MySubscriptionsState {
    /**
     * Subscription connect request
     */
    public subscriptionConnectRequest: SubscriptionConnectRequest;

    /**
     * Subscriptions of the current user
     */
    public currentUserSubscriptions: UserSubscription[];

    /**
     * true if the subscriptions are loaded
     */
    public isLoading: boolean;

    /**
     * true if an error occured while loading the subscriptions
     */
    public loadErrorOccured: boolean;

    /**
     * true if error messages should be shown on empty
     */
    public showErrorMessage: boolean;

    /**
     * true if a save operation is running
     */
    public isSaving: boolean;

    /**
     * true if a subscription was connected
     */
    public subscriptionWasConnected: boolean;

    /**
     * true if an error occured which caused a subscription to not be able to be connected
     */
    public subscriptionConnectErrorOccured: boolean;

    /**
     * true if the subscription number is already in use, else false
     */
    public subscriptionNumberInUse: boolean;

    /**
     * Constructor
     */
    public constructor()
    {
        this.subscriptionConnectRequest = new SubscriptionConnectRequest();
        this.currentUserSubscriptions = [];
        this.isLoading = false;
        this.loadErrorOccured = false;
        this.showErrorMessage = false;
        this.isSaving = false;
        this.subscriptionWasConnected = false;
        this.subscriptionConnectErrorOccured = false;
        this.subscriptionNumberInUse = false;
    }
}

export default MySubscriptionsState;