import mockLoadingTime from './../../util/MockLoadingTime';
import RegisterRequest from '../../dto/account/RegisterRequest';
import ResetPasswordRequest from '../../dto/account/ResetPasswordRequest';
import SetNewPasswordRequest from '../../dto/account/SetNewPasswordRequest';
import ChangePasswordRequest from '../../dto/account/ChangePasswordRequest';
import UpdateUserDataRequest from '../../dto/account/UpdateUserDataRequest';
import CurrentUserDataResponse from '../../dto/account/CurrentUserDataResponse';
import IAccountService from './IAccount.service';

/**
 * Mock account services
 */
class MockAccountService implements IAccountService {
    /**
     * Registers a new user
     * @param registerRequest Register request
     * @returns Promise
     */
    public async register(registerRequest: RegisterRequest): Promise<void> {
        await mockLoadingTime();
    }
    
    /**
     * Resets the password for a user
     * @param resetRequest Reset request
     * @returns Promise
     */
    public async resetPassword(resetRequest: ResetPasswordRequest): Promise<void> {
        await mockLoadingTime();
    }
        
    /**
     * Sets the new password for a user
     * @param setNewPasswordRequest New password request
     * @returns Promise
     */
    public async setNewPassword(setNewPasswordRequest: SetNewPasswordRequest): Promise<void> {
        await mockLoadingTime();
    }

    /**
     * Changes the password for a user
     * @param changePasswordRequest Change password request
     * @returns Promise
     */
    public async changePassword(changePasswordRequest: ChangePasswordRequest): Promise<void> {
        await mockLoadingTime();
    }

    /**
     * Returns the current user data
     * @returns Current user data
     */
    public async getCurrentUserData(): Promise<CurrentUserDataResponse> {
        await mockLoadingTime();

        let userResponse: CurrentUserDataResponse = new CurrentUserDataResponse();
        userResponse.email = "maria.mock@mockingbird.local";
        userResponse.salutation = "Frau";
        userResponse.firstname = "Maria";
        userResponse.lastname = "Mock";

        userResponse.companyName = "Mocking Mariachis";
        userResponse.companyStreet = "Mock Street 13";
        userResponse.companyCity = "Mexico City";
        userResponse.companyPostalCode = "16999";
        userResponse.companyCountry = "Mexiko";

        return userResponse;
    }

    /**
     * Updates the user data
     * @param updateUserDataRequest Update user data request
     * @returns Promise
     */
    public async updateUserData(updateUserDataRequest: UpdateUserDataRequest): Promise<void> {
        await mockLoadingTime();
    }
}

export default MockAccountService;