import UserData from "../../../../dto/account/UserData";

/**
 * User Data form state class
 */
class UserDataFormState {
    /**
     * User data
     */
    public userData: UserData;

    /**
     * Password repeat data
     */
    public repeatPassword: string;

    /**
     * true if error messages should be shown on empty
     */
    public showErrorMessage: boolean;

    /**
     * Constructor
     */
    public constructor() {
        this.userData = new UserData();
        this.repeatPassword = "";
        this.showErrorMessage = false;
    }
}

export default UserDataFormState;