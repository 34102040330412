import ResetPasswordRequest from "../../../dto/account/ResetPasswordRequest";

/**
 * Password reset page state class
 */
class PasswordResetState {
    /**
     * Password reset request
     */
    public resetRequest: ResetPasswordRequest;

    /**
     * True if the reset request was sent
     */
    public requestWasSent: boolean;

    /**
     * true if error messages should be shown on empty
     */
    public showErrorMessage: boolean;

    /**
     * true if the page is loading
     */
    public isLoading: boolean;

    /**
     * Constructor
     */
    public constructor()
    {
        this.resetRequest = new ResetPasswordRequest();
        this.requestWasSent = false;
        this.showErrorMessage = false;
        this.isLoading = false;
    }
}

export default PasswordResetState;