/**
 * User import state class
 */
class UserImportLogEntry {
    /**
     * Id of the log entry
     */
    public id: number;

    /**
     * User mail that was affected
     */
    public userMail: string;

    /**
     * true if the user was created, false if it was an update
     */
    public wasCreate: boolean;

    /**
     * true if an error occured while importing the user
     */
    public errorOccured: boolean;

    /**
     * Error message
     */
    public errorMessage: string;

    /**
     * Time the log was created
     */
    public createTime: Date;
    
    /**
     * Constructor
     * @param id Id of the entry
     * @param userMail Mail of the user to import
     * @param wasCreate true if the user was created, false if it was an update
     * @param errorOccured true if an error occured while importing the user
     * @param errorMessage Error message
     */
    public constructor(id: number, userMail: string, wasCreate: boolean, errorOccured: boolean, errorMessage: string)
    {
        this.id = id;
        this.userMail = userMail;
        this.wasCreate = wasCreate;
        this.errorOccured = errorOccured;
        this.errorMessage = errorMessage;
        this.createTime = new Date();
    }
}

export default UserImportLogEntry;