/**
 * Change password request
 */
class ChangePasswordRequest {
    /**
     * Old password
     */
    public oldPassword: string;

    /**
     * New Password
     */
    public newPassword: string;

    /**
     * Constructor
     */
    public constructor() {
        this.oldPassword = "";
        this.newPassword = "";
    }
}

export default ChangePasswordRequest;