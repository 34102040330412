import React, { ReactNode } from 'react';

// Components
import styles from './Header.module.css';
import TranslatedComponent from '../../../localization/TranslatedComponent';
import { TFunction } from 'i18next';

/**
 * Header Component
 */
class Header extends TranslatedComponent<{}, {}> {
    /**
     * Renders the Header
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <div className={styles.headerContainer}>
                <div className={styles.redhead}></div>
                <img alt={t("header.scifoLogo")} src="/img/Scifo_Logo_2019.svg"></img>
            </div>
        );
    }   
}

export default Header; 