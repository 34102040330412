/**
 * Request to add a user subscription
 */
class AddUserSubscriptionRequest {
    /**
     * User id for which to add the subscription
     */
    public userId: string;

    /**
     * Subscription number
     */
    public subscriptionNumber: string;

    /**
     * Constructor
     * @param userId User id for which to add the subscription
     * @param subscriptionNumber Subscription number
     */
    public constructor(userId: string, subscriptionNumber: string) {
        this.userId = userId;
        this.subscriptionNumber = subscriptionNumber;
    }
}

export default AddUserSubscriptionRequest;