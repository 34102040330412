import MyDownloadsFilterEntryType from './MyDownloadsFilterEntryType';

/**
 * Config for filters
 */
class MyDownloadsFilterEntry {
    /**
     * Product number for the filter
     */
    public productNumber: string;

    /**
     * Product name for the filter
     */
    public productName: string;

    /**
     * Type of the entry
     */
    public entryType: MyDownloadsFilterEntryType;

    /**
     * Constructor
     */
    public constructor() {
        this.productNumber = "";
        this.productName = "";
        this.entryType = MyDownloadsFilterEntryType.Magazin;
    }
}

export default MyDownloadsFilterEntry;