/**
 * Returns the value of an url parameter
 * @param parameterName Name of the parameter to read
 * @returns Parameter value
 */
function getUrlParameter(parameterName: string) {
    let url = new URL(window.location.href);
    let result = url.searchParams.get(parameterName)
    if (result) {
        return result;
    } else {
        return "";
    }
}

export default getUrlParameter;