// Util
import isDevMode from './../../util/IsDevMode';

// Services
import IDocumentAccessService from './IDocumentAccess.service';
import MockDocumentAccessService from './MockDocumentAccess.service';
import WebDocumentService from './WebDocumentAccess.service';

/**
 * Factory class to provide document access services
 */
export default class DocumentAccessFactory {
    /**
     * Returns the document access service
     * @returns Document access service
     */
    public static getDocumentAccessService(): IDocumentAccessService {
        if (isDevMode()) {
            return new MockDocumentAccessService();
        }

        return new WebDocumentService();
    }
}