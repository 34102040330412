import React, { ReactNode } from 'react';

// Library
import { Icon, IIconStyles, Image, Stack, getTheme, IStackTokens, Text, ITextStyles, FontClassNames, FontWeights, ColorClassNames, MessageBar, MessageBarType, FontSizes, IconButton, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { Card, ICardTokens, ICardSectionStyles, ICardSectionTokens } from '@uifabric/react-cards';

import { TFunction } from 'i18next';
import { Redirect } from 'react-router-dom';

// Components
import TranslatedComponent from '../../localization/TranslatedComponent';
import FilterPanel from './components/filterPanel/FilterPanel';
import DocumentEntryInfoSidePanel from './components/documentEntryInfoSidePanel/DocumentEntryInfoSidePanel';
import styles from './MyDownloads.module.css';

// Services
import MyDownloadsFactoryService from '../../services/myDownloads/MyDownloadsFactory.service';

// Dto
import IMyDownloadsProps from './dto/IMyDownloadsProps';
import MyDownloadsState from './dto/MyDownloadsState';
import MyDownloadsEntry from '../../dto/myDownloads/MyDownloadsEntry';
import MyDownloadsFilter from '../../dto/myDownloadsFilter/MyDownloadsFilter';
import MyDownloadsFilterEntryType from '../../dto/myDownloads/MyDownloadsFilterEntryType';

/**
 * My Downloads Component
 */
class MyDownloads extends TranslatedComponent<IMyDownloadsProps, MyDownloadsState> {
    /**
     * Book prefix
     */
    private readonly _bookPrefix = "b_";

    /**
     * Theme
     */
    private readonly _theme = getTheme();

    /**
     * Page size of the downloads to load
     */
    private readonly pageSize: number = 6;

    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<IMyDownloadsProps>) {
        super(props);

        let state = new MyDownloadsState();
        this.getProductNumberFromUrl(state);
        this.state = state;
    }

    /**
     * Returns the product number from the url
     */
    private getProductNumberFromUrl(state: MyDownloadsState): void {
        if(!this.props.fromUrlPreview) {
            return;
        }

        let parsedUrl = window.location.pathname.split("/").filter(c => c !== "");
        if(parsedUrl.length !== 1) {
            state.invalidProductNumberUrl = true;
            return;
        }

        let productNumberPreview = parsedUrl[0];
        if(productNumberPreview.length <= 4 || isNaN(parseInt(productNumberPreview))) {
            state.invalidProductNumberUrl = true;
            return;
        }

        state.urlProductNumberPreview = this._bookPrefix + productNumberPreview;
    }

    /**
     * Gets called if the component gets mounted
     */
    public componentDidMount() {
        if(!this.state.urlProductNumberPreview) {
            return;
        }

        MyDownloadsFactoryService.getMyDownloadsService().getDownloadByProductNumber(this.state.urlProductNumberPreview).then((productData) => {
            this.setState({
                urlProductNumberPreview: ""
            }, () => {
                this.onShowEntryInline(productData);
            });
        }, () => {
            this.setState({
                errorLoadingProductByUrl: true
            })
        });
    }

    /**
     * Renders the my downloads component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <div className={styles.myDownloadsContainer}>
                {this.renderAnonymouseUserRedirect()}
                {this.renderNotFoundRedirectOnInvalidUrl()}
                {this.renderErrorOccured(t)}
                {this.props.currentUser && this.props.currentUser.isAuthenticated &&
                    <div dir="ltr" className="ms-Grid">
                        {this.state.viewFile === "" && this.state.viewVideo === "" && this.state.urlProductNumberPreview === "" ?
                            this.renderMainContent(t) : null
                        }
                        {this.state.viewFile !== "" ?
                            <div className="ms-Grid-row" style={{ paddingRight: "70px" }}>
                                <div className={`ms-Grid-col ms-lg12 ms-xl12 ${this.state.viewFileZoom ? styles.zoomFile : ""}`} style={{ paddingTop: "30px", textAlign: "right" }}>
                                    {this.state.showViewLoadingSpinner && <div className={styles.spinnerContainer}>
                                        <Spinner size={SpinnerSize.large}></Spinner>
                                    </div>}
                                    <Icon title="Zoom" iconName="ZoomToFit" style={{ fontSize: FontSizes.xLargePlus, fontWeight: 100, cursor: "pointer" }} onClick={this.fileZoom} />
                                    <Icon iconName="Cancel" style={{ fontSize: FontSizes.xLargePlus, fontWeight: 100, cursor: "pointer" }} onClick={() => { this.setState({ "viewFile": "", "viewFileZoom": false }) }} />
                                    <embed onLoad={this.onHideViewLoadingSpinner} src={`/api/myDownloads/download?fileId=${this.state.viewFile}&showInline=1`} type="application/pdf" width="100%" style={{ height: "100vh", marginTop: "30px" }} />
                                </div>
                            </div> : null}
                        {this.state.viewVideo !== "" ?
                            <div className="ms-Grid-row" style={{ paddingRight: "70px" }}>
                                <div className={`ms-Grid-col ms-lg12 ms-xl12 ${this.state.viewFileZoom ? styles.zoomFile : ""}`} style={{ paddingTop: "30px", textAlign: "right" }}>
                                    {this.state.showViewLoadingSpinner && <div className={styles.spinnerContainer}>
                                        <Spinner size={SpinnerSize.large}></Spinner>
                                    </div>}
                                    <Icon title="Zoom" iconName="ZoomToFit" style={{ fontSize: FontSizes.xLargePlus, fontWeight: 100, cursor: "pointer" }} onClick={this.fileZoom} />
                                    <Icon iconName="Cancel" style={{ fontSize: FontSizes.xLargePlus, fontWeight: 100, cursor: "pointer" }} onClick={() => { this.setState({ "viewVideo": "", "viewFileZoom": false }) }} />
                                    <br />
                                    <p style={{ textAlign: "center" }}>
                                        <video onLoadStart={this.onHideViewLoadingSpinner} style={{ height: "320px", width: "640px", marginTop: "30px" }} controls>
                                            <source src={`/api/myDownloads/download?fileId=${this.state.viewVideo}&showInline=1`} type="video/mp4" />
                                            {t("general.noVideoSupport")}
                                        </video>
                                    </p>
                                </div>
                            </div>
                            : null}
                        {this.state.urlProductNumberPreview !== "" &&
                            <div className={styles.urlPreviewLoadingIndicatorContainer}>
                                {!this.state.errorLoadingProductByUrl && <Spinner size={SpinnerSize.large}></Spinner>}
                                {this.state.errorLoadingProductByUrl && 
                                    <div>
                                        <Icon iconName="ErrorBadge" style={{ fontSize: FontSizes.xxLargePlus }} className={ColorClassNames.red}></Icon><br/>
                                        {t("myDownloads.noAccessToFileOrNotFound")}
                                    </div>}
                            </div>
                        }
                    </div>
                }

                {this.state.viewFile === "" && this.state.viewVideo === "" && this.state.urlProductNumberPreview === "" && this.renderFilterPanel()}

                <DocumentEntryInfoSidePanel downloadEntryToShow={this.state.detailDownloadEntry} onClosePanel={this.onCloseDocumentEntryInfoPanel}></DocumentEntryInfoSidePanel>
            </div>
        );
    }

    /**
     * Renders the main content
     * @param t Translate function
     * @returns Rendered component
     */
    private renderMainContent(t: TFunction): React.ReactNode {
        return (
            <div>
                <div className="ms-Grid-row">
                    <div className={`ms-Grid-col ms-lg12 ms-xl8 ${styles.resize}`}>
                        <h1 className={`${FontClassNames.xLargePlus} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>
                            {t(!this.props.favoritesOnly ? "myDownloads.header" : "myDownloads.favoritesHeader")}
                            <IconButton className={styles.filterButton} iconProps={{ iconName: "Filter" }} onClick={this.onShowFilterPanel}></IconButton>
                        </h1>
                        {this.renderDownloadList()}
                        <div className={`${styles.pageButton}`}>
                            <IconButton disabled={this.state.page <= 0} iconProps={{ iconName: "pageLeft" }} onClick={() => { this.changeDisplayPage(-1); }}></IconButton>
                            <IconButton disabled={!this.state.queryResult || this.state.queryResult.totalCount <= (this.state.page + 1) * this.pageSize} iconProps={{ iconName: "pageRight" }} onClick={() => { this.changeDisplayPage(1); }}></IconButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    /**
     * Renders the filter panel
     * @returns Rendered component
     */
    private renderFilterPanel(): React.ReactNode {
        let hideStyle = !this.state.showFilterPanel ? styles.hideFilterPanelInSmall : "";

        return (
            <div>
                <div className={`${hideStyle} ${ColorClassNames.whiteBackground} ${styles.filterContainer} ${styles.shadow}`}>
                    <IconButton className={styles.filterCloseButton} onClick={this.onCloseFilterPanel} iconProps={{ iconName: "ChromeClose" }}></IconButton>
                    <FilterPanel isLoading={this.state.pageLoading} favoritesOnly={this.props.favoritesOnly} onFilterChanged={this.onFilterChanged}></FilterPanel>
                </div>
            </div>
        );
    }

    /**
     * Shows the filter panel
     */
    private onShowFilterPanel = (): void => {
        this.setState({
            showFilterPanel: true
        });
    }

    /**
     * Closes the filter panel
     */
    private onCloseFilterPanel = (): void => {
        this.setState({
            showFilterPanel: false
        });
    }

    /**
     * Zooms the actual shown file to page max
     */
    private fileZoom = (): void => {
        window.scrollTo(0, 0);
        this.setState({ "viewFileZoom": !this.state.viewFileZoom });
    }

    /**
     * Gets called if the view loading spinner must be hidden
     */
    private onHideViewLoadingSpinner = (): void => {
        this.setState({
            showViewLoadingSpinner: false
        })
    }

    /**
     * Renders a redirect for a logged in user
     */
    private renderAnonymouseUserRedirect(): ReactNode {
        if (this.props.currentUser && this.props.currentUser.isAuthenticated) {
            return null;
        }

        let redirectUrl = "";
        if(this.state.urlProductNumberPreview) {
            redirectUrl = "/" + this.state.urlProductNumberPreview.substr(this._bookPrefix.length);
        } else {
            redirectUrl = !this.props.favoritesOnly ? "/myDownloads" : "/favorites";
        }

        return (<Redirect to={{
            pathname: "/login",
            state: { from: redirectUrl }
        }}></Redirect>);
    }

    /**
     * Renders a redirect for invalid url
     */
    private renderNotFoundRedirectOnInvalidUrl(): ReactNode {
        if (!this.state.invalidProductNumberUrl) {
            return null;
        }

        return (<Redirect to={{
            pathname: "/notFound",
        }}></Redirect>);
    }

    /**
     * Renders a message that an error occured
     * @param t Translate function
     */
    private renderErrorOccured(t: TFunction): ReactNode {
        if (!this.state.errorOccured) {
            return null;
        }

        return (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.removeErrorBanner} dismissButtonAriaLabel={t("general.close")}>
                {t("general.errorOccured")}
            </MessageBar>
        )
    }

    /**
     * Downloads a file
     * @param fileId Id of the file
     */
    private downloadFile = (fileId: string): void => {
        window.location.assign("/api/myDownloads/download?fileId=" + fileId)
    };

    /**
     * Renders the download list
     */
    private renderDownloadList(): React.ReactNode {
        if (!this.state.queryResult || !this.state.queryResult.entries) {
            return null;
        }
        const sectionStackTokens: IStackTokens = { childrenGap: 20 };

        return (<div>

            <Stack wrap tokens={sectionStackTokens} horizontal >
                {this.state.queryResult.entries.map((e) => this.renderDownloadCard(e))}
            </Stack>
        </div>);
    }

    /**
     * Renders a download card
     * @param e Rend
     */
    private renderDownloadCard(entry: MyDownloadsEntry): JSX.Element {
        const siteTextStyles: ITextStyles = {
            root: {
                color: this._theme.palette.themeTertiary,
                fontWeight: FontWeights.semibold
            }
        };
        const descriptionTextStyles: ITextStyles = {
            root: {
                color: this._theme.palette.themePrimary,
                fontWeight: FontWeights.semibold
            }
        };
        const searchPreviewTextStyles: ITextStyles = {
            root: {
                color: this._theme.palette.neutralDark,
                fontWeight: FontWeights.light
            }
        };

        const iconStyles: IIconStyles = {
            root: {
                color: this._theme.palette.themePrimary,
                fontSize: FontSizes.mediumPlus,
                fontWeight: FontWeights.regular,
                cursor: "pointer"
            }
        };
        const footerCardSectionStyles: ICardSectionStyles = {
            root: {
                alignSelf: 'stretch',
                borderLeft: '1px solid #F3F2F1'
            }
        };

        const cardTokens: ICardTokens = { childrenMargin: 12 };
        const footerCardSectionTokens: ICardSectionTokens = { padding: '0px 0px 0px 12px' };

        return <Card style={{ maxWidth: this.state.displayingSearchResult ? "calc(100% - 50px)" : undefined }} className={`${ColorClassNames.neutralLightBackground} ${styles.searchResultCard}`} key={entry.id} aria-label={entry.title} horizontal tokens={cardTokens}>
            <Card.Item fill>
                {entry.entryType !== MyDownloadsFilterEntryType.Video && entry.entryType !== MyDownloadsFilterEntryType.Generic &&
                    <Image src={entry.imageUrl} style={{ height: "139px" }} alt="Preview" />
                }
                {(entry.entryType === MyDownloadsFilterEntryType.Video || entry.entryType === MyDownloadsFilterEntryType.Generic) &&
                    <div className={`${styles.outerVideo} ${ColorClassNames.themePrimaryBackground}`}>
                        <div className={`${styles.innerVideo}`}>
                            <Icon style={{ fontSize: FontSizes.xxLargePlus }} className={ColorClassNames.white} iconName={entry.entryType === MyDownloadsFilterEntryType.Video ? "MSNVideos" : "Attach"}></Icon>
                        </div>
                    </div>
                }
            </Card.Item>
            <Card.Section grow={2}>
                <Text variant="small" styles={siteTextStyles}>
                    {entry.entryType !== MyDownloadsFilterEntryType.Video && entry.entryType !== MyDownloadsFilterEntryType.Generic && <span>{entry.edition} {entry.publicationYear} {entry.pageCount !== 0 ? <span>({entry.pageCount} Seiten)</span> : null}</span>}
                    {(entry.entryType === MyDownloadsFilterEntryType.Video || entry.entryType === MyDownloadsFilterEntryType.Generic) && <span>{entry.publicationYear}</span>}
                </Text>
                <Text styles={descriptionTextStyles}>{entry.title}</Text>
                {entry.searchHit && <Text styles={searchPreviewTextStyles}><span className={styles.searchResultContent} dangerouslySetInnerHTML={{__html: entry.searchHit}}></span></Text>}
            </Card.Section>
            <Card.Section styles={footerCardSectionStyles} tokens={footerCardSectionTokens}>
                {entry.entryType !== MyDownloadsFilterEntryType.Generic && <Icon iconName="RedEye" styles={iconStyles} onClick={() => { this.onShowEntryInline(entry); }} />}
                {entry.entryType === MyDownloadsFilterEntryType.Book && <Icon iconName="Info" styles={iconStyles} onClick={() => { this.onShowDocumentEntryInfo(entry); }} />}
                <Icon iconName={entry.isFavorited ? "FavoriteStarFill" : "FavoriteStar"} styles={iconStyles} onClick={() => this.toggleFavorite(entry.id)} />
                <Stack.Item grow={1}>
                    <span />
                </Stack.Item>
                <Icon iconName="CloudDownload" styles={iconStyles} onClick={() => { this.downloadFile(entry.id); }} />
            </Card.Section>
        </Card>;
    }

    /**
     * Shows a download entry inline
     * @param entry Download entry
     */
    private onShowEntryInline = (entry: MyDownloadsEntry): void => {
        window.scrollTo(0, 0);
        if (entry.entryType === MyDownloadsFilterEntryType.Video) {
            this.setState({
                viewVideo: entry.id,
                showViewLoadingSpinner: true
            });
        } else if(entry.entryType !== MyDownloadsFilterEntryType.Generic) {
            this.setState({
                viewFile: entry.id,
                showViewLoadingSpinner: true
            });
        }
    }

    /**
     * Shows the info for a document entry
     * @param entry Entry for which the details must be shown
     */
    private onShowDocumentEntryInfo = (entry: MyDownloadsEntry): void => {
        this.setState({
            detailDownloadEntry: entry
        });
    }

    /**
     * Closes the document entry info panel
     */
    private onCloseDocumentEntryInfoPanel = (): void => {
        this.setState({
            detailDownloadEntry: null
        });
    }

    /**
     * Removes the error banner
     */
    private removeErrorBanner = (): void => {
        this.setState({
            errorOccured: false
        })
    }

    /**
     * Changes the display page
     * @param direction Direction of the paging
     */
    private changeDisplayPage = (direction: number) => {
        let newPage = this.state.page + direction;
        if (newPage < 0 || (this.state.queryResult && newPage * this.pageSize > this.state.queryResult.totalCount)) {
            return;
        }

        this.setState({
            page: newPage,
        }, () => {
            this.loadMyDownloads();
        });
    }

    /**
     * Loads the downloads of the user
     */
    private loadMyDownloads = async () => {
        if (!this.props.currentUser || !this.props.currentUser.isAuthenticated) {
            return;
        }

        this.setState({
            pageLoading: true
        });

        try {
            let myDownloads = await MyDownloadsFactoryService.getMyDownloadsService().getMyDownloads(this.state.page, this.pageSize, this.props.favoritesOnly, this.state.filter);
            this.setState({
                queryResult: myDownloads,
                errorOccured: false,
                pageLoading: false,
                displayingSearchResult: myDownloads.entries != null && myDownloads.entries.some(e => !!e.searchHit)
            });
        }
        catch
        {
            this.setState({
                errorOccured: true,
                pageLoading: false
            });
        }
    }

    /**
     * Gets called if a filter is changed
     * @param filter New filter
     */
    private onFilterChanged = (filter: MyDownloadsFilter) => {
        this.setState({
            filter: filter,
            page: 0
        }, () => {
            this.loadMyDownloads();
        });
    }

    /**
     * Toggles a favorite for a document
     * @param entryId Id of the entry
     */
    private toggleFavorite = async (entryId: string): Promise<void> => {
        if (!this.state.queryResult) {
            return;
        }

        let queryResult = { ...this.state.queryResult };
        let entry = queryResult.entries.find(e => e.id === entryId);
        if (!entry) {
            return;
        }

        let wasFavorited = entry.isFavorited;
        entry.isFavorited = !entry.isFavorited;
        this.setState({
            errorOccured: false,
            queryResult: queryResult
        });

        try {
            if (!wasFavorited) {
                await MyDownloadsFactoryService.getMyDownloadsService().addFavorite(entry.id);
            }
            else {
                await MyDownloadsFactoryService.getMyDownloadsService().removeFavorite(entry.id);
            }
        }
        catch
        {
            this.setState({
                errorOccured: true
            });
        }
    }
}

export default MyDownloads; 