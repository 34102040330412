/**
 * Data for a user subscription
 */
class UserSubscription {
    /**
     * Id of the subscription
     */
    public id: string;

    /**
     * Subscription number
     */
    public subscriptionNumber: string;

    /**
     * Product name
     */
    public productName: string;

    /**
     * true if the subscription is active, else false
     */
    public isActive: boolean;

    /**
     * Constructor
     */
    public constructor() {
        this.id = "";
        this.subscriptionNumber = "";
        this.productName = "";
        this.isActive = false;
    }
}

export default UserSubscription;