import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';
import { Panel, PrimaryButton, DefaultButton, MessageBar, MessageBarType, PanelType, SelectionMode, ShimmeredDetailsList, IColumn, Stack, IStackTokens, Label, TextField, IconButton, FontSizes, Dialog, DialogFooter, Spinner, DialogType, SpinnerSize } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';

// Components
import TranslatedComponent from '../../../../localization/TranslatedComponent';
import styles from './UserSubscriptionsPanel.module.css';

// Services
import AdminFactoryService from '../../../../services/admin/AdminFactory.service';

// Dto
import IUserSubscriptionsPanelProps from './dto/IUserSubscriptionsPanelProps';
import UserSubscriptionsPanelState from './dto/UserSubscriptionsPanelState';
import AddUserSubscriptionRequest from './../../../../dto/admin/AddUserSubscriptionRequest';

// Util
import UserSubscription from '../../../../dto/subscriptions/UserSubscription';

/**
 * User subscriptions panel Component
 */
class UserSubscriptionsPanel extends TranslatedComponent<IUserSubscriptionsPanelProps, UserSubscriptionsPanelState> {
    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<IUserSubscriptionsPanelProps>) {
        super(props);

        this.state = new UserSubscriptionsPanelState();
    }

    /**
     * Gets called if the component is updated
     */
    public componentDidUpdate(prevProps: IUserSubscriptionsPanelProps) {
        if (this.props.userToEdit !== null && prevProps.userToEdit === null) {
            this.setState({
                userSubscriptions: [],
                showErrorMessage: false
            }, () => {
                this.loadUserSubscriptions();
            });
        }
    }

    /**
     * Loads the user subscriptions
     */
    private loadUserSubscriptions = async (): Promise<void> => {
        if(this.props.userToEdit === null) {
            return;
        }

        this.setState({
            userSubscriptions: [],
            isLoading: true,
            isSaving: false
        });

        AdminFactoryService.getAdminService().getUserSubscriptions(this.props.userToEdit.id).then((subscriptions) => {
            this.setState({
                userSubscriptions: subscriptions,
                isLoading: false
            });
        }, () => {
            this.setState({
                isLoading: false,
                errorOccured: true
            })
        });
    }

    /**
     * Renders the administration component
     * @param t Translate function
     * @returns Rendered component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        const addSubscriptionsStackTokens: IStackTokens = {
            childrenGap: 10
        };

        return (
            <Panel
                isOpen={this.props.userToEdit !== null}
                onDismiss={this.props.onClosePanel}
                closeButtonAriaLabel={t("general.close")}
                headerText={t("userSubscriptionsPanel.header")}
                onRenderFooter={() => this.renderPanelFooter(t)}
                isFooterAtBottom={true}
                type={PanelType.large}>
                {this.renderErrorOccured(t)}
                <Label required={true}>{t("userSubscriptionsPanel.addSubscription")}</Label>
                <Stack horizontal tokens={addSubscriptionsStackTokens}>
                    <Stack.Item grow>
                        <TextField placeholder={t("userSubscriptionsPanel.number")} errorMessage={this.getSubscriptionsNumberErrorMessage(t)} value={this.state.subscriptionNumberToAdd} onChange={this.onSubscriptionNumberToAddChange}></TextField>
                    </Stack.Item>
                    <Stack.Item>
                        <PrimaryButton onClick={this.onSaveUserSubscription}>
                            {this.state.isSaving && <Spinner className={styles.saveButtonSpinner} size={SpinnerSize.xSmall}></Spinner>}
                            {t("userSubscriptionsPanel.addSubscriptionButton")}
                        </PrimaryButton>
                    </Stack.Item>
                </Stack>
                <ShimmeredDetailsList
                    items={this.state.userSubscriptions}
                    columns={this.getUserColumns(t)}
                    enableShimmer={this.state.isLoading}
                    selectionMode={SelectionMode.none}
                    getKey={this.getUserRowKey}></ShimmeredDetailsList>

                {this.renderConfirmDeleteDialog(t)}
            </Panel>
        );
    }

    /**
     * Returns the subscription number error message
     * @param t Translate function
     */
    private getSubscriptionsNumberErrorMessage = (t: TFunction) => {
        if(!this.state.showErrorMessage || this.state.subscriptionNumberToAdd) {
            return "";
        }

        return t("general.mandatoryField");
    }

    /**
     * Renders the confirm delete dialog
     * @param t Translate function
     */
    private renderConfirmDeleteDialog = (t: TFunction): JSX.Element => {
        let deleteContent = "";
        if(this.state.subscriptionToDelete) {
            deleteContent = t("userSubscriptionsPanel.confirmDeleteText");
            deleteContent = deleteContent.replace("{0}", this.state.subscriptionToDelete.subscriptionNumber);
        }
        
        const dialogContentProps = {
            type: DialogType.normal,
            title: t("userSubscriptionsPanel.confirmDeleteHeader"),
            closeButtonAriaLabel: t("general.close")
        };
      
        const modalProps = {
            isBlocking: true
        };

        return (<Dialog
                    hidden={this.state.subscriptionToDelete === null}
                    onDismiss={this.onCloseConfirmDialog}
                    dialogContentProps={dialogContentProps}
                    modalProps={modalProps}>
                    {this.renderErrorOccured(t)}
                    {deleteContent}
                    <DialogFooter>
                        <PrimaryButton onClick={this.onRunDeleteSubscription} disabled={this.state.isSaving}>
                            {this.state.isSaving && <Spinner className={styles.saveButtonSpinner} size={SpinnerSize.xSmall}></Spinner>}
                            {t("general.delete")}
                        </PrimaryButton>
                        <DefaultButton onClick={this.onCloseConfirmDialog} disabled={this.state.isSaving} text={t("general.cancel")} />
                    </DialogFooter>
                </Dialog>);
    }

    /**
     * Returns the user columns
     * @param t Translate function
     * @returns User Columns
     */
    private getUserColumns = (t: TFunction): IColumn[] => {
        return [
            {
                key: 'number',
                name: t('userSubscriptionsPanel.number'),
                fieldName: 'subscriptionNumber',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true
            },
            {
                key: 'name',
                name: t('userSubscriptionsPanel.productName'),
                fieldName: 'productName',
                minWidth: 250,
                maxWidth: 450,
                isResizable: true
            },
            {
                key: 'active',
                name: t('userSubscriptionsPanel.status'),
                fieldName: 'isActive',
                minWidth: 250,
                maxWidth: 450,
                isResizable: true,
                onRender: (u) => this.renderStatusColumn(t, u)
            },
            {
                key: 'edit',
                name: '',
                fieldName: '',
                minWidth: 150,
                maxWidth: 250,
                isResizable: true,
                onRender: (u) => this.renderEditColumn(t, u)
            }
        ];
    }

    /**
     * Returns the key for a user row
     * @param item Row item
     * @returns Row key
     */
    private getUserRowKey = (item: UserSubscription, index?: number): string => {
        if (!item) {
            return index ? index.toString() : "";
        }
        return item.id;
    }

    /**
     * Renders the name column
     * @param t Translate function
     * @param userSubscription Subscription to render
     * @returns Rendered column
     */
    private renderStatusColumn = (t: TFunction, userSubscription: UserSubscription): JSX.Element | string => {
        let statusDisplayname = t("userSubscriptionsPanel.active");
        if(!userSubscription.isActive) {
            statusDisplayname = t("userSubscriptionsPanel.inactive");
        }
        
        return statusDisplayname;
    }

    /**
     * Renders the edit column
     * @param t Translate function
     * @param userSubscription User Subscription
     * @returns Edit column
     */
    private renderEditColumn = (t: TFunction, userSubscription: UserSubscription): JSX.Element | string => {
        return (
            <span>
                <IconButton styles={{ icon: { fontSize: FontSizes.small } }} iconProps={{ iconName: "Delete" }} onClick={() => { this.onDeleteUserSubscription(userSubscription) }} title={t("general.delete")} ariaLabel={t("general.delete")}></IconButton>
            </span>
        )
    }

    /**
     * Renders the panel footer
     * @param t Translate function
     */
    private renderPanelFooter(t: TFunction): JSX.Element | null {
        return (
            <div className={styles.footerContainer}>
                <DefaultButton disabled={this.state.isLoading || this.state.isSaving} onClick={this.props.onClosePanel}>{t("general.close")}</DefaultButton>
            </div>
        );
    }

    /**
     * Renders a message that an error occured
     * @param t Translate function
     */
    private renderErrorOccured(t: TFunction): ReactNode {
        if (!this.state.errorOccured) {
            return null;
        }

        return (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.removeErrorBanner} dismissButtonAriaLabel={t("general.close")}>
                {t("general.errorOccured")}
            </MessageBar>
        )
    }

    /**
     * Removes the error banner
     */
    private removeErrorBanner = (): void => {
        this.setState({
            errorOccured: false
        })
    }

    /**
     * Updates the subscription number that must be added
     * @param _ev Event
     * @param val Value to add
     */
    private onSubscriptionNumberToAddChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) => {
        if (!val) {
            val = "";
        }

        this.setState({
            subscriptionNumberToAdd: val
        });
    }

    /**
     * Gets called if a user subscription must be deleted
     * @param userSubscription User subscription
     */
    private onDeleteUserSubscription = (userSubscription: UserSubscription): void => {
        this.setState({
            isSaving: false,
            errorOccured: false,
            subscriptionToDelete: userSubscription
        });
    }

    /**
     * Triggers the delete subscription call
     */
    private onRunDeleteSubscription = async (): Promise<void> => {
        if(!this.state.subscriptionToDelete) {
            return;
        }

        this.setState({
            isSaving: true,
            errorOccured: false
        });
        
        try {
            await AdminFactoryService.getAdminService().deleteUserSubscription(this.state.subscriptionToDelete.id);

            this.setState({
                isSaving: false,
                subscriptionToDelete: null
            }, () => {
                this.loadUserSubscriptions();
            });
        }
        catch (e) {
            this.setState({
                isSaving: false,
                errorOccured: true
            });
        }
    }

    /**
     * Closes the confirm dialog
     */
    private onCloseConfirmDialog = (): void => {
        this.setState({
            subscriptionToDelete: null
        });
    }

    /**
     * Gets called if the user subscription must be saved
     */
    private onSaveUserSubscription = async (): Promise<void> => {
        if (!this.props.userToEdit || !this.props.userToEdit.id) {
            return;
        }

        if(!this.state.subscriptionNumberToAdd) {
            this.setState({
                showErrorMessage: true
            });
            return;
        }

        this.setState({
            isSaving: true,
            showErrorMessage: false,
            errorOccured: false
        });

        try {
            await AdminFactoryService.getAdminService().addUserSubscription(new AddUserSubscriptionRequest(this.props.userToEdit.id, this.state.subscriptionNumberToAdd));

            this.setState({
                isSaving: false,
                subscriptionNumberToAdd: ""
            }, () => {
                this.loadUserSubscriptions();
            });
        }
        catch (e) {
            this.setState({
                isSaving: false,
                errorOccured: true
            });
        }
    }
}

export default UserSubscriptionsPanel; 