// Util
import isDevMode from './../../util/IsDevMode';

// Services
import IAuthService from './IAuth.service';
import MockAuthService from './MockAuth.service';
import WebAuthService from './WebAuth.service';

/**
 * Factory class to provide auth services
 */
export default class AuthFactoryService {
    /**
     * Returns the auth service
     * @returns Auth service
     */
    public static getAuthService(): IAuthService {
        if (isDevMode()) {
            return new MockAuthService();
        }

        return new WebAuthService();
    }
}