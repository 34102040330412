import EventBusEvent from './EventBusEvent';
import EventCallBack from './EventCallbacks';

/**
 * Event bus service
 */
export default class EventBusService  {

    /**
     * To prevent the eventbus from being instantiated with new
     * @memberof EventBusService
     */
    private constructor() {
    }

    /**
     * AndEventBusInstance
     */
     public static instance: EventBusService;

    /**
     * Registered Event callbacks
     */
    private eventCallbacks: {[key: number]: EventCallBack[]} = {};

    /**
     * Returns the instance of the EventBusService
     * @static
     * @returns
     * @memberof EventBusService
     */
    public static getInstance() {
        if (!this.instance) {
            this.instance = new EventBusService(); 
        }
        return this.instance;
    }

    /**
     * Registers a new event callback
     * @param event Event
     * @param callback Callback to call on the event
     */
    public on(event: EventBusEvent, context: any, callback: Function) {
        if(!this.eventCallbacks[event]) {
            this.eventCallbacks[event] = [];
        }
        let eventCallBack: EventCallBack = {
            eventCallback: callback, 
            callbackContext: context
        }
        this.eventCallbacks[event].push(eventCallBack);
    }

    /**
     * Unregisters an event callback
     * @param event Event
     * @param callback Callback to unregister
     */
    public off(event: EventBusEvent, callback:Function) {
        if(!this.eventCallbacks[event]) {
            return;
        }
        for(let curCallback = this.eventCallbacks[event].length - 1; curCallback >= 0; --curCallback) {
            if(this.eventCallbacks[event][curCallback] && this.eventCallbacks[event][curCallback].eventCallback === callback) {
                this.eventCallbacks[event].splice(curCallback, 1);
            }
        }
    }

    /**
     * Calls all event callbacks for an event
     * @param event Event
     * @param args Callback arguments
     */
    public dispatch(event: EventBusEvent, ...args: any[]) {
        if(!this.eventCallbacks[event]) {
            return;
        }
        for(let curCallback of this.eventCallbacks[event]) {
            curCallback.eventCallback.call(curCallback.callbackContext, ...args);
        }
    }
}
