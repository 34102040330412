/**
 * Returns true if a string is a valid email adress
 * @param mail String to test
 * @returns true if a string is a valid email adress, else false
 */
function isValidEmail(mail: string): boolean {
    let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
    return regex.test(mail);
}

export default isValidEmail;
