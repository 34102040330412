import UserData from './../account/UserData'

/**
 * User create edit request data
 */
class UserCreateEditRequest {
    /**
     * Email
     */
    public email: string;

    /**
     * Password
     */
    public password: string;

    /**
     * Firstname
     */
    public salutation: string;

    /**
     * Firstname
     */
    public firstname: string;

    /**
     * Lastname
     */
    public lastname: string;
    
    /**
     * Company name
     */
    public companyName: string;
    
    /**
     * Company street
     */
    public companyStreet: string;
    
    /**
     * Company postal code
     */
    public companyPostalCode: string;
    
    /**
     * Company city
     */
    public companyCity: string;

    /**
     * Company country
     */
    public companyCountry: string;

    /**
     * Max session count
     */
    public maxSessionCount: number | null;

    /**
     * IP Address
     */
    public ipAddress: string;

    /**
     * Username overwrite
     */
    public usernameOverwrite: string;

    /**
     * Constructor
     */
    public constructor() {
        this.email = "";
        this.password = "";
        this.salutation = "";
        this.firstname = "";
        this.lastname = "";
        this.companyName = "";
        this.companyStreet = "";
        this.companyPostalCode = "";
        this.companyCity = "";
        this.companyCountry = "";
        this.maxSessionCount = null;
        this.ipAddress = "";
        this.usernameOverwrite = "";
    }

    /**
     * Assigns the user data from another user data object
     * @param userData User data to copy
     */
    public assignFromUserData(userData: UserData) {
        this.email = userData.email;
        this.password = userData.password;
        this.salutation = userData.salutation;
        this.firstname = userData.firstname;
        this.lastname = userData.lastname;
        this.companyName = userData.companyName;
        this.companyStreet = userData.companyStreet;
        this.companyPostalCode = userData.companyPostalCode;
        this.companyCity = userData.companyCity;
        this.companyCountry = userData.companyCountry;
        this.maxSessionCount = userData.maxSessionCount;
        this.ipAddress = userData.ipAddress;
        this.usernameOverwrite = userData.username;
    }

    /**
     * Clones the request
     * @returns Cloned request
     */
    public clone(): UserCreateEditRequest {
        let clonedObj = new UserCreateEditRequest();
        clonedObj.email = this.email;
        clonedObj.password = this.password;
        clonedObj.salutation = this.salutation;
        clonedObj.firstname = this.firstname;
        clonedObj.lastname = this.lastname;
        clonedObj.companyName = this.companyName;
        clonedObj.companyStreet = this.companyStreet;
        clonedObj.companyPostalCode = this.companyPostalCode;
        clonedObj.companyCity = this.companyCity;
        clonedObj.companyCountry = this.companyCountry;
        clonedObj.ipAddress = this.ipAddress;
        clonedObj.usernameOverwrite = this.usernameOverwrite;
        
        return clonedObj;
    }
}

export default UserCreateEditRequest;