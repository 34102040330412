// Library
import React from 'react';
import { IButtonProps, CommandBarButton } from 'office-ui-fabric-react';
import { useHistory } from 'react-router-dom';

/**
 * Renders a NavBar Button
 * @param props NavBar Button data
 */
const NavBarButton: React.FunctionComponent<IButtonProps> = props => {
    let routerHistory = useHistory();
    const buttonOnMouseClick = () => {
        routerHistory.push(props.data.target);
    }
    return (
        <CommandBarButton
            {...props}
            onClick={buttonOnMouseClick}
            styles={{
                ...props.styles
            }}
        />
    );
};

export default NavBarButton;