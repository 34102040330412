/**
 * Set new password request
 */
class SetNewPasswordRequest {
    /**
     * User Id for which the password must be reset
     */
    public userId: string;

    /**
     * Password reset code
     */
    public code: string;

    /**
     * New Password
     */
    public password: string;

    /**
     * Constructor
     */
    public constructor() {
        this.userId = "";
        this.code = "";
        this.password = "";
    }
}

export default SetNewPasswordRequest;