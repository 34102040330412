/**
 * User delete dialog state class
 */
class UserDeleteDialogState {
    /**
     * true if a load operation is in progress
     */
    public isLoading: boolean;

    /**
     * true if an error occured
     */
    public errorOccured: boolean;

    /**
     * Constructor
     */
    public constructor()
    {
        this.isLoading = false;
        this.errorOccured = false;
    }
}

export default UserDeleteDialogState;