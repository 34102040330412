import UserData from "./UserData";

/**
 * State of the application
 */
class AppState {
    /**
     * Displayname of the user
     */
    public currentUser: UserData;

    /**
     * true if too many logins were detected for the current user
     */
    public tooManyLogins: boolean;

    /**
     * Constructor
     */
    public constructor() {
        this.currentUser = new UserData();
        this.currentUser.isAuthenticated = false;

        this.tooManyLogins = false;
    }
}

export default AppState;