import DocumentDetailEntry from "../../../../../dto/documentAccess/DocumentDetailEntry";

/**
 * Document entry info sidepanel state class
 */
class DocumentEntryInfoSidePanelState {
    /**
     * Document entry
     */
    public documentEntry: DocumentDetailEntry | null;

    /**
     * true if a load operation is in progress
     */
    public isLoading: boolean;

    /**
     * true if an error occured
     */
    public errorOccured: boolean;

    /**
     * Constructor
     */
    public constructor()
    {
        this.documentEntry = null;
        this.isLoading = false;
        this.errorOccured = false;
    }
}

export default DocumentEntryInfoSidePanelState;