// Library
import { Guid } from "guid-typescript";
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';

// Services
import EventBusService from "../eventBus/EventBus.service";
import LogManagerService from "./../logging/LogManager.service";
import EventBusEvent from "../eventBus/EventBusEvent";

/**
 * Http Client that tracks requests
 */
export default class TrackedHttpClient {
    /**
     * Holds all ongoing get requests
     */
    private static getRequests: { [k: string]: any } = {};

    /**
     * Currently running CSRF request
     */
    private static runningCsrfRequest: Promise<string> | null = null;

    /**
     * Sens a tracked get request
     * @param {string} url
     * @param {*} [additionalOptions=null]
     */
    public static get<T>(url: string, additionalOptions: any = null): Promise<T> {
        return this.sendTrackedRequest<T>((httpOptions: AxiosRequestConfig) => axios.get<AxiosResponse<T>>(url, httpOptions), url, "GET", true, additionalOptions);
    }

    /**
    * Sends a post request
    * @param url Url to request
    * @param body Body to send
    * @param additionalOptions additional options for the request (content type etc.)
    */
    public static post<T>(url: string, body: any, additionalOptions: any = null): Promise<T> {
        return this.sendTrackedRequest<T>((httpOptions: AxiosRequestConfig) => axios.post<AxiosResponse<T>>(url, body, httpOptions), url, "POST", null, additionalOptions);
    }

    /**
     * Sends a put request
     * @param url Url to request
     * @param body Body to send
     * @param additionalOptions additional options for the request (content type etc.)
     */
    public static put<T>(url: string, body: any, additionalOptions: any = null): Promise<T> {
        return this.sendTrackedRequest<T>((httpOptions: AxiosRequestConfig) => axios.put<T>(url, body, httpOptions), url, "PUT", body, additionalOptions);
    }

    /**
     * Sensa a delete request
     * @param url Url to request
     * @param additionalOptions additional options for the request (content type etc.)
     */
    public static delete<T>(url: string, additionalOptions: any = null): Promise<T> {
        return this.sendTrackedRequest<T>((httpOptions: AxiosRequestConfig) => axios.delete<T>(url, httpOptions), url, "DELETE", null, additionalOptions);
    }

    /**
     * Creates a tracked request
     * @template T Body type
     * @param {(httpOptions: {}) => any} requestFunc Request function
     * @param {string} requestUrl Request url
     * @param {string} method Method to use
     * @param {*} additionalOptions Additional options
     * @returns {Promise<T>}
     */
    private static sendTrackedRequest<T>(requestFunc: (httpOptions: {}) => any, requestUrl: string, method: string, body: any, additionalOptions: any): Promise<T> {
        let startTime: number = Date.now();
        let batchUrl: string = requestUrl.toLowerCase();
        if (method === "GET") {
            if (this.getRequests[batchUrl]) {
                return this.getRequests[batchUrl];
            }
        }

        EventBusService.getInstance().dispatch(EventBusEvent.OnLoadStarted);
        let returnPromise: Promise<T> = new Promise((resolve, reject) => {
            let referenceId: string = Guid.create().toString();
            this.createHeaders(additionalOptions, referenceId, method).then((headers) => {
                let httpOptions: AxiosRequestConfig = {
                    headers: headers,
                }
                requestFunc(httpOptions).then((response: AxiosResponse) => {
                    LogManagerService.getInstance().trackRequest(referenceId, requestUrl, true, response.status, method, Date.now() - startTime);
                    EventBusService.getInstance().dispatch(EventBusEvent.OnLoadFinished);
                    resolve(response.data);
                    delete this.getRequests[batchUrl];
                }).catch((err: any) => {
                    delete this.getRequests[batchUrl];
                    let statusCode = 500;
                    if(err.response) {
                        statusCode = err.response;
                    }
                    LogManagerService.getInstance().trackRequest(referenceId, requestUrl, false, statusCode, method, Date.now() - startTime);
                    EventBusService.getInstance().dispatch(EventBusEvent.OnLoadFinished);
                    reject(err);
                });
            }, (err) => {
                delete this.getRequests[batchUrl];
                let statusCode = 500;
                if(err.response) {
                    statusCode = err.response;
                }
                LogManagerService.getInstance().trackRequest(referenceId, requestUrl, false, statusCode, method, Date.now() - startTime);
                EventBusService.getInstance().dispatch(EventBusEvent.OnLoadFinished);
                reject(err);
            });
        });

        if (method === "GET") {
            this.getRequests[batchUrl] = returnPromise;
        }

        return returnPromise;
    }

    /**
     * Creates http headers
     * @param additionalHeaders Additional headers
     * @param referenceId Reference id to use
     * @param method Request method
     */
    private static async createHeaders(additionalOptions: any, referenceId: string, method: string): Promise<{}> {
        let headers: any = {
            referenceId: referenceId
        }

        if(method !== "GET") {
            let csrfToken = await this.requestCsrfToken();
            headers["__RequestVerificationToken"] = csrfToken;
        }

        if (!additionalOptions || additionalOptions.headers) {
            return headers;
        }
        headers = {
            ...headers,
            ...additionalOptions.headers
        }
        return headers;
    }

    /**
     * Loads a CSRF Token
     * @returns CSRF Token
     */
    private static async requestCsrfToken(): Promise<string> {
        if(this.runningCsrfRequest != null)
        {
            return this.runningCsrfRequest;
        }

        this.runningCsrfRequest = this.get<string>("/api/util/getCsrfToken");
        let token = await this.runningCsrfRequest;
        this.runningCsrfRequest = null;

        return token;
    }
}