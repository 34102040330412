import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Libs
import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { loadTheme, ColorClassNames, initializeIcons, Stack, MessageBar, MessageBarType } from 'office-ui-fabric-react';

// Localization
import initLocalization from './localization/InitLocalization';

// Theming
import MainTheme from './theming/MainTheme';

// Components
import styles from './App.module.css'
import FullScreenLoading from './components/shared/fullscreenLoading/FullscreenLoading';
import Header from './components/shared/header/Header';
import NavBar from './components/shared/navBar/NavBar';
import PageViewTracker from './components/shared/pageViewTracker/PageViewTracker';
import GlobalLoadingIndicator from './components/shared/globalLoadingIndicator/GlobalLoadingIndicator';
import Footer from './components/shared/footer/Footer';
import Login from './components/login/Login';
import Register from './components/register/Register';
import ConfirmMail from './components/confirmMail/ConfirmMail';
import SetNewPassword from './components/setNewPassword/SetNewPassword';
import ChangePassword from './components/changePassword/ChangePassword';
import MyProfile from './components/myProfile/MyProfile';
import MySubscriptions from './components/mySubscriptions/MySubscriptions';
import PasswordReset from './components/passwordReset/PasswordReset';
import Home from './components/home/Home';
import MyDownloads from './components/myDownloads/MyDownloads';
import Administration from './components/administration/Administration';
import NotFound from './components/notFound/NotFound';
import Imprint from './components/imprint/Imprint';
import TermsOfUse from './components/termsOfUse/TermsOfUse';
import DataProtection from './components/dataProtection/DataProtection';

// Services
import AuthFactoryService from './services/auth/AuthFactory.service';
import LogManagerService from './services/logging/LogManager.service';

// Dto
import AppState from './dto/AppState';
import UserData from './dto/UserData';
import { Translation } from 'react-i18next';

initializeIcons();
loadTheme(MainTheme);
initLocalization();

/**
 * App Component
 */
class App extends React.Component<{}, AppState> {
  /**
   * Constructor
   * @param props Props
   */
  public constructor(props: Readonly<{}>) {
    super(props);

    LogManagerService.getInstance().initializeLoggers();

    let state = new AppState();
    let loginResult = AuthFactoryService.getAuthService().checkLoggedInUser();
    if (loginResult && loginResult.user && loginResult.user.isAuthenticated) {
      state.currentUser = loginResult.user;
    }

    if (loginResult && loginResult.tooManyLogins) {
      state.tooManyLogins = true;
    }

    this.state = state;
  }

  /**
   * Renders the component
   */
  public render() {
    return (
      <Suspense fallback={<FullScreenLoading></FullScreenLoading>}>
        <div className={`ms-Fabric ${styles.bodyContent} ${ColorClassNames.whiteBackground}`} dir="ltr">
          <Router>
            <Stack wrap verticalFill={true} styles={{ root: { minHeight: "100vh" } }}>
              <Stack.Item>
                <PageViewTracker></PageViewTracker>
                <Header></Header>
                <GlobalLoadingIndicator></GlobalLoadingIndicator>
                <NavBar currentUser={this.state.currentUser} onUserSignedOut={this.resetCurrentUser}></NavBar>
              </Stack.Item>
              <Stack.Item grow>
                <div className={styles.mainContent}>
                  {this.state.tooManyLogins && this.renderTooManyLoginsBanner()}
                  <Switch>
                    <Route key="home" exact path="/">
                      <Home></Home>
                    </Route>
                    <Route key="login" path="/login">
                      <Login onUserLoggedIn={this.onUserLoggedIn} currentUser={this.state.currentUser}></Login>
                    </Route>
                    <Route key="register" path="/register">
                      <Register></Register>
                    </Route>
                    <Route key="confirmMail" path="/confirmMail">
                      <ConfirmMail></ConfirmMail>
                    </Route>
                    <Route key="setNewPassword" path="/setNewPassword">
                      <SetNewPassword></SetNewPassword>
                    </Route>
                    <Route key="passwordReset" path="/passwordReset">
                      <PasswordReset></PasswordReset>
                    </Route>
                    <Route key="changePassword" path="/changePassword">
                      <ChangePassword currentUser={this.state.currentUser}></ChangePassword>
                    </Route>
                    <Route key="myProfile" path="/myProfile">
                      <MyProfile currentUser={this.state.currentUser}></MyProfile>
                    </Route>
                    <Route path="/mySubscriptions">
                      <MySubscriptions currentUser={this.state.currentUser}></MySubscriptions>
                    </Route>
                    <Route key="myDownloads" path="/myDownloads">
                      <MyDownloads currentUser={this.state.currentUser} favoritesOnly={false} fromUrlPreview={false}></MyDownloads>
                    </Route>
                    <Route key="favorites" path="/favorites">
                      <MyDownloads currentUser={this.state.currentUser} favoritesOnly={true} fromUrlPreview={false}></MyDownloads>
                    </Route>
                    <Route key="administration" path="/administration">
                      <Administration currentUser={this.state.currentUser}></Administration>
                    </Route>
                    <Route key="imprint" path="/imprint">
                      <Imprint></Imprint>
                    </Route>
                    <Route key="dataProtection" path="/dataProtection">
                      <DataProtection></DataProtection>
                    </Route>
                    <Route key="termsOfUse" path="/termsOfUse">
                      <TermsOfUse></TermsOfUse>
                    </Route>
                    <Route key="fallback" path="/notFound">
                      <NotFound></NotFound>
                    </Route>
                    <Route key="myDownloadsByEan" path="/:productNumber">
                      <MyDownloads currentUser={this.state.currentUser} favoritesOnly={false} fromUrlPreview={true}></MyDownloads>
                    </Route>
                    <Route key="fallback" path="*">
                      <NotFound></NotFound>
                    </Route>
                  </Switch>
                </div>
              </Stack.Item>
              <Stack.Item>
                <Footer></Footer>
              </Stack.Item>
            </Stack>
          </Router>
        </div>
      </Suspense>
    );
  }

  /**
   * Renders a banner if too many logins were detected
   */
  private renderTooManyLoginsBanner = (): React.ReactNode => {
    return (
      <Translation>
        {
          (t, { i18n }) => <MessageBar className={styles.tooManyLoginsBanner} messageBarType={MessageBarType.warning} onDismiss={this.dismissToManyLoginsBanner}>{t("general.tooManyLogins")}</MessageBar>
        }
      </Translation>
    );
  }

  /**
   * Dissmises the too many logins banner
   */
  private dismissToManyLoginsBanner = (): void => {
    this.setState({
      tooManyLogins: false
    });
  }

  /**
   * Gets called if a user logged in
   * @param user User that logged in
   */
  public onUserLoggedIn = (user: UserData) => {
    this.setState({
      currentUser: user
    });
  }

  /**
   * Resets the current user
   */
  public resetCurrentUser = () => {
    let signOutUser = new UserData();
    signOutUser.isAuthenticated = false;
    signOutUser.displayName = "";

    this.setState({
      currentUser: signOutUser
    });
  }
}

export default App;
