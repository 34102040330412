import AvailableUserProduct from '../../../../../dto/admin/AvailableUserProduct';
import UserProduct from '../../../../../dto/admin/UserProduct';

/**
 * User Product panel state class
 */
class UserProductPanelState {
    /**
     * Available products
     */
    public availableProducts: AvailableUserProduct[];

    /**
     * User products
     */
    public userProducts: UserProduct[];

    /**
     * Selected product key
     */
    public selectedProductKey: string | null;

    /**
     * Selected start date
     */
    public selectedStartDate: Date | undefined;
    
    /**
     * Selected end date
     */
    public selectedEndDate: Date | undefined;

    /**
     * true if the error messages must be shown, else false
     */
    public showErrorMessage: boolean;

    /**
     * true if a load operation is in progress
     */
    public isLoading: boolean;

    /**
     * true if a save operation is in progress
     */
    public isSaving: boolean;

    /**
     * true if an error occured
     */
    public errorOccured: boolean;

    /**
     * Constructor
     */
    public constructor()
    {
        this.availableProducts = [];
        this.userProducts = [];
        this.selectedProductKey = null;
        this.selectedStartDate = undefined;
        this.selectedEndDate = undefined;
        this.showErrorMessage = false;
        this.isLoading = false;
        this.isSaving = false;
        this.errorOccured = false;
    }
}

export default UserProductPanelState;