import TrackedHttpClient from './../trackedHttpClient/TrackedHttpClient.service';
import RegisterRequest from '../../dto/account/RegisterRequest';
import ResetPasswordRequest from '../../dto/account/ResetPasswordRequest';
import SetNewPasswordRequest from '../../dto/account/SetNewPasswordRequest';
import ChangePasswordRequest from '../../dto/account/ChangePasswordRequest';
import UpdateUserDataRequest from '../../dto/account/UpdateUserDataRequest';
import CurrentUserDataResponse from '../../dto/account/CurrentUserDataResponse';
import IAccountService from './IAccount.service';

/**
 * Web account services
 */
class WebAccountService implements IAccountService {
    /**
     * Registers a new user
     * @param registerRequest Register request
     * @returns Promise
     */
    public async register(registerRequest: RegisterRequest): Promise<void> {
        await TrackedHttpClient.post<void>("/api/account/register", registerRequest);
    }
    
    /**
     * Resets the password for a user
     * @param resetRequest Reset request
     * @returns Promise
     */
    public async resetPassword(resetRequest: ResetPasswordRequest): Promise<void> {
        await TrackedHttpClient.post<void>("/api/account/resetPassword", resetRequest);
    }

    /**
     * Sets the new password for a user
     * @param setNewPasswordRequest New password request
     * @returns Promise
     */
    public async setNewPassword(setNewPasswordRequest: SetNewPasswordRequest): Promise<void> {
        await TrackedHttpClient.post<void>("/api/account/setNewPassword", setNewPasswordRequest);
    }

    /**
     * Changes the password for a user
     * @param changePasswordRequest Change password request
     * @returns Promise
     */
    public async changePassword(changePasswordRequest: ChangePasswordRequest): Promise<void> {
        await TrackedHttpClient.post<void>("/api/account/changePassword", changePasswordRequest);
    }

    /**
     * Returns the current user data
     * @returns Current user data
     */
    public async getCurrentUserData(): Promise<CurrentUserDataResponse> {
        return await TrackedHttpClient.get<CurrentUserDataResponse>("/api/account/currentUserData");
    }

    /**
     * Updates the user data
     * @param updateUserDataRequest Update user data request
     * @returns Promise
     */
    public async updateUserData(updateUserDataRequest: UpdateUserDataRequest): Promise<void> {
        await TrackedHttpClient.post<void>("/api/account/updateUserData", updateUserDataRequest);
    }
}

export default WebAccountService;