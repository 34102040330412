import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';

// Components
import TranslatedComponent from '../../localization/TranslatedComponent';
import styles from './Imprint.module.css';
import { FontClassNames, ColorClassNames, FontWeights, Link } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';

/**
 * Imprint Component
 */
class Imprint extends TranslatedComponent<{}, {}> {

    /**
     * Renders the home component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <div className={styles.imprintContainer}>
                <h1 className={`${FontClassNames.xLargePlus} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("imprint.header")}</h1>
                <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("imprint.ownerOfSite") }</h2>
                <p>
                    { t("imprint.company") }<br/>
                    { t("imprint.road") }<br/>
                    { t("imprint.city") }<br/>
                    { t("imprint.phone") }<br/>
                    { t("imprint.fax") }<br/>
                    { t("imprint.mail") }<br/>
                </p>
                <p>
                    { t("imprint.authorizedRepresentative") }<br/>
                    { t("imprint.court") }<br/>
                    { t("imprint.tradeNumber") }<br/>
                    { t("imprint.tradeNumberLegal") }<br/>
                </p>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("imprint.noticeOfLiability") }</h2>
                    { t("imprint.noticeOfLiabilityContent") }
                </p>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("imprint.legalNotices") }</h2>
                    { t("imprint.legalNoticesContent") }
                </p>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("imprint.onlineProcessSolving") }</h2>
                    { t("imprint.onlineProcessSolvingContent") }<Link href='http://ec.europa.eu/consumers/odr/'>http://ec.europa.eu/consumers/odr/</Link>
                </p>
            </div>
        );
    }
}

export default Imprint; 