import { TFunction } from 'i18next';
import { IDatePickerStrings } from 'office-ui-fabric-react';

/**
 * Builds the datepicker strings
 * @parma t Translate Function
 * @returns Datepciker strings
 */
export default function buildDatePickerStrings(t: TFunction): IDatePickerStrings {
    return {
        months: [t("general.january"), t("general.february"), t("general.march"), t("general.april"), t("general.may"), t("general.june"), t("general.july"), t("general.august"), t("general.september"), t("general.october"), t("general.november"), t("general.december")],
        shortMonths: [t("general.shortJanuary"), t("general.shortFebruary"), t("general.shortMarch"), t("general.shortApril"), t("general.shortMay"), t("general.shortJune"), t("general.shortJuly"), t("general.shortAugust"), t("general.shortSeptember"), t("general.shortOctober"), t("general.shortNovember"), t("general.shortDecember")],

        days: [t("general.sunday"), t("general.monday"), t("general.tuesday"), t("general.wednesday"), t("general.thursday"), t("general.friday"), t("general.saturday")],
        shortDays: [t("general.shortSunday"), t("general.shortMonday"), t("general.shortTuesday"), t("general.shortWednesday"), t("general.shortThursday"), t("general.shortFriday"), t("general.shortSaturday")],

        goToToday: t("general.today"),
        prevMonthAriaLabel: t("general.prevMonth"),
        nextMonthAriaLabel: t("general.nextMonth"),
        prevYearAriaLabel: t("general.prevYear"),
        nextYearAriaLabel: t("general.nextYear")
    };
}