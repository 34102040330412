import UserImportLogEntry from './UserImportLogEntry';

/**
 * User import state class
 */
class UserImportPanelState {
    /**
     * true if a load operation is in progress
     */
    public isLoading: boolean;

    /**
     * true if an import is running
     */
    public isImportRunning: boolean;

    /**
     * true if the improt log must be shown
     */
    public showImportLog: boolean;

    /**
     * Import progress
     */
    public importProgress: number;

    /**
     * Log of the import
     */
    public importLog: UserImportLogEntry[];

    /**
     * true if a validation error message must be shown
     */
    public showErrorMessage: boolean;

    /**
     * true if an error occured
     */
    public errorOccured: boolean;

    /**
     * Constructor
     */
    public constructor()
    {
        this.isLoading = false;
        this.isImportRunning = false;
        this.showImportLog = false;
        this.importProgress = 0;
        this.importLog = [];
        this.showErrorMessage = false;
        this.errorOccured = false;
    }
}

export default UserImportPanelState;