// Libs
import React from 'react';
import { Spinner, SpinnerSize, ColorClassNames } from 'office-ui-fabric-react';

// Components
import styles from './FullscreenLoading.module.css';

/**
 * Fullscreen Loading Component
 */
class FullScreenLoading extends React.Component<{}, {}> {

  /**
   * Renders the component
   */
  public render() {
    return (
        <div className={`${styles.fullscreenLoadingContainer} ${ColorClassNames.whiteBackground}`}>
            <div role="progressbar" className={styles.fullscreenLoadingSpinnerContainer}>
                <Spinner size={SpinnerSize.large} />
            </div>
        </div>
    );
  }
}

export default FullScreenLoading;
