import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';

// Components
import TranslatedComponent from '../../localization/TranslatedComponent';
import styles from './NotFound.module.css';
import { FontWeights, ColorClassNames } from 'office-ui-fabric-react';

/**
 * NotFound Component
 */
class NotFound extends TranslatedComponent<{}, {}> {
    /**
     * Renders the notfound component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <div className={styles.notFoundContainer}>
                <h1 className={ColorClassNames.neutralPrimary} style={{ fontWeight: FontWeights.light as number, marginTop: "100px" }}>{t("notFound.notFound")}</h1>
                <img alt={t("notFound.notFound")} style={{ width: "200px" }} src="/img/eis.svg" />
            </div>
        );
    }
}

export default NotFound; 