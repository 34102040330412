// Util
import isDevMode from './../../util/IsDevMode';

// Services
import IMyDownloadsService from './IMyDownloads.service';
import MockMyDownloadsService from './MockMyDownloads.service';
import WebMyDownloadsService from './WebMyDownloads.service';

/**
 * Factory class to provide my downloads service
 */
export default class MyDownloadsFactoryService {
    /**
     * Returns the my downloads service
     * @returns My Downloads service
     */
    public static getMyDownloadsService(): IMyDownloadsService {
        if (isDevMode()) {
            return new MockMyDownloadsService();
        }

        return new WebMyDownloadsService();
    }
}