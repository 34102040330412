import React, { ReactNode } from 'react';

// Library
import { TextField, Toggle, Checkbox, FontClassNames, FontWeights, ColorClassNames, Stack, FontSizes, Spinner, SpinnerSize, Icon, IStackTokens, Dropdown } from 'office-ui-fabric-react';
import { TFunction } from 'i18next';

// Components
import TranslatedComponent from '../../../../localization/TranslatedComponent';
import styles from './FilterPanel.module.css';

// Util
import debounce from '../../../../util/Debounce';

// Services
import MyDownloadsFactoryService from '../../../../services/myDownloads/MyDownloadsFactory.service';

// Dto
import IFilterPanelProps from './dto/IFilterPanelProps';
import FilterPanelState from './dto/FilterPanelState';
import AvailableFilterFormat from './dto/AvailableFilterFormat';
import MyDownloadsFilter from '../../../../dto/myDownloadsFilter/MyDownloadsFilter';
import MyDownloadsFilterConfig from '../../../../dto/myDownloads/MyDownloadsFilterConfig';
import MyDownloadsFilterEntry from '../../../../dto/myDownloads/MyDownloadsFilterEntry';
import MyDownloadsFilterEntryType from '../../../../dto/myDownloads/MyDownloadsFilterEntryType';
import { IDropdownOption } from '@uifabric/react-cards/node_modules/office-ui-fabric-react';

/**
 * Filterpanel for my downloads Component
 */
class FilterPanel extends TranslatedComponent<IFilterPanelProps, FilterPanelState> {

    /**
     * Available formats
     */
    private readonly _availableFormats: AvailableFilterFormat[] = [
        new AvailableFilterFormat("downloadFilterPanel.formatMagazin", MyDownloadsFilterEntryType.Magazin),
        new AvailableFilterFormat("downloadFilterPanel.formatBook", MyDownloadsFilterEntryType.Book),
        new AvailableFilterFormat("downloadFilterPanel.formatVideo", MyDownloadsFilterEntryType.Video),
        new AvailableFilterFormat("downloadFilterPanel.genericFile", MyDownloadsFilterEntryType.Generic)
    ];

    /**
     * Debounced function to propagte the filter to the parent component
     */
    private debouncedPropagateFilterToParent: () => void;

    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<IFilterPanelProps>) {
        super(props);

        this.debouncedPropagateFilterToParent = debounce(this.propagateFilterToParent, this, 400, false);

        let state = new FilterPanelState();
        state.filter.formats = this._availableFormats.map(f => f.format);
        state.filter.filterEntries = [];

        this.state = state;
    }

    /**
     * Loads the filter config
     */
    public componentDidMount() {
        this.setState({
            isLoading: true,
            errorOccured: false
        });

        MyDownloadsFactoryService.getMyDownloadsService().getMyDownloadsConfig(this.props.favoritesOnly).then((filterConfig) => {
            let availableFormats = this.getAvailableFormatFilters(filterConfig);
            
            let magazinFilterEntries = filterConfig.filterEntries;
            
            let filter = { ...this.state.filter };
            filter.filterEntries = [ ...magazinFilterEntries ];
            
            this.setState({
                filter: filter,
                showYearFilter: filterConfig.minYear !== filterConfig.maxYear,
                minYear: filterConfig.minYear,
                maxYear: filterConfig.maxYear,
                availableFormats: availableFormats,
                filterEntries: magazinFilterEntries,
                isLoading: false
            });

            this.propagateFilterToParent();
        }, (err) => {
            this.setState({
                isLoading: false,
                errorOccured: true
            });
        });
    }

    /**
     * Returns the available format filters
     * @param filterConfig Filter config to check
     * @returns Available format filters
     */
    private getAvailableFormatFilters(filterConfig: MyDownloadsFilterConfig): AvailableFilterFormat[] {
        let availableFormatFilters: AvailableFilterFormat[] = [];
        let usedFormats: any = {};
        for(let curEntry of filterConfig.filterEntries)
        {
            if(usedFormats[curEntry.entryType])
            {
                continue;
            }

            usedFormats[curEntry.entryType] = true;
            let filterFormat = this._availableFormats.find(a => a.format === curEntry.entryType);
            if(filterFormat) {
                availableFormatFilters.push(filterFormat);
            }
        }

        return availableFormatFilters;
    }

    /**
     * Renders the filter panel component
     * @param t Translate function
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <div>
                <h1 className={`${FontClassNames.xLargePlus} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("downloadFilterPanel.header")}{this.props.isLoading && <Spinner className={styles.loadingSpinner} size={SpinnerSize.small}></Spinner>}</h1>

                <TextField placeholder={t("downloadFilterPanel.searchPlaceholder")} onChange={(ev, newVal) => this.onQueryChanged(newVal)}></TextField>
                {this.state.isLoading && !this.state.errorOccured && this.renderLoadingSpinner()}
                {!this.state.isLoading && this.state.errorOccured && this.renderErrorDisplay(t)}
                {!this.state.isLoading && !this.state.errorOccured && this.renderFilterPanel(t)}
            </div>
        );
    }

    /**
     * Renders a loading spinner while the download config is being loaded
     */
    private renderLoadingSpinner = (): ReactNode => {
        return (
            <div className={styles.filterPanelLoadingSpinnerContainer}>
                <Spinner size={SpinnerSize.large}></Spinner>
            </div>
        );
    }

    /**
     * Renders an error if the filter config can not be loaded
     * @param t Translate function
     */
    private renderErrorDisplay = (t: TFunction): ReactNode => {
        return (
            <div className={`${styles.filterPanelErrorContainer} ${ColorClassNames.red} ${FontClassNames.xLarge}`} style={{ fontWeight: FontWeights.light as number }}>
                <Icon iconName="ErrorBadge"></Icon>
                <div>{t("general.errorOccured")}</div>
            </div>
        );
    }

    /**
     * Renders the filter panel
     * @param t Translate function
     */
    private renderFilterPanel = (t: TFunction): ReactNode => {
        const addProductStackTokens: IStackTokens = {
            childrenGap: 10
        };
        
        const yearFilters: IDropdownOption[] = [ { key: -1, text: "" } ];
        for(let curYear = this.state.minYear; curYear <= this.state.maxYear; ++curYear)
        {
            yearFilters.push({ key: curYear, text: curYear.toString() });
        }

        return (
            <div>
                { this.state.showYearFilter &&
                    <div>
                        <h2 className={`${FontClassNames.xLarge} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("downloadFilterPanel.dateFilter")}</h2>
                        <Stack horizontal tokens={addProductStackTokens}>
                            <Stack.Item grow>
                                <Dropdown 
                                    placeholder={t("downloadFilterPanel.minDate")} 
                                    options={yearFilters} 
                                    selectedKey={this.state.filter.minDate ? this.state.filter.minDate.getFullYear() : -1}
                                    onChange={this.onMinDateChanged}></Dropdown>
                            </Stack.Item>
                            <Stack.Item>
                                -
                            </Stack.Item>
                            <Stack.Item grow>
                                <Dropdown 
                                    placeholder={t("downloadFilterPanel.maxDate")} 
                                    options={yearFilters} 
                                    selectedKey={this.state.filter.maxDate ? this.state.filter.maxDate.getFullYear() : -1}
                                    onChange={this.onMaxDateChanged}></Dropdown>
                            </Stack.Item>
                        </Stack>
                    </div>
                }
                { this.state.availableFormats.length > 0 && 
                    <div>
                        <h2 className={`${FontClassNames.xLarge} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("downloadFilterPanel.selectableFormats")}</h2>
                        {this.renderSelectableFormats(t)}
                    </div>
                }
                { this.state.filterEntries && this.state.filterEntries.length > 0 &&
                    <div>
                        <h2 className={`${FontClassNames.xLarge} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("downloadFilterPanel.selectableMagazins")}</h2>
                        {this.renderSelectableMagazins()}
                    </div>
                }
            </div>);
    }

    /**
     * Gets called if the min date changed
     * @param _ev Selection event
     * @param option Selected option
     */
    private onMinDateChanged = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
        let date: Date | null = null;
        let selectedYear = option ? option.key as number : -1;
        if(selectedYear > 0) {
            date = new Date(selectedYear, 0, 1, 0, 0, 0);
        }

        let filter = { ...this.state.filter };
        filter.minDate = date;
        this.updateFilter(filter);
    }

    /**
     * Gets called if the max date changed
     * @param date New Date
     */
    private onMaxDateChanged = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
        let date: Date | null = null;
        let selectedYear = option ? option.key as number : -1;
        if(selectedYear > 0) {
            date = new Date(selectedYear, 11, 31, 23, 59, 59);
        }

        let filter = { ...this.state.filter };
        filter.maxDate = date;
        this.updateFilter(filter);
    }

    /**
     * Gets called if the query text was changed
     * @param newVal New query text
     */
    private onQueryChanged = (newVal: string | undefined): void => {
        let filter = { ...this.state.filter };
        filter.searchQuery = newVal ? newVal : "";
        this.updateFilter(filter);
    }

    /**
     * Renders the selectable formats
     * @param t Translate function
     * @returns Rendered selectable formats
     */
    private renderSelectableFormats(t: TFunction): React.ReactNode {
        let renderedFormats = this.state.availableFormats.map(f =>
            <Toggle styles={{ root: { fontSize: FontSizes.medium }, text: FontSizes.medium }} key={f.format} label="" onText={t(f.langKey)} offText={t(f.langKey)}
                onChange={(ev, checked) => { this.toggleFormat(f.format, checked); }}
                checked={this.state.filter.formats.find(ff => ff === f.format) !== undefined}></Toggle>
        );

        return (
            <div>
                {renderedFormats}
            </div>
        )
    }

    /**
     * Toggles the format selected state
     * @param format Format
     * @param checked true if the format is checked, else false
     */
    private toggleFormat(format: MyDownloadsFilterEntryType, checked: boolean | undefined) {
        let filter = { ...this.state.filter };
        if (checked) {
            filter.formats.push(format);
        } else {
            filter.formats = filter.formats.filter(f => f !== format);
        }
        this.updateFilter(filter);
    }

    /**
     * Renders the selectable magazins
     * @returns Rendered selectable magazins
     */
    private renderSelectableMagazins(): React.ReactNode {
        let renderedMagazins = this.state.filterEntries.filter(f => f.entryType === MyDownloadsFilterEntryType.Magazin).map(m =>
            <Checkbox styles={{ root: { fontSize: FontSizes.medium }, text: FontSizes.medium }} key={m.productNumber} label={m.productName}
                onChange={(ev, checked) => { this.toggleMagazin(m, checked); }}
                checked={this.state.filter.filterEntries.find(fm => fm.productNumber === m.productNumber) !== undefined}></Checkbox>
        );

        return (
            <div>
                <Stack tokens={{ childrenGap: 5 }}>
                    {renderedMagazins}
                </Stack>
            </div>
        )
    }

    /**
     * Toggles the magazin selected state
     * @param magazin Magazin
     * @param checked true if the magazin is checked, else false
     */
    private toggleMagazin(magazin: MyDownloadsFilterEntry, checked: boolean | undefined) {
        let filter = { ...this.state.filter };
        if (checked) {
            filter.filterEntries.push(magazin);
        } else {
            filter.filterEntries = filter.filterEntries.filter(m => m.productNumber !== magazin.productNumber);
        }
        this.updateFilter(filter);
    }

    /**
     * Updates the filter
     * @param filter New filter
     */
    private updateFilter = (filter: MyDownloadsFilter): void => {
        this.setState({
            filter: filter
        }, () => {
            this.debouncedPropagateFilterToParent();
        });
    }

    /**
     * Propagates the filter to the parent componetn
     */
    private propagateFilterToParent = (): void => {
        this.props.onFilterChanged(this.state.filter);
    }
}

export default FilterPanel; 