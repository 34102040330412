import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';
import { ColorClassNames, FontClassNames, FontWeights, Stack, PrimaryButton, IColumn, SelectionMode, MessageBar, MessageBarType, IconButton, ShimmeredDetailsList, TextField, FontSizes, DefaultButton, IStackTokens } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';

// Components
import TranslatedComponent from '../../localization/TranslatedComponent';
import UserEditCreatePanel from './components/userEditCreatePanel/UserEditCreatePanel';
import UserProductPanel from './components/userProductPanel/UserProductPanel';
import UserSubscriptionPanel from './components/userSubscriptionsPanel/UserSubscriptionsPanel';
import UserImportPanel from './components/userImportPanel/UserImportPanel';
import UserProductImportPanel from './components/userProductImportPanel/UserProductImportPanel';
import UserDeleteDialog from './components/userDeleteDialog/UserDeleteDialog';
import styles from './Administration.module.css';

// Services
import AdminFactoryService from './../../services/admin/AdminFactory.service';

// Dto
import IAdministrationProps from './dto/IAdministrationProps';
import AdministrationState from './dto/AdministrationState';
import { Redirect } from 'react-router-dom';
import debounce from '../../util/Debounce';
import UserEntry from '../../dto/admin/UserEntry';

/**
 * Administration Component
 */
class Administration extends TranslatedComponent<IAdministrationProps, AdministrationState> {
    /**
     * Page size of the users
     */
    private readonly userPageSize: number = 25;

    /**
     * Debounced user load function
     */
    private _loadUserDataDebounced: () => void;

    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<IAdministrationProps>) {
        super(props);

        this._loadUserDataDebounced = debounce(this.loadUserData, this, 400, false);

        this.state = new AdministrationState();
    }

    /**
     * Loads the user data on load of the page
     */
    public componentDidMount() {
        this.loadUserData();
    }

    /**
     * Renders the administration component
     * @param t Translate function
     * @returns Rendered component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        const userStackTokens: IStackTokens = { childrenGap: 10 };

        return (
            <div className={`${styles.administrationContainer} ${ColorClassNames.whiteBackground}`}>
                {this.renderUnauthorizedRedirect()}
                <h1 className={`${FontClassNames.xLargePlus} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("administration.header")}</h1>
                <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("administration.reportsHeader")}</h2>
                <Stack horizontal>
                    <Stack.Item>
                        <PrimaryButton onClick={this.onDownloadStatistics}>{t("administration.downloadsStatistics")}</PrimaryButton>
                    </Stack.Item>
                </Stack>
                <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("administration.usersHeader")}</h2>
                {this.renderUserErrorOccured(t)}
                <Stack horizontal tokens={userStackTokens}>
                    <Stack.Item>
                        <PrimaryButton onClick={this.onCreateUser}>{t("administration.createUser")}</PrimaryButton>
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton onClick={this.onImportUser}>{t("administration.importUsers")}</DefaultButton>
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton onClick={this.onImportUserProducts}>{t("administration.importUsersProducts")}</DefaultButton>
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton onClick={this.onExportUsers}>{t("administration.exportUsers")}</DefaultButton>
                    </Stack.Item>
                </Stack>
                <br/>
                <TextField placeholder={t("administration.userSearchLabel")} value={this.state.userSearchQuery} onChange={this.onUserSearchQueryChange}></TextField>
                <ShimmeredDetailsList 
                    items={this.state.userQueryResult.entries} 
                    columns={this.getUserColumns(t)}
                    enableShimmer={this.state.loadingUserData}
                    selectionMode={SelectionMode.none}
                    getKey={this.getUserRowKey}></ShimmeredDetailsList>
                <div className={`${styles.pageButton}`}>
                    <IconButton disabled={this.state.userPage <= 0 || this.state.loadingUserData} iconProps={{ iconName: "pageLeft" }} onClick={() => { this.changeUserDisplayPage(-1) }}></IconButton>
                    <IconButton disabled={!this.state.userQueryResult || this.state.userQueryResult.totalCount <= (this.state.userPage + 1) * this.userPageSize || this.state.loadingUserData} iconProps={{ iconName: "pageRight" }} onClick={() => { this.changeUserDisplayPage(1) }}></IconButton>
                </div>
                <UserEditCreatePanel showPanel={this.state.showUserEditCreatePanel} userToEdit={this.state.userToEdit} onSaveSuccess={this.onUserEditCreateSuccess} onClosePanel={this.onCloseUserEditCreatePanel}></UserEditCreatePanel>
                <UserProductPanel userToEdit={this.state.userToManageProducts} onSaveSuccess={this.onCloseProductPanel} onClosePanel={this.onCloseProductPanel}></UserProductPanel>
                <UserSubscriptionPanel userToEdit={this.state.userToManageSubscriptions} onClosePanel={this.onCloseSubscriptionsPanel}></UserSubscriptionPanel>
                <UserImportPanel showPanel={this.state.showImportPanel} onClosePanel={this.onCloseImportPanel} onSaveSuccess={this.onImportSuccess}></UserImportPanel>
                <UserProductImportPanel showPanel={this.state.showProductImportPanel} onClosePanel={this.onCloseImportUserProducts}></UserProductImportPanel>
                <UserDeleteDialog userToDelete={this.state.userToDelete} onCloseDialog={this.onCloseDeleteUser} onDeleteSuccess={this.onDeleteUserSuccess}></UserDeleteDialog>
            </div>
        );
    }

    /**
     * Renders a redirect if the current user is not logged in
     */
    private renderUnauthorizedRedirect = (): ReactNode => {
        if (this.props.currentUser && this.props.currentUser.isAuthenticated && this.props.currentUser.isAdmin) {
            return null;
        }

        return (
            <Redirect to={{
                pathname: "/login",
                state: { from: "/" }
            }}></Redirect>
        )
    }

    /**
     * Downloads a report for the download statistics
     */
    private onDownloadStatistics = (): void => {
        window.location.assign("/api/admin/downloadStatistics");
    }

    /**
     * Renders a message that an error occured in the user section
     * @param t Translate function
     */
    private renderUserErrorOccured(t: TFunction): ReactNode {
        if (!this.state.errorOccured) {
            return null;
        }

        return (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.removeUserErrorBanner} dismissButtonAriaLabel={t("general.close")}>
                {t("general.errorOccured")}
            </MessageBar>
        )
    }
    
    /**
     * Removes the user error banner
     */
    private removeUserErrorBanner = (): void => {
        this.setState({
            errorOccured: false
        })
    }

    /**
     * Gets called if the query text was changed
     * @param newVal New query text
     */
    private onUserSearchQueryChange = (_ev:React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newVal: string | undefined): void => {
        this.setState({
            userSearchQuery: newVal ? newVal : ""
        }, () => {
            this._loadUserDataDebounced();
        });
    }

    /**
     * Returns the user columns
     * @param t Translate function
     * @returns User Columns
     */
    private getUserColumns = (t: TFunction): IColumn[] => {
        return [
            { 
                key: 'name', 
                name: t("administration.userNameColumn"), 
                fieldName: 'name', 
                minWidth: 250, 
                maxWidth: 350, 
                isResizable: true 
            },
            { 
                key: 'email', 
                name: t("administration.userEmailColumn"), 
                fieldName: 'email', 
                minWidth: 250, 
                maxWidth: 350, 
                isResizable: true,
                onRender: (u) => this.renderEmailColumn(u)
            },
            { 
                key: 'sessionCount', 
                name: t("administration.userSessionCountColumn"), 
                fieldName: 'sessionCount', 
                minWidth: 150, 
                maxWidth: 200, 
                isResizable: true 
            },
            { 
                key: 'ipAuth', 
                name: t("administration.userIpAuthColumn"), 
                fieldName: 'ipAuth', 
                minWidth: 150, 
                maxWidth: 250, 
                isResizable: true
            },
            { 
                key: 'editColumn', 
                name: '', 
                fieldName: '', 
                minWidth: 130, 
                maxWidth: 130, 
                isResizable: false,
                onRender: (u) => this.renderEditColumn(t, u)
            }
        ];
    }

    /**
     * Returns the key for a user row
     * @param item Row item
     * @returns Row key
     */
    private getUserRowKey = (item: UserEntry, index?: number): string => {
        if(!item) {
            return index ? index.toString() : "";
        }
        return item.id;
    }

    /**
     * Renders the username column
     * @param userEntry User entry
     * @returns Username column
     */
    private renderEmailColumn = (userEntry: UserEntry): JSX.Element | string => {
        let content = userEntry.email;
        if(userEntry.userName && userEntry.userName !== userEntry.email) {
            content += " ("+ userEntry.userName + ")";
        }

        return (
            <span title={content}>
                {content}
            </span>
        )
    }

    /**
     * Renders the edit column
     * @param t Translate function
     * @param userEntry User entry
     * @returns Edit column
     */
    private renderEditColumn = (t: TFunction, userEntry: UserEntry): JSX.Element | string => {
        return (
            <span>
                <IconButton styles={{icon:{fontSize:FontSizes.small}}} iconProps={{ iconName: "Edit" }} onClick={() => { this.openUserEdit(userEntry) }} title={t("general.edit")} ariaLabel={t("general.edit")}></IconButton>
                <IconButton styles={{icon:{fontSize:FontSizes.small}}} iconProps={{ iconName: "PaymentCard" }} onClick={() => { this.openManageProductPanel(userEntry) }} title={t("administration.manageProducts")} ariaLabel={t("administration.manageProducts")}></IconButton>
                <IconButton styles={{icon:{fontSize:FontSizes.small}}} iconProps={{ iconName: "Permissions" }} onClick={() => { this.openManageSubscriptionsPanel(userEntry) }} title={t("administration.manageSubscriptions")} ariaLabel={t("administration.manageSubscriptions")}></IconButton>
                <IconButton styles={{icon:{fontSize:FontSizes.small}}} iconProps={{ iconName: "LineChart" }} onClick={() => { this.downloadUserStatistics(userEntry) }} title={t("administration.downloadUserStatistics")} ariaLabel={t("administration.downloadUserStatistics")}></IconButton>
                <IconButton styles={{icon:{fontSize:FontSizes.small}}} iconProps={{ iconName: "Delete" }} onClick={() => { this.onDeleteUser(userEntry) }} title={t("administration.deleteUser")} ariaLabel={t("administration.deleteUser")}></IconButton>
            </span>
        )
    }

    /**
     * Gets called if the user wants to create a new user
     */
    private onCreateUser = (): void => {
        this.setState({
            showUserEditCreatePanel: true,
            userToEdit: null
        })
    }

    /**
     * Opens the edit for a user
     * @param user User to edit
     */
    private openUserEdit = (user: UserEntry): void => {
        this.setState({
            showUserEditCreatePanel: true,
            userToEdit: user
        })
    }

    /**
     * Gets called on close of the user edit create panel
     */
    private onCloseUserEditCreatePanel = (): void => {
        this.setState({
            showUserEditCreatePanel: false
        });
    }

    /**
     * Gets called if a user was created or edited successfully
     */
    private onUserEditCreateSuccess = (): void => {
        this.setState({
            showUserEditCreatePanel: false,
            userToEdit: null
        }, () => {
            this.loadUserData();
        });
    }

    /**
     * Opens the panel to manage the products for a user
     * @param user User to manage the products for
     */
    private openManageProductPanel = (user: UserEntry): void => {
        this.setState({
            userToManageProducts: user
        });
    }

    /**
     * Closes the product panel
     */
    private onCloseProductPanel = (): void => {
        this.setState({
            userToManageProducts: null
        });
    }

    /**
     * Opens the panel to manage the subscriptions for a user
     * @param user User to manage the subscriptions for
     */
    private openManageSubscriptionsPanel = (user: UserEntry): void => {
        this.setState({
            userToManageSubscriptions: user
        });
    }

    /**
     * Closes the user subscriptions panel
     */
    private onCloseSubscriptionsPanel = () => {
        this.setState({
            userToManageSubscriptions: null
        });
    }

    /**
     * Opens the user import panel
     */
    private onImportUser = (): void => {
        this.setState({
            showImportPanel: true
        });
    }

    /**
     * Gets called if a user import was successful
     */
    private onImportSuccess = (): void => {
        this.loadUserData();
    }
    
    /**
     * Closes the user import panel
     */
    private onCloseImportPanel = (): void => {
        this.setState({
            showImportPanel: false
        });
    }

    /**
     * Opens the panel to import user products
     */
    private onImportUserProducts = () => {
        this.setState({
            showProductImportPanel: true
        });
    }

    /**
     * Close the panel to import user products
     */
    private onCloseImportUserProducts = () => {
        this.setState({
            showProductImportPanel: false
        });
    }

    /**
     * Triggers the download of users
     */
    private onExportUsers = () => {
        window.location.assign("/api/admin/exportUsers");
    }

    /**
     * Opens the panel to manage the products for a user
     * @param user User to manage the products for
     */
    private downloadUserStatistics = (user: UserEntry): void => {
        window.location.assign("/api/admin/downloadUserStatistics?userId=" + user.id);
    }
    
    /**
     * Gets called if a user must be deleted
     * @param user User to delete 
     */
    private onDeleteUser = (user: UserEntry): void => {
        this.setState({
            userToDelete: user
        });
    }

    /**
     * Gets called if the user deletion was successfull
     */
    private onDeleteUserSuccess = (): void => {
        this.setState({
            userToDelete: null
        }, () => {
            if(this.state.userQueryResult && this.state.userQueryResult.entries.length <= 0)
            {
                this.changeUserDisplayPage(this.state.userPage - 1);
            }
            else
            {
                this.loadUserData();
            }
        })
    }

    /**
     * Gets called if the user deletion must be closed
     */
    private onCloseDeleteUser = (): void => {
        this.setState({
            userToDelete: null
        });
    }

    /**
     * Changes the user display page
     * @param direction Direction of the paging
     */
    private changeUserDisplayPage = (direction: number) => {
        let newPage = this.state.userPage + direction;
        if(newPage < 0 || (this.state.userQueryResult && newPage * this.userPageSize > this.state.userQueryResult.totalCount))
        {
            return;
        }

        this.setState({
            userPage: newPage,
        }, () => {
            this.loadUserData();
        });
    }

    /**
     * Loads a page of user data 
     */
    private loadUserData = async (): Promise<void> => {
        this.setState({
            loadingUserData: true,
            errorOccured: false
        });

        try {
            let userData = await AdminFactoryService.getAdminService().getUsers(this.state.userPage, this.userPageSize, this.state.userSearchQuery);
            this.setState({
                loadingUserData: false,
                userQueryResult: userData
            });
        }
        catch (e) {
            this.setState({
                loadingUserData: false,
                errorOccured: true
            });
        }
    }
}

export default Administration; 