/**
 * Document entry
 */
class DocumentEntry {
    /**
     * Id of the entry
     */
    public id: string;

    /**
     * Title of the entry
     */
    public title: string;

    /**
     * Edition
     */
    public edition: string;

    /**
     * Publication date
     */
    public publicationDate: Date;

    /**
     * Page count
     */
    public pageCount: number;

    /**
     * Tags
     */
    public tags: string;

    /**
     * Constructor
     */
    public constructor() {
        this.id = "";
        this.title = "";
        this.edition = "";
        this.publicationDate = new Date();
        this.pageCount = 0;
        this.tags = "";
    }
}

export default DocumentEntry;