/**
 * State class for the global loading indicator
 */
class GlobalLoadingIndicatorState {
    /**
     * Amount of loading events in progress
     */
    public loadingRefCount: number;

    /**
     * Constructor
     */
    public constructor() {
        this.loadingRefCount = 0;
    }
}

export default GlobalLoadingIndicatorState;