import MyDownloadsFilterEntryType from "../../../../../dto/myDownloads/MyDownloadsFilterEntryType";

/**
 * Available filter formats
 */
class AvailableFilterFormat {
    /**
     * Langkey for the display
     */
    public langKey: string;

    /**
     * Format
     */
    public format: MyDownloadsFilterEntryType;

    /**
     * Constructor
     * @param langKey Langkey for the display
     * @param format Filter format
     */
    public constructor(langKey: string, format: MyDownloadsFilterEntryType) {
        this.langKey = langKey;
        this.format = format;
    }
}

export default AvailableFilterFormat;