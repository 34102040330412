// Util
import isDevMode from './../../util/IsDevMode';

// Services
import IAccountService from './IAccount.service';
import MockAccountService from './MockAccount.service';
import WebAccountService from './WebAccount.service';

/**
 * Factory class to provide account services
 */
export default class AccountFactory {
    /**
     * Returns the account service
     * @returns Account service
     */
    public static getAccountService(): IAccountService {
        if (isDevMode()) {
            return new MockAccountService();
        }

        return new WebAccountService();
    }
}