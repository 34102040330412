import React, { ReactNode } from 'react';

// Library
import { TFunction, i18n } from 'i18next';
import { Translation } from 'react-i18next';

/**
 * Translated Base Component
 */
abstract class TranslatedComponent<P,S> extends React.Component<P, S> {
    /**
     * Renders the component
     */
    public render() {
        return (
            <Translation>
                {
                    (t, { i18n }) => this.renderWithTranslation(t, i18n)
                }
            </Translation>
        );
    }   

    /**
     * Renders the component with translation
     * @param t Translate object
     * @param i18n i18n object
     */
    protected abstract renderWithTranslation(t: TFunction, i18n: i18n): ReactNode;
}

export default TranslatedComponent; 