import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';

// Components
import TranslatedComponent from '../../localization/TranslatedComponent';
import styles from './DataProtection.module.css';
import { FontClassNames, ColorClassNames, FontWeights, Link } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';
import UncryptMailTo from './../../util/UncryptMailTo';

/**
 * Data Protection Component
 */
class DataProtection extends TranslatedComponent<{}, {}> {

    /**
     * Renders the home component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <div className={styles.dataProtectionContainer}>
                <h1 className={`${FontClassNames.xLargePlus} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("dataProtection.header")}</h1>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("dataProtection.weValueYourPrivacy") }</h2>
                    <p>
                        { t("dataProtection.weValueYourPrivacyContent1") }
                    </p>
                    <p>
                        { t("dataProtection.weValueYourPrivacyContent2") }
                    </p>
                </p>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("dataProtection.personalDataHeader") }</h2>
                    <p>
                        { t("dataProtection.personalDataHeaderContent") }
                    </p>
                </p>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("dataProtection.dataProtectionHeader") }</h2>
                    <ul>
                        <li>{ t("dataProtection.dataProtectionHeaderData") }</li>
                        <li>{ t("dataProtection.dataProtectionHeaderCookies") }</li>
                        <li>{ t("dataProtection.dataProtectionHeaderPersonalDataUsage") }</li>
                        <li>{ t("dataProtection.dataProtectionHeaderEmailContact") }</li>
                        <li>{ t("dataProtection.dataProtectionHeaderForNewsletter") }</li>
                        <li>{ t("dataProtection.dataProtectionHeaderCorrectionDeletion") }</li>
                        <li>{ t("dataProtection.dataProtectionHeaderLinks") }</li>
                        <li>{ t("dataProtection.dataProtectionHeaderSafety") }</li>
                        <li>{ t("dataProtection.dataProtectionHeaderGoogle") }</li>
                        <li>{ t("dataProtection.dataProtectionHeaderAddThis") }</li>
                    </ul>
                </p>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("dataProtection.dataProtectionHeaderData") }</h2>
                    <p>
                        { t("dataProtection.dataProtectionDataContent1") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionDataContent2") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionDataContent3") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionDataContent4") }
                    </p>
                </p>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("dataProtection.dataProtectionHeaderCookies") }</h2>
                    <p>
                        { t("dataProtection.dataProtectionCookiesContent") }
                    </p>
                </p>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("dataProtection.dataProtectionHeaderPersonalDataUsage") }</h2>
                    <p>
                        { t("dataProtection.dataProtectionPersonalDataUsageContent1") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionPersonalDataUsageContent2") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionPersonalDataUsageContent3") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionPersonalDataUsageContent4") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionPersonalDataUsageContent5") }
                    </p>
                </p>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("dataProtection.dataProtectionHeaderTransferOfData") }</h2>
                    <p>
                        { t("dataProtection.dataProtectionHeaderTransferOfData1") }
                    </p>
                </p>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("dataProtection.dataProtectionHeaderEmailContact") }</h2>
                    <p>
                        { t("dataProtection.dataProtectionEmailContactContent1") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionEmailContactContent2") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionEmailContactContent3") }
                    </p>
                </p>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("dataProtection.dataProtectionHeaderForNewsletter") }</h2>
                    <p>
                        { t("dataProtection.dataProtectionForNewsletterPreLink") }<Link onClick={() => UncryptMailTo("nbjmup;jogpAwvmlbo.wfsmbh/ef")}>info(at)vulkan-verlag.de</Link>{ t("dataProtection.dataProtectionForNewsletterPostLink") }
                    </p>
                </p>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("dataProtection.dataProtectionHeaderCorrectionDeletion") }</h2>
                    <p>
                        { t("dataProtection.dataProtectionCorrectionDeletionContent1") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionCorrectionDeletionContent2") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionCorrectionDeletionContent3") }
                    </p>
                </p>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("dataProtection.dataProtectionHeaderLinks") }</h2>
                    <p>
                        { t("dataProtection.dataProtectionLinksContent") }
                    </p>
                </p>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("dataProtection.dataProtectionHeaderCorrectionDeletion") }</h2>
                    <p>
                        { t("dataProtection.dataProtectionCorrectionDeletionContent1") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionCorrectionDeletionContent2") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionCorrectionDeletionContent3") }
                    </p>
                </p>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("dataProtection.dataProtectionHeaderGoogle") }</h2>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("dataProtection.dataProtectionHeaderGoogleAnalytics") }</h2>
                    <p>
                        { t("dataProtection.dataProtectionHeaderGoogleAnalyticsContent1") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionHeaderGoogleAnalyticsContent2") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionHeaderGoogleAnalyticsContent3") }
                    </p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("dataProtection.dataProtectionHeaderGoogleAdSense") }</h2>
                    <p>
                        { t("dataProtection.dataProtectionHeaderGoogleAdSenseContent1") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionHeaderGoogleAdSenseContent2") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionHeaderGoogleAdSenseContent3") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionHeaderGoogleAdSenseContent4") }
                    </p>
                </p>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("dataProtection.dataProtectionHeaderAddThis") }</h2>
                    <p>
                        { t("dataProtection.dataProtectionHeaderAddThisContent1") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionHeaderAddThisContent2") }
                    </p>
                </p>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("dataProtection.dataProtectionHeaderAddThis") }</h2>
                    <p>
                        { t("dataProtection.dataProtectionHeaderComplainsEmail") }<Link onClick={() => UncryptMailTo("nbjmup;ebufotdivu{Aebub.sppn/ef")}>datenschutz(at)data-room.de</Link>
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionHeaderComplainsContent1") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionHeaderComplainsContent2") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionHeaderComplainsAddress1") }<br/>
                        { t("dataProtection.dataProtectionHeaderComplainsAddress2") }<br/>
                        { t("dataProtection.dataProtectionHeaderComplainsAddress3") }<br/>
                        { t("dataProtection.dataProtectionHeaderComplainsAddress4") }<br/>
                        { t("dataProtection.dataProtectionHeaderComplainsAddress5") }<br/>
                        { t("dataProtection.dataProtectionHeaderComplainsEmail") }<Link onClick={() => UncryptMailTo("nbjmup;qptutufmmfAmej/osx/ef")}>poststelle(at)ldi.nrw.de</Link>
                    </p>
                </p>
                <p>
                    <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{ t("dataProtection.dataProtectionMisc") }</h2>
                    <p>
                        { t("dataProtection.dataProtectionMiscLine1") }<br/>
                        { t("dataProtection.dataProtectionMiscLine2") }<Link onClick={() => UncryptMailTo("nbjmup;jogpAwvmlbo.wfsmbh/ef")}>info(at)vulkan-verlag.de</Link><br/>
                        { t("dataProtection.dataProtectionMiscLine3") }
                    </p>
                    <p>
                        { t("dataProtection.dataProtectionMiscLine4") }
                    </p>
                    <p>
                        <Link href="/static/Datenschutzerklärung_Online_06_19.pdf">{ t("dataProtection.dataProtectionMiscDownloadLink") }</Link>
                    </p>
                </p>
            </div>
        );
    }
}

export default DataProtection; 