import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';
import { Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, DefaultButton, Label, ColorClassNames, FontClassNames, FontWeights, ProgressIndicator, Stack, ActivityItem, Icon } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';
import papaparse from 'papaparse';

// Components
import TranslatedComponent from '../../../../localization/TranslatedComponent';
import styles from './UserImportPanel.module.css';

// Services
import AdminFactoryService from '../../../../services/admin/AdminFactory.service';

// Dto
import IUserImportPanelProps from './dto/IUserImportPanelProps';
import UserImportPanelState from './dto/UserImportPanelState';
import CsvUserData from './dto/CsvUserData';
import UserImportLogEntry from './dto/UserImportLogEntry';
import UserEntry from '../../../../dto/admin/UserEntry';
import UserCreateEditRequest from '../../../../dto/admin/UserCreateEditRequest';

// Util
import formatDate from '../../../../util/FormatDate';
import isValidEmail from '../../../../util/IsValidEmail';

/**
 * User import panel Component
 */
class UserImportPanel extends TranslatedComponent<IUserImportPanelProps, UserImportPanelState> {
    /**
     * Progress that is used before starting the actual user import
     */
    private readonly preUserImportProgress: number = 5;

    /**
     * Import File input
     */
    private importFileInput: HTMLInputElement | null = null;

    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<IUserImportPanelProps>) {
        super(props);

        this.state = new UserImportPanelState();
    }

    /**
     * Renders the administration component
     * @param t Translate function
     * @returns Rendered component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <Panel
                isOpen={this.props.showPanel}
                onDismiss={this.onClosePanel}
                closeButtonAriaLabel={t("general.close")}
                headerText={t("userImportPanel.header")}
                onRenderFooter={() => this.renderPanelFooter(t)}
                isFooterAtBottom={true}
                type={PanelType.medium}>

                <Label>{t("userImportPanel.userFile")}</Label>
                <input type="file" accept=".csv" ref={(r) => this.importFileInput = r}></input>
                {this.state.showErrorMessage && <Label className={ColorClassNames.red}>{t("general.mandatoryField")}</Label>}

                {this.renderImportProgress(t)}
            </Panel>
        );
    }

    /**
     * Renders the panel footer
     * @param t Translate function
     */
    private renderPanelFooter(t: TFunction): JSX.Element | null {
        return (
            <div className={styles.footerContainer}>
                <PrimaryButton disabled={this.state.isLoading || this.state.isImportRunning} onClick={this.onImportUser} className={styles.buttonMargin}>
                    {(this.state.isLoading || this.state.isImportRunning) && <Spinner className={styles.saveButtonSpinner} size={SpinnerSize.xSmall}></Spinner>}
                    {t("userImportPanel.import")}
                </PrimaryButton>
                <DefaultButton disabled={this.state.isLoading || this.state.isImportRunning} onClick={this.onClosePanel}>{t("general.close")}</DefaultButton>
            </div>
        );
    }

    /**
     * Renders the import progress
     * @param t Translate function
     * @returns Rendered import progress
     */
    private renderImportProgress(t: TFunction): React.ReactNode | null {
        if (!this.state.showImportLog) {
            return null;
        }

        let importLog = this.state.importLog.map((il) => {
            let colorClassName = "";
            let textKey = "userImportPanel.userWasCreated";
            let icon = "UserFollowed";
            if(!il.wasCreate) {
                textKey = "userImportPanel.userWasUpdated";
                icon = "UserSync";
            }

            if(il.errorOccured) {
                textKey = "userImportPanel.userCouldNotBeImported";
                icon = "StatusErrorFull";
                colorClassName = ColorClassNames.red;
            }

            let activityItem = {
                activityDescription: [
                    <div key={0} className={colorClassName}>{il.userMail} - {t(textKey)}</div>,
                    il.errorOccured ? <div key={1} className={colorClassName}>{t(`general.error.${il.errorMessage}`)}</div> : null
                ],
                activityIcon: <Icon iconName={icon} className={colorClassName}/>,
                timeStamp: formatDate(t, il.createTime, true),
            }

            return <Stack.Item key={il.id}>
                <ActivityItem className={styles.userImportLogEntry} {...activityItem} />
            </Stack.Item>;
        });

        return (
            <div>
                <h2 className={`${FontClassNames.large} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("userImportPanel.importProgress")}</h2>
                <ProgressIndicator percentComplete={this.state.importProgress}></ProgressIndicator>
                <Stack reversed className={styles.userImportLog}>
                    {importLog}
                </Stack>
            </div>
        )
    }

    /**
     * Imports a user
     */
    private onImportUser = async (): Promise<void> => {
        if (!this.importFileInput) {
            return;
        }

        if (!this.importFileInput.files || this.importFileInput.files.length === 0) {
            this.setState({
                showErrorMessage: true
            });
            return;
        }

        this.setState({
            showErrorMessage: false,
            showImportLog: true,
            isImportRunning: true,
            importProgress: 0,
            importLog: []
        });

        try {
            let fileContent = await this.readFileContent(this.importFileInput.files[0]);
            let parsedFile = papaparse.parse<CsvUserData>(fileContent, {
                header: true
            });
            this.setImportProgress(1, null);

            let allExistingUsers = await AdminFactoryService.getAdminService().getUsers(0, 100000, "");
            this.setImportProgress(this.preUserImportProgress, null);

            await this.importUsers(parsedFile.data, allExistingUsers.entries);

            this.setState({
                isImportRunning: false,
                importProgress: 100
            });
            this.props.onSaveSuccess();
        }
        catch (e) {
            this.setState({
                isImportRunning: false,
                errorOccured: true
            })
        }
    }

    /**
     * Imports a list of users
     * @param userData User data to import
     * @param allExistingUsers All existing users
     */
    private async importUsers(userData: CsvUserData[], allExistingUsers: UserEntry[]) {
        let validUserSalutations: any = {
            "herr": true,
            "frau": true,
            "divers": true
        }

        let userLookup: { [key: string]: string } = {};
        for (let curUser of allExistingUsers) {
            userLookup[curUser.email.toLowerCase()] = curUser.id;
        }
        let curProgress = this.preUserImportProgress;
        let progressPerUser = (100 - this.preUserImportProgress) / userData.length;
        let curUserIndex = 0;
        for (let curUser of userData) {
            if (!curUser.EMail) {
                curProgress += progressPerUser;
                this.setImportProgress(curProgress, null);
                continue;
            }

            let userId = userLookup[curUser.EMail.toLowerCase()];
            let errorOccured = false;
            let errorMessage = "";

            try {
                let userCreateEditRequest: UserCreateEditRequest = new UserCreateEditRequest();
                userCreateEditRequest.email = curUser.EMail;
                userCreateEditRequest.password = curUser.Passwort;
                userCreateEditRequest.salutation = curUser.Anrede;
                userCreateEditRequest.firstname = curUser.Vorname;
                userCreateEditRequest.lastname = curUser.Nachname;
                userCreateEditRequest.companyName = curUser.FirmenName;
                userCreateEditRequest.companyStreet = curUser.FirmenStrasse;
                userCreateEditRequest.companyPostalCode = curUser.FirmenPostleitzahl;
                userCreateEditRequest.companyCity = curUser.FirmenStadt;
                userCreateEditRequest.companyCountry = curUser.FirmenLand;
                userCreateEditRequest.ipAddress = curUser.IPLogin;
                userCreateEditRequest.usernameOverwrite = curUser.AlterBenutzername;

                if(isValidEmail(userCreateEditRequest.email) && userCreateEditRequest.firstname && userCreateEditRequest.lastname && userCreateEditRequest.salutation && validUserSalutations[userCreateEditRequest.salutation.toLowerCase()]) {
                    if (!userId) {
                        if(userCreateEditRequest.password) {
                            await AdminFactoryService.getAdminService().createUser(userCreateEditRequest);
                        } else {
                            errorOccured = true;
                            errorMessage = "passwordMissing";
                        }
                    } else {
                        await AdminFactoryService.getAdminService().updateUser(userId, userCreateEditRequest);
                    }
                } else {
                    errorOccured = true;
                    errorMessage = "invalidUserData";
                }
            }
            catch (e) {
                console.error(e);
                errorOccured = true;

                errorMessage = "generic";
                if (e.response && e.response.status < 500 && e.response.data && e.response.data.message) {
                    errorMessage = e.response.data.message;
                }
            }

            let userLogEntry = new UserImportLogEntry(curUserIndex, curUser.EMail, !userId, errorOccured, errorMessage);
            ++curUserIndex;

            curProgress += progressPerUser;
            this.setImportProgress(curProgress, userLogEntry);
        }
    }

    /**
     * Sets the import progress
     * @param progress Progress in percent (0 - 100)
     * @param userLogEntry User import log entry
     */
    private setImportProgress(progress: number, userLogEntry: UserImportLogEntry | null): void {
        let importLog = this.state.importLog;
        if (userLogEntry) {
            importLog.unshift(userLogEntry);    // Push to beginning to keep it at bottom since stack is reversed for scroll
        }

        this.setState({
            importProgress: progress / 100,
            importLog: importLog
        });
    }

    /**
     * Reads the file content
     * @param file File to read
     * @returns File content
     */
    private readFileContent(file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = function (evt) {
                resolve(evt.target?.result?.toString());
            }
            reader.onerror = function () {
                reject();
            }
        });
    }

    /**
     * Gets called if the pannel must be closed
     */
    private onClosePanel = (): void => {
        this.setState({
            isImportRunning: false,
            showImportLog: false,
            isLoading: false,
            importProgress: 0,
            importLog: []
        })
        this.props.onClosePanel();
    }
}

export default UserImportPanel; 