import React, { ReactNode, FormEvent } from 'react';

// Library
import { ColorClassNames, Dropdown, TextField, FontClassNames, IDropdownOption, FontWeights } from 'office-ui-fabric-react';
import { TFunction } from 'i18next';

// Components
import TranslatedComponent from '../../../localization/TranslatedComponent';

// Dto
import IUserDataFormProps from './dto/IUserDataFormProps';
import UserDataFormState from './dto/UserDataFormState';
import UserData from '../../../dto/account/UserData';


// Util
import isValidEmail from './../../../util/IsValidEmail';

/**
 * User Data Component
 */
class UserDataForm extends TranslatedComponent<IUserDataFormProps, UserDataFormState> {
    /**
     * Empty ip address
     */
    private readonly emptyIpAddress: string = "___.___.___.___";

    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<IUserDataFormProps>) {
        super(props);

        let state = new UserDataFormState();
        this.state = state;
    }

    /**
     * Gets called once the component is mounted, copies pre existing user data
     */
    public componentDidUpdate(prevProps: IUserDataFormProps) {
        if(this.props.existingUserData !== null && prevProps.existingUserData === null) {
            let newUserData = new UserData();
            newUserData.assignFromUserData(this.props.existingUserData);

            this.setState({
                userData: newUserData
            })
        }
    }

    /**
     * Renders the header
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        const options: IDropdownOption[] = [
            { key: 'herr', text: t("userDataForm.man") },
            { key: 'frau', text: t("userDataForm.women") },
            { key: 'divers', text: t("userDataForm.diverse") }
        ];

        return (
            <div>
                <Dropdown
                    placeholder={t("general.pleaseChoose")}
                    label={t("userDataForm.salutation")}
                    options={options}
                    required={true}
                    selectedKey={this.getSalutationSelectedKey(options)}
                    onChange={(_ev, val) => { this.onUserFieldChanged((r, v) => { r.salutation = v }, val ? val.text : ""); }} 
                    errorMessage={this.getMandatoryErrorMessage(t, this.state.userData.salutation)}
                />

                <TextField errorMessage={this.getMandatoryErrorMessage(t, this.state.userData.firstname)} onChange={(_ev, val) => { this.onUserFieldChanged((r, v) => { r.firstname = v }, val); }} value={this.state.userData.firstname} label={t("userDataForm.firstname")} required={true}></TextField>
                <TextField errorMessage={this.getMandatoryErrorMessage(t, this.state.userData.lastname)} onChange={(_ev, val) => { this.onUserFieldChanged((r, v) => { r.lastname = v }, val); }} value={this.state.userData.lastname} label={t("userDataForm.lastname")} required={true}></TextField>

                {!this.props.hideLoginFields && <TextField errorMessage={this.getMandatoryErrorMessage(t, this.state.userData.email) || this.getEmailErrorMessage(t)} onChange={(_ev, val) => { this.onUserFieldChanged((r, v) => { r.email = v }, val); }} value={this.state.userData.email} label={t("userDataForm.email")} required={true}></TextField>}
                {!this.props.hideLoginFields && <TextField errorMessage={this.getMandatoryErrorMessage(t, this.state.userData.password)} type="password" onChange={(_ev, val) => { this.onUserFieldChanged((r, v) => { r.password = v }, val); }} value={this.state.userData.password} label={t("userDataForm.password")} required={this.props.existingUserData === null} description={this.props.existingUserData !== null ? t("userDataForm.leavePasswordBlankToKeepOld") : undefined}></TextField>}
                {!this.props.hideLoginFields && <TextField errorMessage={this.getPasswordRepeatErrorMessage(t)} type="password" onChange={this.setPasswordRepeat} value={this.state.repeatPassword} label={t("userDataForm.repeatPassword")} required={this.props.existingUserData === null}></TextField>}
                {!this.props.hideUsername && <TextField onChange={(_ev, val) => { this.onUserFieldChanged((r, v) => { r.username = v }, val); }} value={this.state.userData.username} label={t("userDataForm.username")} required={false} description={t("userDataForm.onEmptyUsernameEmailWillBeUsed")}></TextField>}

                <h2 className={`${FontClassNames.xLarge} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("userDataForm.companyData")}</h2>
                <TextField onChange={(_ev, val) => { this.onUserFieldChanged((r, v) => { r.companyName = v }, val); }} value={this.state.userData.companyName} label={t("userDataForm.companyName")}></TextField>
                <TextField onChange={(_ev, val) => { this.onUserFieldChanged((r, v) => { r.companyStreet = v }, val); }} value={this.state.userData.companyStreet} label={t("userDataForm.companyStreet")}></TextField>
                <TextField onChange={(_ev, val) => { this.onUserFieldChanged((r, v) => { r.companyPostalCode = v }, val); }} value={this.state.userData.companyPostalCode} label={t("userDataForm.companyPostalCode")}></TextField>
                <TextField onChange={(_ev, val) => { this.onUserFieldChanged((r, v) => { r.companyCity = v }, val); }} value={this.state.userData.companyCity} label={t("userDataForm.companyCity")}></TextField>
                <TextField onChange={(_ev, val) => { this.onUserFieldChanged((r, v) => { r.companyCountry = v }, val); }} value={this.state.userData.companyCountry} label={t("userDataForm.companyCountry")}></TextField>
                {!this.props.hideMiscFields && <h2 className={`${FontClassNames.xLarge} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("userDataForm.miscHeader")}</h2>}
                {!this.props.hideMiscFields && <TextField onChange={(_ev, val) => { this.onUserFieldChanged((r, v) => { r.maxSessionCount = !isNaN(parseInt(v)) ? parseInt(v) : null }, val); }} value={this.state.userData.maxSessionCount ? this.state.userData.maxSessionCount.toString() : ""} label={t("userDataForm.maxSessionCount")}></TextField>}
                {!this.props.hideIpAuthFields && <h2 className={`${FontClassNames.xLarge} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("userDataForm.ipAuthHeader")}</h2>}
                {!this.props.hideIpAuthFields && <TextField onChange={(_ev, val) => { this.onUserFieldChanged((r, v) => { r.ipAddress = v }, val); }} value={this.state.userData.ipAddress} label={t("userDataForm.ipAddress")} errorMessage={this.getIpErrorMessage(t)}></TextField>}
            </div>
        );
    }

    /**
     * Returns the error message for a mandatory field
     * @param t Translate function
     * @param val Value of the fields
     * @returns Error message
     */
    private getMandatoryErrorMessage = (t: TFunction, val: string): string => {
        if (!this.state.showErrorMessage || val) {
            return "";
        }

        return t("general.mandatoryField");
    }

    /**
     * Returns the error for the password repeat
     * @param t Translate function
     * @returns Error message
     */
    private getPasswordRepeatErrorMessage(t: TFunction): string {
        if (!this.state.showErrorMessage || this.state.userData.password === this.state.repeatPassword) {
            return "";
        }

        return t("general.passwordsDoNotMatch");
    }

    /**
     * Returns an error message if an invalid email address is provided
     * @param t Translate function
     * @returns Error message
     */
    private getEmailErrorMessage(t: TFunction): string {
        if (!this.state.showErrorMessage || !this.state.userData.email) {
            return "";
        }

        return !isValidEmail(this.state.userData.email) ? t("general.invalidEmail") : "";
    }

    /**
     * Returns the IP error message
     * @param t Translate function
     * @returns IP error message
     */
    private getIpErrorMessage(t:TFunction): string | undefined {
        if(!this.state.showErrorMessage || !this.state.userData.ipAddress)
        {
            return "";
        }

        let isIpValid = this.validateIp(this.state.userData.ipAddress);
        if(isIpValid) {
            return "";
        }
        
        return t("userDataForm.enterValidIpAddress");
    }

    /**
     * Validates if an ip value is valid
     * @param ip Value to validate
     */
    private validateIp(ip: string): boolean {
        if(!ip) {
            return true;
        }

        let ipAddresses = ip.split(",");
        for(let ipAddress of ipAddresses) {
            let ipValueParts = ipAddress.split("-");
            if(ipValueParts.length > 2)
            {
                return false;
            }

            for(let ipValue of ipValueParts) {
                let ipAddressSplitted = ipValue.split(".");
                if(ipAddressSplitted.length !== 4)
                {
                    return false;
                }

                for(let curIpAddressPart of ipAddressSplitted) {
                    let ipPart = parseInt(curIpAddressPart);
                    if(isNaN(ipPart) || ipPart < 0 || ipPart > 255)
                    {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    /**
     * Handles the change of a user field value
     * @param fieldName Fieldname
     * @param val New value of the field
     */
    private onUserFieldChanged = (setValueCallback: (r: UserData, val: string) => void, val: string | undefined) => {
        if (!val) {
            val = "";
        }

        let userData = new UserData();
        userData.assignFromUserData(this.state.userData);
        setValueCallback(userData, val);

        this.setState({
            userData: userData
        }, () => {
            this.props.onUserDataChanged(this.state.userData);
        });
    }

    /**
     * Returns the selected saluation key
     * @param options Selected salutation key
     * @returns Selected salutation key
     */
    private getSalutationSelectedKey(options: IDropdownOption[]): string | null {
        let option = options.find(o => o.text === this.state.userData.salutation);
        if(!option) {
            return null;
        }

        return option.key.toString();
    }

    /**
     * Sets the password repeat value
     * @param ev Event
     * @param newValue New value 
     */
    private setPasswordRepeat = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
        if (!newValue) {
            newValue = "";
        }

        this.setState({
            repeatPassword: newValue
        });
    }

    /**
     * Validates if the form is valid
     * @returns true if the form is valid, else false
     */
    public validate = (): boolean => {
        if ((!this.props.hideLoginFields && (!this.props.existingUserData || this.state.userData.password) && this.state.userData.password !== this.state.repeatPassword) || (!this.props.hideLoginFields && (!this.state.userData.email || !isValidEmail(this.state.userData.email))) ||
            !this.state.userData.firstname || !this.state.userData.lastname || (!this.props.hideLoginFields && !this.props.existingUserData && !this.state.userData.password) || !this.state.userData.salutation ||
            (!this.props.hideIpAuthFields && !this.validateIp(this.state.userData.ipAddress))) {
            this.setState({
                showErrorMessage: true
            });
            return false;
        }
        
        this.setState({
            showErrorMessage: false
        });
        return true;
    }
}

export default UserDataForm; 