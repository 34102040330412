import ChangePasswordRequest from './../../../dto/account/ChangePasswordRequest';

/**
 * Change password page state class
 */
class ChangePasswordState {
    /**
     * Request to set the new password
     */
    public changePasswordRequest: ChangePasswordRequest;

    /**
     * Repeat of the new password
     */
    public repeatNewPassword: string;

    /**
     * true if error messages should be shown on empty
     */
    public showErrorMessage: boolean;

    /**
     * true if the page is loading
     */
    public isLoading: boolean;

    /**
     * Error message that was returned from the server when setting a new password
     */
    public changePasswordError: string;

    /**
     * true if the new password could be set
     */
    public passwordWasChanged: boolean;

    /**
     * Constructor
     */
    public constructor()
    {
        this.changePasswordRequest = new ChangePasswordRequest();
        this.repeatNewPassword = ""; 
        this.showErrorMessage = false;
        this.isLoading = false;
        this.changePasswordError = "";
        this.passwordWasChanged = false;
    }
}

export default ChangePasswordState;