import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';
import { Dialog, DialogFooter, PrimaryButton, DefaultButton, DialogType, Spinner, SpinnerSize, MessageBar, MessageBarType } from 'office-ui-fabric-react';

// Components
import TranslatedComponent from '../../../../localization/TranslatedComponent';
import styles from './UserDeleteDialog.module.css';

// Services
import AdminFactoryService from '../../../../services/admin/AdminFactory.service';

// Dto
import IUserDeleteDialogProps from './dto/IUserDeleteDialogProps';
import UserDeleteDialogState from './dto/UserDeleteDialogState';

/**
 * User delete dialog Component
 */
class UserDeleteDialog extends TranslatedComponent<IUserDeleteDialogProps, UserDeleteDialogState> {
    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<IUserDeleteDialogProps>) {
        super(props);

        this.state = new UserDeleteDialogState();
    }

    /**
     * Renders the administration component
     * @param t Translate function
     * @returns Rendered component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        let content = "";
        if(this.props.userToDelete) {
            content = t("userDeleteDialog.text");
            content = content.replace("{0}", this.props.userToDelete.name);
        }

        const dialogContentProps = {
            type: DialogType.normal,
            title: t("userDeleteDialog.header"),
            closeButtonAriaLabel: t("general.close")
        };
      
        const modalProps = {
            isBlocking: true
        };

        return (
            <Dialog
                hidden={this.props.userToDelete === null}
                onDismiss={this.onCloseDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}>
                {this.renderErrorOccured(t)}
                {content}
                <DialogFooter>
                    <PrimaryButton onClick={this.onDeleteUser} disabled={this.state.isLoading}>
                        {this.state.isLoading && <Spinner className={styles.saveButtonSpinner} size={SpinnerSize.xSmall}></Spinner>}
                        {t("general.delete")}
                    </PrimaryButton>
                    <DefaultButton onClick={this.onCloseDialog} disabled={this.state.isLoading} text={t("general.cancel")} />
                </DialogFooter>
            </Dialog>
        );
    }

    /**
     * Renders a message that an error occured 
     * @param t Translate function
     */
    private renderErrorOccured(t: TFunction): ReactNode {
        if (!this.state.errorOccured) {
            return null;
        }

        return (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={true} onDismiss={this.onRemoveErrorBanner} dismissButtonAriaLabel={t("general.close")}>
                {t("general.errorOccured")}
            </MessageBar>
        )
    }

    /**
     * Gets called if the error banner must be removed
     */
    private onRemoveErrorBanner = (): void => {
        this.setState({
            errorOccured: false
        });
    }

    /**
     * Gets called if the user confirms that they want to delete the selected user
     */
    private onDeleteUser = async (): Promise<void> => {
        if(!this.props.userToDelete) {
            return;
        }

        this.setState({
            errorOccured: false,
            isLoading: true
        });

        try
        {
            await AdminFactoryService.getAdminService().deleteUser(this.props.userToDelete.id);
       
            this.setState({
                isLoading: false
            });
            this.props.onDeleteSuccess();
        }
        catch(e)
        {
            this.setState({
                errorOccured: true,
                isLoading: false
            });
        }
    }

    /**
     * Closes the dialog
     */
    private onCloseDialog = (): void => {
        this.setState({
            isLoading: false,
            errorOccured: false
        }, () => {
            this.props.onCloseDialog();
        })
    }
}

export default UserDeleteDialog; 