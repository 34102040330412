import MyDownloadsQueryResult from './../../../dto/myDownloads/MyDownloadsQueryResult';
import MyDownloadsEntry from '../../../dto/myDownloads/MyDownloadsEntry';
import MyDownloadsFilter from '../../../dto/myDownloadsFilter/MyDownloadsFilter';

/**
 * My Downloads Page state
 */
class MyDownloadsState {
    /**
     * true if the filter panel must be shown, else false
     */
    public showFilterPanel: boolean;

    /**
     * Filter to use
     */
    public filter: MyDownloadsFilter;

    /**
     * Current query result of data
     */
    public queryResult: MyDownloadsQueryResult | null;

    /**
     * Download entry for which the details must be shown
     */
    public detailDownloadEntry: MyDownloadsEntry | null;

    /**
     * true if an error occured, else false
     */
    public errorOccured: boolean;

    /**
     * true if a page is being loaded
     */
    public pageLoading: boolean;

    /**
     * Current page
     */
    public page: number;

    /**
     * true if view loading spinner must be shown
     */
    public showViewLoadingSpinner: boolean;
    
    /**
     * true if the product number in the url is invalid
     */
    public invalidProductNumberUrl: boolean;

    /**
     * Product number of the object that is previewed from the url
     */
    public urlProductNumberPreview: string;

    /**
     * true if an error occured while loading product by url
     */
    public errorLoadingProductByUrl: boolean;

    /**
     * id of the file who will watched
     */
    public viewFile: string;

    /**
     * id of the video who will watched
     */
    public viewVideo: string;

    /**
     * Fullpage view
     */
    public viewFileZoom: boolean;

    /**
     * True if search results will be displayed
     */
    public displayingSearchResult: boolean;

    /**
     * Constructor
     */
    public constructor() {
        this.showFilterPanel = false;
        this.filter = new MyDownloadsFilter();
        this.queryResult = null;
        this.detailDownloadEntry = null;
        this.pageLoading = false;
        this.page = 0;
        this.errorOccured = false;
        this.showViewLoadingSpinner = false;
        this.urlProductNumberPreview = "";
        this.invalidProductNumberUrl = false;
        this.errorLoadingProductByUrl = false;
        this.viewFile = "";
        this.viewFileZoom = false;
        this.viewVideo = "";
        this.displayingSearchResult = false;
    }
}

export default MyDownloadsState;