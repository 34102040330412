/**
 * Entry for user
 */
class UserEntry {
    /**
     * Id of the user
     */
    public id: string;

    /**
     * Title of the user
     */
    public name: string;

    /**
     * Email of the user
     */
    public email: string;

    /**
     * Username
     */
    public userName: string;

    /**
     * Current session count
     */
    public sessionCount: number;

    /**
     * IP with which the user can authenticate
     */
    public ipAuth: string;

    /**
     * Constructor
     */
    public constructor() {
        this.id = "";
        this.name = "";
        this.email = "";
        this.userName = "";
        this.sessionCount = 0;
        this.ipAuth = "";
    }
}

export default UserEntry;