import MyDownloadsFilterEntry from './../myDownloads/MyDownloadsFilterEntry';
import MyDownloadsFilterEntryType from '../myDownloads/MyDownloadsFilterEntryType';

/**
 * Filter for my downloads
 */
class MyDownloadsFilter {
    /**
     * Search query
     */
    public searchQuery: string;

    /**
     * Minimum filter date
     */
    public minDate: Date | null;

    /**
     * Maximum filter date
     */
     public maxDate: Date | null;

    /**
     * Formats to use
     */
    public formats: MyDownloadsFilterEntryType[];

    /**
     * Filter entries to use
     */
    public filterEntries: MyDownloadsFilterEntry[];

    /**
     * Constructor
     */
    public constructor() {
        this.searchQuery = "";
        this.minDate = null;
        this.maxDate = null;
        this.formats = [];
        this.filterEntries = [];
    }
}

export default MyDownloadsFilter;