import SetNewPasswordRequest from './../../../dto/account/SetNewPasswordRequest';

/**
 * Set new password page state class
 */
class SetNewPasswordState {
    /**
     * Request to set the new password
     */
    public setNewPasswordRequest: SetNewPasswordRequest;

    /**
     * Repeat of the new password
     */
    public repeatNewPassword: string;

    /**
     * true if error messages should be shown on empty
     */
    public showErrorMessage: boolean;

    /**
     * true if the page is loading
     */
    public isLoading: boolean;

    /**
     * Error message that was returned from the server when setting a new password
     */
    public setNewPasswordError: string;

    /**
     * true if the new password could be set
     */
    public couldSetNewPassword: boolean;

    /**
     * Constructor
     */
    public constructor()
    {
        this.setNewPasswordRequest = new SetNewPasswordRequest();
        this.repeatNewPassword = ""; 
        this.showErrorMessage = false;
        this.isLoading = false;
        this.setNewPasswordError = "";
        this.couldSetNewPassword = false;
    }
}

export default SetNewPasswordState;