import { Guid } from "guid-typescript";

/**
 * Product associated with a user
 */
class UserProduct {
    /**
     * Id of the product
     */
    public id: string;

    /**
     * Product id
     */
    public productId: string;

    /**
     * Title of the product
     */
    public name: string;

    /**
     * Filename of the product
     */
    public filename: string;

    /**
     * Start Date
     */
    public start: Date;

    /**
     * End Date
     */
    public end: Date;

    /**
     * Constructor
     * @param productId Product id
     * @param name Product name
     * @param filename Filename of the product
     * @param startDate Start date
     * @param endDate End Date
     */
    public constructor(productId: string, name: string, filename: string, startDate: Date, endDate: Date) {
        this.id = Guid.create().toString();
        this.productId = productId;
        this.name = name;
        this.filename = filename;
        this.start = new Date(startDate);
        this.start.setHours(6);
        this.end = new Date(endDate);
        this.end.setHours(23);
        this.end.setMinutes(59);
    }
}

export default UserProduct;