import React, { ReactNode } from 'react';

// Library
import { TFunction } from 'i18next';
import { Panel, PrimaryButton, DefaultButton, Spinner, SpinnerSize, MessageBar, MessageBarType, PanelType, SelectionMode, ShimmeredDetailsList, IColumn, IconButton, FontSizes, Stack, DatePicker, DayOfWeek, IDatePickerStrings, IStackTokens, Label, ColorClassNames, IComboBoxOption, IComboBox, ComboBox } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';

// Components
import TranslatedComponent from '../../../../localization/TranslatedComponent';
import styles from './UserProductPanel.module.css';

// Services
import AdminFactoryService from '../../../../services/admin/AdminFactory.service';

// Dto
import IUserProductPanelProps from './dto/IUserProductPanelProps';
import UserProductPanelState from './dto/UserProductPanelState';
import UserProduct from '../../../../dto/admin/UserProduct';

// Util
import formatDate from '../../../../util/FormatDate';
import buildDatePickerStrings from '../../../../util/BuildDatePickerStrings';

/**
 * User product panel Component
 */
class UserProductPanel extends TranslatedComponent<IUserProductPanelProps, UserProductPanelState> {
    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<IUserProductPanelProps>) {
        super(props);

        this.state = new UserProductPanelState();
    }

    /**
     * Gets called if the component is updated
     */
    public componentDidUpdate(prevProps: IUserProductPanelProps) {
        if (this.props.userToEdit !== null && prevProps.userToEdit === null) {
            this.setState({
                userProducts: [],
                isLoading: true,
                isSaving: false
            });

            let availableProducts = AdminFactoryService.getAdminService().getAvailableUserProducts();
            let userAdminProductsPromise = AdminFactoryService.getAdminService().getUserAdminProducts(this.props.userToEdit.id);

            Promise.all([availableProducts, userAdminProductsPromise]).then((products) => {
                this.setState({
                    availableProducts: products[0],
                    userProducts: (products[1] as UserProduct[]),
                    isLoading: false
                });
            }, () => {
                this.setState({
                    isLoading: false,
                    errorOccured: true
                })
            });
        }
    }

    /**
     * Renders the administration component
     * @param t Translate function
     * @returns Rendered component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        const datePickerStrings: IDatePickerStrings = buildDatePickerStrings(t);
        const addProductStackTokens: IStackTokens = {
            childrenGap: 10
        };

        return (
            <Panel
                isOpen={this.props.userToEdit !== null}
                onDismiss={this.props.onClosePanel}
                closeButtonAriaLabel={t("general.close")}
                headerText={t("userProductPanel.header")}
                onRenderFooter={() => this.renderPanelFooter(t)}
                isFooterAtBottom={true}
                type={PanelType.large}>
                {this.renderErrorOccured(t)}
                <Label required={true}>{t("userProductPanel.addProduct")}</Label>
                <Stack horizontal tokens={addProductStackTokens}>
                    <Stack.Item grow disableShrink>
                        <ComboBox
                            placeholder={t("userProductPanel.chooseProduct")}
                            selectedKey={this.state.selectedProductKey}
                            autoComplete="on"
                            allowFreeform={false}
                            options={this.getSelectableProducts()}
                            onChange={this.onSelectUserProduct}
                            styles={
                                {
                                    optionsContainerWrapper: {
                                        maxHeight: '70vh'
                                    }
                                }
                            }/>
                    </Stack.Item>
                    <Stack.Item grow>
                        <DatePicker
                            value={this.state.selectedStartDate}
                            placeholder={t("userProductPanel.startDate")} 
                            strings={datePickerStrings} 
                            firstDayOfWeek={DayOfWeek.Monday} 
                            formatDate={(d) => formatDate(t, d)} 
                            onSelectDate={this.onSelectStartDate}></DatePicker>
                    </Stack.Item>
                    <Stack.Item grow>
                        <DatePicker value={this.state.selectedEndDate} placeholder={t("userProductPanel.endDate")} strings={datePickerStrings} firstDayOfWeek={DayOfWeek.Monday} formatDate={(d) => formatDate(t, d)} onSelectDate={this.onSelectEndDate}></DatePicker>
                    </Stack.Item>
                    <Stack.Item grow>
                        <PrimaryButton onClick={this.onAddUserProduct}>{t("userProductPanel.addProductButton")}</PrimaryButton>
                    </Stack.Item>
                </Stack>
                {this.renderAddProductErrorMessage(t)}
                <ShimmeredDetailsList
                    items={this.getUserProducts()}
                    columns={this.getUserColumns(t)}
                    enableShimmer={this.state.isLoading}
                    selectionMode={SelectionMode.none}
                    getKey={this.getUserRowKey}></ShimmeredDetailsList>
            </Panel>
        );
    }

    /**
     * Returns the error message on adding of a product
     * @param t  Translate function
     * @returns Product error message
     */
    private renderAddProductErrorMessage = (t: TFunction): React.ReactNode => {
        if(!this.state.showErrorMessage) {
            return null;
        } 

        let errorMessage: string | null = null;
        if(!this.state.selectedProductKey || !this.state.selectedStartDate || !this.state.selectedEndDate) {
            errorMessage = t("userProductPanel.fieldsAreRequired")
        } else if(this.state.selectedEndDate < this.state.selectedStartDate) {
            errorMessage = t("userProductPanel.startDateMustBeBeforeEndDate")
        }

        if(!errorMessage) {
            return null;
        }

        return (
            <Label className={ColorClassNames.red}>{errorMessage}</Label>
        );
    }

    /**
     * Returns the user products
     * @returns User Products
     */
    private getUserProducts = (): UserProduct[] => {
        return this.state.userProducts.sort((up1, up2) => {
            let startDiff = up2.start.getTime() - up1.start.getTime();
            if(startDiff !== 0)
            {
                return startDiff;
            }

            let endDiff = up2.end.getTime() - up1.end.getTime();
            if(endDiff !== 0)
            {
                return endDiff;
            }

            return up1.name.localeCompare(up2.name)
        });
    }

    /**
     * Returns the products a user can select
     * @returns Products that a user can select
     */
    private getSelectableProducts(): IComboBoxOption[] {
        return this.state.availableProducts.sort((up1, up2) => up1.name.localeCompare(up2.name)).map(p => {
            let name = p.name;
            if(p.filename) {
                name = `${p.name} (${p.filename})`;
            }
            
            return {
                key: p.id,
                text: name
            }
        });
    }

    /**
     * Returns the user columns
     * @param t Translate function
     * @returns User Columns
     */
    private getUserColumns = (t: TFunction): IColumn[] => {
        return [
            {
                key: 'name',
                name: t("userProductPanel.name"),
                fieldName: 'name',
                minWidth: 250,
                maxWidth: 450,
                isResizable: true,
                onRender: (u) => this.renderNameColumn(u)
            },
            {
                key: 'startDate',
                name: t("userProductPanel.startDate"),
                fieldName: 'start',
                minWidth: 150,
                maxWidth: 250,
                isResizable: true,
                onRender: (u) => formatDate(t, u.start)
            },
            {
                key: 'endDate',
                name: t("userProductPanel.endDate"),
                fieldName: 'end',
                minWidth: 150,
                maxWidth: 250,
                isResizable: true,
                onRender: (u) => formatDate(t, u.end)
            },
            {
                key: 'editColumn',
                name: '',
                fieldName: '',
                minWidth: 80,
                maxWidth: 80,
                isResizable: false,
                onRender: (u) => this.renderEditColumn(t, u)
            }
        ];
    }

    /**
     * Returns the key for a user row
     * @param item Row item
     * @returns Row key
     */
    private getUserRowKey = (item: UserProduct, index?: number): string => {
        if (!item) {
            return index ? index.toString() : "";
        }
        return item.id;
    }

    /**
     * Renders the name column
     * @param userProduct Product to render
     * @returns Rendered column
     */
    private renderNameColumn = (userProduct: UserProduct): JSX.Element | string => {
        let name = userProduct.name;
        if(userProduct.filename) {
            name = `${userProduct.name} (${userProduct.filename})`;
        }
        
        return (
            <span title={name}>
                {name}
            </span>
        )
    }

    /**
     * Renders the edit column
     * @param t Translate function
     * @param userProduct User product
     * @returns Edit column
     */
    private renderEditColumn = (t: TFunction, userProduct: UserProduct): JSX.Element | string => {
        return (
            <span>
                <IconButton styles={{ icon: { fontSize: FontSizes.small } }} iconProps={{ iconName: "Delete" }} onClick={() => { this.onDeleteUserProduct(userProduct) }} title={t("general.delete")} ariaLabel={t("general.delete")}></IconButton>
            </span>
        )
    }

    /**
     * Renders the panel footer
     * @param t Translate function
     */
    private renderPanelFooter(t: TFunction): JSX.Element | null {
        return (
            <div className={styles.footerContainer}>
                <PrimaryButton disabled={this.state.isLoading || this.state.isSaving} onClick={this.onSaveUserProducts} className={styles.buttonMargin}>
                    {(this.state.isLoading || this.state.isSaving) && <Spinner className={styles.saveButtonSpinner} size={SpinnerSize.xSmall}></Spinner>}
                    {t("general.save")}
                </PrimaryButton>
                <DefaultButton disabled={this.state.isLoading || this.state.isSaving} onClick={this.props.onClosePanel}>{t("general.cancel")}</DefaultButton>
            </div>
        );
    }

    /**
     * Renders a message that an error occured
     * @param t Translate function
     */
    private renderErrorOccured(t: TFunction): ReactNode {
        if (!this.state.errorOccured) {
            return null;
        }

        return (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={false} onDismiss={this.removeErrorBanner} dismissButtonAriaLabel={t("general.close")}>
                {t("general.errorOccured")}
            </MessageBar>
        )
    }

    /**
     * Removes the error banner
     */
    private removeErrorBanner = (): void => {
        this.setState({
            errorOccured: false
        })
    }

    /**
     * Gets called if a user product must be deleted
     * @param userProduct User PRoduct to delete
     */
    private onDeleteUserProduct = (userProduct: UserProduct): void => {
        let newUserProducts = this.state.userProducts.filter(u => u.id !== userProduct.id);

        this.setState({
            userProducts: newUserProducts
        });
    }

    /**
     * Gets called if a user product must be added
     * @param _ev Event
     * @param selectedOption Selected product to add
     */
    private onSelectUserProduct = (_ev: React.FormEvent<IComboBox>, selectedOption?: IComboBoxOption) => {
        if (!selectedOption) {
            return;
        }

        this.setState({
            selectedProductKey: selectedOption.key.toString()
        });
    }

    /**
     * Gets called if a start date is selected
     * @param date Selected date
     */
    private onSelectStartDate = (date: Date | null | undefined): void => {
        if (!date) {
            return;
        }

        this.setState({
            selectedStartDate: date
        });
    }

    /**
     * Gets called if a end date is selected
     * @param date Selected date
     */
    private onSelectEndDate = (date: Date | null | undefined): void => {
        if (!date) {
            return;
        }

        this.setState({
            selectedEndDate: date
        });
    }

    /**
     * Gets called if a user product must be added
     * @param _ev Event
     */
    private onAddUserProduct = (_ev: React.MouseEvent<HTMLButtonElement>) => {
        let selectedOption = this.state.availableProducts.find(ap => ap.id === this.state.selectedProductKey);
        if (!selectedOption || !this.state.selectedStartDate || !this.state.selectedEndDate || this.state.selectedEndDate < this.state.selectedStartDate) {
            this.setState({
                showErrorMessage: true
            });
            return;
        }

        this.setState({
            showErrorMessage: false
        });

        let userProduct = new UserProduct(selectedOption.id, selectedOption.name, selectedOption.filename, this.state.selectedStartDate, this.state.selectedEndDate);
        let newUserProducts = [...this.state.userProducts];
        newUserProducts.push(userProduct);

        this.setState({
            userProducts: newUserProducts,
            selectedProductKey: null,
            selectedStartDate: undefined,
            selectedEndDate: undefined
        });
    }

    /**
     * Gets called if the user products must be saved
     */
    private onSaveUserProducts = async (): Promise<void> => {
        if (!this.props.userToEdit || !this.props.userToEdit.id) {
            return;
        }

        this.setState({
            isSaving: true,
            errorOccured: false
        });

        try {
            await AdminFactoryService.getAdminService().saveUserAdminProducts(this.props.userToEdit.id, this.state.userProducts);

            this.setState({
                isSaving: false
            });

            this.props.onSaveSuccess();
        }
        catch (e) {
            this.setState({
                isSaving: false,
                errorOccured: true
            });
        }
    }
}

export default UserProductPanel; 