import React, { ReactNode } from 'react';
import { TextField, PrimaryButton, MessageBar, Icon, Text, MessageBarType, FontClassNames, ColorClassNames, FontWeights, DefaultButton, SpinnerSize, Spinner } from 'office-ui-fabric-react';
import 'office-ui-fabric-core/dist/css/fabric.min.css';

// Library
import { TFunction } from 'i18next';
import { Redirect, useLocation, useHistory, Link } from 'react-router-dom';

// Components
import TranslatedComponent from '../../localization/TranslatedComponent';
import styles from './Login.module.css';

// Services
import AuthServiceFactory from './../../services/auth/AuthFactory.service';

// Dto
import ILoginProps from './dto/ILoginProps';
import LoginState from './dto/LoginState';


/**
 * Login Component
 */
class Login extends TranslatedComponent<ILoginProps, LoginState> {
    /**
     * Constructor
     * @param props Input props
     */
    public constructor(props: Readonly<ILoginProps>) {
        super(props);

        this.state = new LoginState();
    }

    /**
     * Renders the login component
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        let location: any = useLocation();
        let routerHistory = useHistory();

        let redirectTarget = location && location.state && location.state.from ? location.state.from : "/";
        return (
            <div className={`${styles.loginContainer} ${ColorClassNames.whiteBackground}`}>
                <div className={`${styles.redhead}  ${ColorClassNames.whiteBackground}`}></div>

                <div className={`ms-Grid ${styles.height100} ${ColorClassNames.whiteBackground}`} dir="ltr">
                    <div className={`ms-Grid-row ${styles.height100}`}>
                        <div className={`ms-Grid-col ms-hiddenLgDown ms-xl8 ${styles.height100} ${styles.cover}`}>

                        </div>
                        <div className={`ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl4 ${styles.login}`}>
                            {this.renderLoggedInUserRedirect(redirectTarget)}
                            <h1 className={`${FontClassNames.xLargePlus} ${ColorClassNames.themePrimary}`} style={{ fontWeight: FontWeights.light as number }}>{t("login.enterCredentials")}</h1>
                            <img alt={t("header.scifoLogo")} className={`${styles.logo}`} src="/img/Scifo_Logo_2019.svg"></img>

                            {this.renderAuthErrorOccured(t)}

                            <Text className={ColorClassNames.neutralPrimary}><p className={FontClassNames.mediumPlus}>{t("login.text")}</p></Text>

                            <TextField onKeyDown={this.loginOnEnter} onChange={this.onUsernameChange} errorMessage={this.getErrorMessage(t, this.state.username)} label={t("login.username")}></TextField>
                            <TextField onKeyDown={this.loginOnEnter} onChange={this.onPasswordChange} errorMessage={this.getErrorMessage(t, this.state.password)} type="password" label={t("login.password")}></TextField>
                            <p className={`${styles.right}`}>
                                <DefaultButton onClick={() => this.toHome(routerHistory)}>{t("login.cancel")}</DefaultButton>&nbsp;&nbsp;
                                <PrimaryButton disabled={this.state.isLoading} onClick={this.onLogin}>
                                    {this.state.isLoading && <Spinner className={styles.loginButtonSpinner} size={SpinnerSize.xSmall}></Spinner>}
                                    {t("login.login")}
                                </PrimaryButton>
                            </p>
                            <div className={styles.linkContainer}>
                                <p style={{textAlign: "center"}}><Icon iconName='AddFriend' /> <Link className={`${ColorClassNames.themePrimary} ${styles.redirectLink}`} to="/register">{t("login.registerLink")}</Link>
                                &nbsp;&nbsp;
                                    <Icon iconName='Lock' /> <Link className={`${ColorClassNames.themePrimary} ${styles.redirectLink}`} to="/passwordReset">{t("login.passwordResetLink")}</Link></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    /**
     * Renders a redirect for a logged in user
     * @param redirectTarget Redirect target
     */
    private renderLoggedInUserRedirect(redirectTarget: string): ReactNode {
        if (!this.props.currentUser || !this.props.currentUser.isAuthenticated) {
            return null;
        }

        return (<Redirect to={redirectTarget}></Redirect>);
    }

    /**
     * Renders a message that an auth error occured
     * @param t Translate function
     */
    private renderAuthErrorOccured(t: TFunction): ReactNode {
        if (!this.state.authErrorOccured) {
            return null;
        }

        return (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={true} onDismiss={this.removeAuthErrorBanner} dismissButtonAriaLabel={t("general.close")}>
                {t(this.state.authErrorMessage)}
            </MessageBar>
        )
    }

    /**
     * Removes the auth error banner
     */
    private removeAuthErrorBanner = (): void => {
        this.setState({
            authErrorOccured: false
        })
    }

    /**
     * Returns an error message if a login field is empty
     * @param t Translation function
     * @param value Current value
     */
    private getErrorMessage = (t: TFunction, value: string): string => {
        if (!this.state.showErrorMessage || value) {
            return "";
        }

        return t("general.mandatoryField");
    }

    /**
     * Gets called if the username is changed
     * @param event Event object
     * @param newValue New value
     */
    private onUsernameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        this.setState({
            username: newValue ? newValue : ""
        });
    }

    /**
     * Gets called if the password is changed
     * @param event Event object
     * @param newValue New value
     */
    private onPasswordChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        this.setState({
            password: newValue ? newValue : ""
        });
    }

    /**
     * Redirects the user back to the home screen
     * @param routerHistory Router history
     */
    private toHome = (routerHistory: any) => {
        routerHistory.push("/");
    };

    /** 
     * Submits a login request on enter 
     */
    private loginOnEnter = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if(e.key === "Enter")
        {
            this.onLogin();
        }
    }

    /**
     * Gets called if the user wants to login
     */
    private onLogin = async () => {
        if (!this.state.username || !this.state.password) {
            this.setState({
                showErrorMessage: true,
                authErrorOccured: false,
                authErrorMessage: "login.youCouldNotBeLoggedIn"
            });
            return;
        }

        this.setState({
            showErrorMessage: false,
            authErrorOccured: false,
            authErrorMessage: "login.youCouldNotBeLoggedIn",
            isLoading: true
        });

        let userData;
        let authErrorOccured = false;
        let authErrorMessage = "login.youCouldNotBeLoggedIn";
        try {
            userData = await AuthServiceFactory.getAuthService().login(this.state.username, this.state.password);
            if (!userData.isAuthenticated) {
                authErrorOccured = true;
            }
        }
        catch (e) {
            authErrorOccured = true;

            if (e.response && e.response.data === "emailNotConfirmed") {
                authErrorMessage = "login.pleaseConfirmBeforeLoggingIn";
            } else if (e.response && e.response.data === "tooManySessions") {
                authErrorMessage = "login.tooManySessionsForLogin";
            }
        }

        if (authErrorOccured || !userData) {
            this.setState({
                authErrorOccured: true,
                authErrorMessage: authErrorMessage,
                isLoading: false
            });
        }
        else {
            this.setState({
                isLoading: false
            });
            this.props.onUserLoggedIn(userData);
        }
    }
}

export default Login; 