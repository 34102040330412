import { createTheme } from 'office-ui-fabric-react';

// Created using https://fabricweb.z5.web.core.windows.net/pr-deploy-site/refs/heads/master/theming-designer/index.html
const mainTheme = createTheme({
    palette: {
        themePrimary: '#cd1717',
        themeLighterAlt: '#fdf4f4',
        themeLighter: '#f7d4d4',
        themeLight: '#f0b0b0',
        themeTertiary: '#e06969',
        themeSecondary: '#d22e2e',
        themeDarkAlt: '#b81414',
        themeDark: '#9b1111',
        themeDarker: '#720d0d',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#d4d4d4',
        neutralSecondary: '#a9a9a9',
        neutralPrimaryAlt: '#818181',
        neutralPrimary: '#707070',
        neutralDark: '#555555',
        black: '#3f3f3f',
        white: '#ffffff',
    }
});

export default mainTheme;