import ILogger from "./ILogger";

/**
 * Class for a logger
 */
class ConsoleLogger implements ILogger {
    /**
     * Page view start
     */
    private pageViewStart: number = 0;

    /**
     * Current page
     */
    private currentPage: string = "";

    /**
     * Initialized the logger
     */
    public init(): void {
        console.log("Console Logger initialized");
    }

    /**
     * Starts tracking a page
     * @param pageName Page name
     */
    public startTrackPage(pageName: string): void {
        if(this.currentPage === pageName) {
            return;
        }

        if(this.currentPage) {
            let viewTime = ((new Date()).getTime() - this.pageViewStart) / 1000;
            console.log(`Viewed ${this.currentPage} for ${viewTime}`);
        }

        this.pageViewStart = (new Date()).getTime();
        this.currentPage = pageName;
    }

    /**
     * Tracks a request
     * @param trackId Track id
     * @param url Url that was called
     * @param success true if the request was a success, else false
     * @param responseCode Response code
     * @param method Method used
     * @param duration Duration the call took
     */
    public trackRequest(trackId: string, url: string, success: boolean, responseCode: number, method: string, duration: number): void {
        console.log(`${method} ${url} (${trackId}) took ${duration}ms -> response ${responseCode}, success: ${success}`);
    }
}

export default ConsoleLogger;