import { Guid } from 'guid-typescript';

import UserQueryResult from '../../dto/admin/UserQueryResult';
import mockLoadingTime from './../../util/MockLoadingTime';

import IAdminService from './IAdmin.service';
import UserEntry from '../../dto/admin/UserEntry';
import UserCreateEditRequest from '../../dto/admin/UserCreateEditRequest';
import UserData from '../../dto/account/UserData';
import AvailableUserProduct from '../../dto/admin/AvailableUserProduct';
import UserProduct from '../../dto/admin/UserProduct';
import UserSubscription from '../../dto/subscriptions/UserSubscription';
import AddUserSubscriptionRequest from '../../dto/admin/AddUserSubscriptionRequest';

/**
 * Mock admin services
 */
class MockAdminService implements IAdminService {
    /**
     * Returns the users
     * @param page Page to load
     * @param pageSize Pagesize to load
     * @param searchQuery Search query
     * @returns Userlist
     */
    public async getUsers(page: number, pageSize: number, searchQuery: string): Promise<UserQueryResult> {
        await mockLoadingTime();

        let queryResult: UserQueryResult = new UserQueryResult();
        queryResult.totalCount = 100;
     
        queryResult.entries = [];
        for(let curEntry = page * pageSize; curEntry < 100 && curEntry < (page + 1) * pageSize; ++curEntry)
        {
            let userEntry: UserEntry = new UserEntry();
            userEntry.id = curEntry.toString();
            userEntry.email = `user${curEntry}@test.local`;
            userEntry.name = `User #${curEntry}`;
            userEntry.sessionCount = curEntry % 2;
            userEntry.ipAuth = curEntry === 0 ? '127.0.0.1-127.0.0.1,255.255.255.255' : '';

            queryResult.entries.push(userEntry);
        }

        return queryResult;
    }

    /**
     * Loads a user by id
     * @param userId Id of the user
     */
    public async getUserById(userId: string): Promise<UserData> {
        await mockLoadingTime();

        let userData = new UserData();
        userData.username = "UsernameOverwrite"
        userData.email = "user@test.local";
        userData.salutation = "Herr";
        userData.firstname = "Vorname";
        userData.lastname = "Nachname";
        userData.companyName = "Test Firma";
        userData.companyStreet = "Firmenstraße";
        userData.companyPostalCode = "Firmen Postleitzahl";
        userData.companyCity = "Firmen Stadt";
        userData.companyCountry = "Firmen Land";
        userData.maxSessionCount = 10;
        userData.ipAddress = "127.0.0.1-127.0.0.1,255.255.255.255";

        return userData;
    }

    /**
     * Creates a new user
     * @param createRequest Create request 
     */
    public async createUser(createRequest: UserCreateEditRequest): Promise<void> {
        await mockLoadingTime();
    }
    
    /**
     * Updates a user
     * @param userId Id of the user
     * @param editRequest Edit request 
     */
    public async updateUser(userId: string, editRequest: UserCreateEditRequest): Promise<void> {
        await mockLoadingTime();
    }
        
    /**
     * Deletes a user
     * @param userId User Id
     */
    public async deleteUser(userId: string): Promise<void> {
        await mockLoadingTime();
    }

    /**
     * Returns the available user products
     * @returns List of user products
     */
    public async getAvailableUserProducts(): Promise<AvailableUserProduct[]> {
        await mockLoadingTime();

        let products: AvailableUserProduct[] = [];
        for(let curProduct = 0; curProduct < 1000; ++curProduct)
        {
            let product = new AvailableUserProduct();
            product.id = curProduct.toString();
            product.name = `Produkt #${curProduct.toString().padStart(2, '0')}`;
            product.filename = `product_${curProduct.toString()}.pdf`;
            products.push(product);
        }

        return products;
    }

    /**
     * Returns the products of a user which were associated by an admin
     * @param userId Id of the user
     * @returns List of user products
     */
    public async getUserAdminProducts(userId: string): Promise<UserProduct[]> {
        await mockLoadingTime();

        let products: UserProduct[] = [];
        for(let curProduct = 0; curProduct < 10; ++curProduct)
        {
            let start = new Date(2018 + Math.random() * 2, Math.random() * 12, 1 + Math.random() * 20);
            let end = new Date(start.getFullYear(), start.getMonth(), start.getDate());
            end.setDate(end.getDate() + (Math.random() * 30));

            let product = new UserProduct(curProduct.toString(), `Produkt #${curProduct.toString().padStart(2, '0')}`, `product_${curProduct.toString()}.pdf`, start, end);

            if(curProduct > 0 && (curProduct % 2) === 0)
            {
                product.start = new Date(products[curProduct - 1].start);
                product.end = new Date(products[curProduct - 1].end);
                product.end.setDate(product.end.getDate() + 1);
            }

            products.push(product);
        }

        return products;
    }
    
    /**
     * Saves the user admin products
     * @param userId Id of the user
     * @param products Products to save
     */
    public async saveUserAdminProducts(userId: string, products: UserProduct[]): Promise<void> {
        await mockLoadingTime();
    }

    /**
     * Returns a list of user subscriptions
     * @param userId Id of the user
     * @returns List of user subscription
     */
    public async getUserSubscriptions(userId: string): Promise<UserSubscription[]> {
        await mockLoadingTime();

        let subscriptions: UserSubscription[] = [];
        for(let curSubscription = 0; curSubscription < 3; ++curSubscription) {
            let subscription: UserSubscription = new UserSubscription();
            subscription.id = Guid.create().toString();
            subscription.subscriptionNumber = "400000000" + curSubscription;
            subscription.productName = "Subscription #" + curSubscription;
            subscription.isActive = curSubscription !== 0;
            
            subscriptions.push(subscription);
        }

        return subscriptions;
    }

    /**
     * Adds a user subscription
     * @param subscriptionRequest Subscription request
     */
    public async addUserSubscription(subscriptionRequest: AddUserSubscriptionRequest): Promise<void> {
        await mockLoadingTime();
    }
      
    /**
     * Deletes a user subscriptions
     * @param subscriptionId Id of the subscription
     */
    public async deleteUserSubscription(subscriptionId: string): Promise<void> {
        await mockLoadingTime();
    }
}

export default MockAdminService;