/**
 * Response send for the current user data
 */
class CurrentUserDataResponse {
    /**
     * Email of the current user
     */
    public email: string;

    /**
     * Firstname
     */
    public salutation: string;

    /**
     * Firstname
     */
    public firstname: string;

    /**
     * Lastname
     */
    public lastname: string;
    
    /**
     * Company name
     */
    public companyName: string;
    
    /**
     * Company street
     */
    public companyStreet: string;
    
    /**
     * Company postal code
     */
    public companyPostalCode: string;
    
    /**
     * Company city
     */
    public companyCity: string;

    /**
     * Company country
     */
    public companyCountry: string;

    /**
     * Constructor
     */
    public constructor() {
        this.email = "";
        this.salutation = "";
        this.firstname = "";
        this.lastname = "";
        this.companyName = "";
        this.companyStreet = "";
        this.companyPostalCode = "";
        this.companyCity = "";
        this.companyCountry = "";
    }
}

export default CurrentUserDataResponse;