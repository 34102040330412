import React, { ReactNode } from 'react';

// Library
import { ColorClassNames, FontClassNames } from 'office-ui-fabric-react';
import { TFunction } from 'i18next';

// Components
import styles from './Footer.module.css';
import TranslatedComponent from '../../../localization/TranslatedComponent';
import { Link } from 'react-router-dom';

/**
 * Footer Component
 */
class Footer extends TranslatedComponent<{}, {}> {
    /**
     * Renders the Footer
     */
    protected renderWithTranslation(t: TFunction): ReactNode {
        return (
            <div className={`${styles.mainFooterContainer} ${ColorClassNames.neutralLightBackground}`}>
                <img alt={t("footer.vulkanVerlagLogo")} src="/img/VV Logo_4c.svg"></img>
                <br />
                <div className={styles.links}>
                    <Link className={`${FontClassNames.small} ${ColorClassNames.themePrimary} ${styles.linkEntry}`} to="/imprint">
                        {`${t("footer.imprint")}`}
                    </Link>
                    &nbsp;|&nbsp;
                    <Link className={`${FontClassNames.small} ${ColorClassNames.themePrimary} ${styles.linkEntry}`} to="/termsOfUse">
                        {`${t("footer.termsOfUse")}`}
                    </Link>
                    &nbsp;|&nbsp;
                    <Link className={`${FontClassNames.small} ${ColorClassNames.themePrimary} ${styles.linkEntry}`} to="/dataProtection">
                        {`${t("footer.dataProtection")}`}
                    </Link>
                </div>
            </div >
        );
    }
}

export default Footer; 