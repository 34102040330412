import UserEntry from "./UserEntry";

/**
 * Result of a user query
 */
class UserQueryResult {
    /**
     * Total count of users
     */
    public totalCount: number;

    /**
     * User Entries
     */
    public entries: UserEntry[];

    /**
     * Constructor
     */
    public constructor() {
        this.totalCount = 0;
        this.entries = [];
    }
}

export default UserQueryResult;