import MyDownloadsFilterEntry from "./MyDownloadsFilterEntry";

/**
 * Config for filters
 */
class MyDownloadsFilterConfig {
    /**
     * Minimum year for the filter
     */
    public minYear: number;

    /**
     * Maximum year for the filter
     */
    public maxYear: number;

    /**
     * Filter entries
     */
    public filterEntries: MyDownloadsFilterEntry[];


    /**
     * Constructor
     */
    public constructor() {
        this.minYear = 0;
        this.maxYear = 0;
        this.filterEntries = [];
    }
}

export default MyDownloadsFilterConfig;