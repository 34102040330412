import { Guid } from "guid-typescript";

import ISubscriptionsService from './ISubscriptions.service';
import SubscriptionConnectRequest from './../../dto/subscriptions/SubscriptionConnectRequest';
import UserSubscription from '../../dto/subscriptions/UserSubscription';
import mockLoadingTime from './../../util/MockLoadingTime';

/**
 * Mock service for subscriptions
 */
class MockSubscriptionsService implements ISubscriptionsService {
    /**
     * Connects a subscription
     * @param subscriptionConnectRequest Request to connect the subscription
     * @returns Newly connected subscription
     */
    public async connectSubscription(subscriptionConnectRequest: SubscriptionConnectRequest): Promise<UserSubscription> {
        await mockLoadingTime();
        
        let subscription: UserSubscription = new UserSubscription();
        subscription.id = Guid.create().toString();
        subscription.subscriptionNumber = subscriptionConnectRequest.subscriptionNumber;
        subscription.productName = "Subscription #New";
        subscription.isActive = true;
        return subscription;
    }

    /**
     * Returns the subscriptions of the current user
     * @returns Subscriptions of the current user
     */
    public async getMySubscriptions(): Promise<UserSubscription[]> {
        await mockLoadingTime();

        let subscriptions: UserSubscription[] = [];
        for(let curSubscription = 0; curSubscription < 3; ++curSubscription) {
            let subscription: UserSubscription = new UserSubscription();
            subscription.id = Guid.create().toString();
            subscription.subscriptionNumber = "400000000" + curSubscription;
            subscription.productName = "Subscription #" + curSubscription;
            subscription.isActive = curSubscription !== 0;
            
            subscriptions.push(subscription);
        }

        return subscriptions;
    }
}

export default MockSubscriptionsService;