// Library
import React from 'react';
import { useLocation } from 'react-router-dom'

// Services
import LogManagerService from './../../../services/logging/LogManager.service'

/**
 * Renders a Pageview tracker
 */
const PageViewTracker: React.FunctionComponent = () => {
    let location = useLocation();
    LogManagerService.getInstance().startTrackPage(location.pathname);
    React.useEffect(() => {
        LogManagerService.getInstance().startTrackPage(location.pathname);
    }, [location]);

    return (
        null
    );
};

export default PageViewTracker;