// Util
import isDevMode from './../../util/IsDevMode';

// Services
import ILogger from './ILogger';
import ConsoleLogger from './ConsoleLogger';
import AppInsightsLogger from './AppInsightsLogger';

/**
 * Factory class to provide logger services
 */
export default class LogManagerService {
    /**
     * To prevent the logger from being instantiated with new
     * @memberof LogManagerService
     */
    private constructor() {
        this.loggers = this.buildLoggers();
    }

    /**
     * LogManager Service
     */
    public static instance: LogManagerService;

    /**
     * Returns the instance of the LogManagerService
     */
    public static getInstance() {
        if (!this.instance) {
            this.instance = new LogManagerService();
        }
        return this.instance;
    }

    /**
     * Loggers to use
     */
    private loggers: ILogger[];

    /**
     * Builds the loggers to use
     */
    private buildLoggers(): ILogger[] {
        let loggers = [];
        if (isDevMode()) {
            loggers.push(new ConsoleLogger());
        }
        else {
            loggers.push(new AppInsightsLogger());
        }

        return loggers;
    }

    /**
     * Initialized the loggers
     */
    public initializeLoggers(): void {
        for(let curLogger of this.loggers) {
            curLogger.init();
        }
    }

    /**
     * Starts tracking a page
     * @param pageName Page name
     */
    public startTrackPage(pageName: string): void {
        for(let curLogger of this.loggers) {
            curLogger.startTrackPage(pageName);
        }
    }

    /**
     * Tracks a request
     * @param trackId Track id
     * @param url Url that was called
     * @param success true if the request was a success, else false
     * @param responseCode Response code
     * @param method Method used
     * @param duration Duration the call took
     */
    public trackRequest(trackId: string, url: string, success: boolean, responseCode: number, method: string, duration: number): void {
        for(let curLogger of this.loggers) {
            curLogger.trackRequest(trackId, url, success, responseCode, method, duration);
        }
    }
}