import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

/**
 * Initializes the localization
 */
function initLocalization() {
    i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            lng: 'de',
            backend: {
                loadPath: '/lang/{{ns}}/{{lng}}.json'
            },
            fallbackLng: 'de',
            debug: true,
            ns: ['translations'],
            defaultNS: 'translations',
            keySeparator: false,
            interpolation: {
                escapeValue: false,
                formatSeparator: ','
            },
            react: {
                wait: true
            }
        });
}

export default initLocalization;